import * as Sentry from '@sentry/nextjs'
import { errorTagName, errorTagValue } from '@/utils/errorHandling'
import { LogLevel, LogArgs, SentryContext } from './types'
import { formatStringLog } from './formatters'

export default function sentryOutput({ message, location, error, extraInfo = {}, tags = {}, enableClientAlerts = false }: LogArgs): void {
    // Add tag to whitelist error from client
    if (enableClientAlerts) {
        tags[errorTagName] = errorTagValue
    }

    // sentry context provided to both exceptions and messages
    const sentryContext: SentryContext = {
        tags,
        extra: {
            ...extraInfo
        }
    }

    if (error) {
        // add message and location to the extra context for exceptions
        sentryContext.extra = { message, location, ...sentryContext.extra }
        Sentry.captureException(error, sentryContext)
    } else {
        // there is no error, so we'll send a formatted message instead
        const formattedMessage = formatStringLog({ level: LogLevel.Info, message, location, extraInfo })
        Sentry.captureMessage(formattedMessage, sentryContext)
    }
}
