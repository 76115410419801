import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { usePromotionsData, BannerBase } from '@saatva-bits/pattern-library.modules.promotions'

import styles from './Banner.module.scss'

const BannerPDPSitewideDiscount = ({
    category,
    className,
    productCode
}) => {

    const promotionData = usePromotionsData()
    const { sitewidePromo: { pagesMessaging = {}, promoProfile = {}, theme = 'blue' } } = promotionData

    const { isChooseYourDealPromo } = promoProfile

    if (isChooseYourDealPromo && productCode === 'crib-mattress') return null

    const skuSpecificMessage = get(pagesMessaging, ['pdp', productCode])
    const categorySpecificMessage = get(pagesMessaging, ['pdp', category])
    const defaultMessage = get(pagesMessaging, 'pdp.default')

    const messagingConfig = skuSpecificMessage || categorySpecificMessage || defaultMessage

    if (!messagingConfig) {
        return null
    }

    const {
        messageDesktop,
        messagePrefixConfig,
        action,
        actionText
    } = messagingConfig
    const sitewidePromo = promotionData.sitewidePromo


    const classes = classNames(styles.bannerBasePDP, styles.bannerPDPSitewideDiscount,{
        [`${styles[`bannerBase--${theme}`]}`]: !!theme
    }, className)
    return (
        <BannerBase
            messageDesktop={messageDesktop}
            messagePrefixConfig={messagePrefixConfig}
            theme={theme}
            action={action}
            actionText={actionText}
            className={classes}
            contentClassName={styles.sitewideContent}
            dataSelector={'pdp-banner'}
            mobileMessageDataSelector='promoBannerMsg'
            desktopMessageDataSelector='promoBannerMsgMobile'
            sitewidePromo={sitewidePromo}
        />
    )
}

BannerPDPSitewideDiscount.propTypes = {
    sku: PropTypes.string,
    category: PropTypes.string,
    addOnComponent: PropTypes.node,
    className: PropTypes.string
}

export default BannerPDPSitewideDiscount
