import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import classNames from 'classnames'
import DropdownPanel from '@/components/DropdownPanel/DropdownPanel'
import { useProductData, useProductState, useProductPricing, useUpdateProductAttributes, QuantitySelector, ProductSelectors } from '@saatva-bits/pattern-library.modules.selection'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/hooks'
import useAddon from '@/hooks/addon'
import { Button } from '@saatva-bits/pattern-library.components.button'
import { checkDropdownBuystackAddonConditions } from '../checkAddonConditions'
import PILLOW_SIZE_MAP from '@/temp-configs/pillow-size-map'
import { PRODUCT_CODES } from '@/constants'

const DropdownBuystackAddon = ({
    primaryProductCode,
    addonProductCode,
    addonLabel,
    currencySymbol = '$'
}) => {
    const primaryProductData = useProductData(primaryProductCode)
    const addonProductData = useProductData(addonProductCode)
    const primaryProductState = useProductState(primaryProductCode)
    const addonProductState = useProductState(addonProductCode)
    const { quantity, inStock } = addonProductState
    const updateProductAttributes = useUpdateProductAttributes()
    const { quiltOption: primaryQuiltOption } = primaryProductState
    const { isAddonSelected, selectAddon, unselectAddon } = useAddon(addonProductCode, addonLabel)
    const [isClosing, setIsClosing] = useState(false)
    const [isClosed, setIsClosed] = useState(true)
    const { finalPrice } = useProductPricing(addonProductCode)
    const [isAddonApplicable, setIsAddonApplicable] = useState(checkDropdownBuystackAddonConditions({ primaryQuiltOption, addonProductCode }))

    // BEGIN PDP Out of Stock Styling test EX-260
    const { isV1: isOutOfStockStylingEnabled } = useExperiment('EXP.OOS_BUYSTACK.EX-260')
    const isOutOfStockStyling = isOutOfStockStylingEnabled && !primaryProductData.isBundleProduct
    // END PDP Out of Stock Styling test EX-260

    useEffect(() => {
        const isApplicable = checkDropdownBuystackAddonConditions({ primaryQuiltOption, addonProductCode })
        setIsAddonApplicable(isApplicable)
        if (!isApplicable) unselectAddon(addonProductCode)
    }, [primaryQuiltOption])

    // unselect the addon if the selected SKU is not in stock
    useEffect(() => {
        if (!inStock) {
            unselectAddon(addonProductCode)
        }
    }, [inStock])

    useEffect(() => {
        let size = primaryProductState.size
        // check to avoid mapping in Dog bed, must change if we use this add on for other mattresses
        if (primaryProductData.category !== 'Mattresses') {
            // normalizes pillow and primary product sizes.
            const { pillowSize } = PILLOW_SIZE_MAP[primaryProductState['size']]
            size = pillowSize === 'Queen' ? 'Standard/Queen' : pillowSize || 'Standard/Queen'
        }
        // fall back to current color if it exists but primary product doesn't have one
        // e.g. if the user selects a pillowcase with a color and but the pillow doesn't have a color
        const color = primaryProductState.color || addonProductState.color
        const fabric = primaryProductState.fabric
        updateProductAttributes(addonProductCode, {
            size,
            fabric,
            color
        })
    }, [primaryProductState.size, primaryProductState.fabric, primaryProductState.color])

    if (!isAddonApplicable) return null

    const dropdownToggleCallback = (e, isOpen) => {
        const dropdownBuystackAddonPanel = e.target.closest('.dropdownPanel--dropdownBuystackAddon')

        if (!isOpen) {
            dropdownBuystackAddonPanel.addEventListener('animationend', setDropdownClosingToFalse)
            dropdownBuystackAddonPanel.addEventListener('transitionend', setDropdownClosingToFalse)
        }

        setIsClosing(!isOpen)
        isOpen && setIsClosed(false)

        // when dropdown is closed, the product should be selected unless it's not in stock
        if (!isAddonSelected && inStock) {
            selectAddon()
        }
    }

    const setDropdownClosingToFalse = (e) => {
        const dropdownBuystackAddonPanel = e.target.closest('.dropdownPanel--dropdownBuystackAddon')
        dropdownBuystackAddonPanel.removeEventListener('animationend', setDropdownClosingToFalse)
        dropdownBuystackAddonPanel.removeEventListener('transitionend', setDropdownClosingToFalse)
        setIsClosing(false)
        setIsClosed(true)
    }

    // Remove text is displayed if the modal is closed and an addon is selected.
    const removeText = () => {
        return (
            // TODO: this should be tabbable and have a keyboard handler
            // eslint-disable-next-line
            <span
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    unselectAddon()
                }}
                className='t-underline'>
                Remove
            </span>
        )
    }

    const ConfirmButton = ({ onClick }) => {
        const buttonClasses = classNames({
            'dropdownBuystackAddon__confirmButton': true
        })
        const priceDisplay = `+${currencySymbol}${finalPrice * quantity}`
        // include final price in button text if using standalone quantity selector
        const buttonText = finalPrice !== undefined ? `Confirm ${priceDisplay}` : 'Confirm'

        return (
            <div className="dropdownBuystackAddon__add dropdownBuystackAddon__add--inline">
                <QuantitySelector
                    productCode={addonProductCode}
                    dataSelector={'buystackAddonQty'}
                    className='u-marginRight'
                    displayIfOutOfStock={false}
                />
                <Button
                    className={buttonClasses}
                    kind="secondary"
                    type="button"
                    data-selector='dropdownPanelConfirmButton'
                    onClick={onClick}>
                    {buttonText}
                </Button>
            </div>
        )
    }

    // We exclude the "Extra" in the displayText for the special case(s) below
    const hasExtraText = primaryProductCode !== PRODUCT_CODES.VELVET_PICK_STICH_QUILT
    const displayText = quantity && isAddonSelected
        ? `${quantity} x ${addonProductData.name}`
        : `Add ${hasExtraText ? 'Extra ' : ''}${addonProductData.name}`

    const dropdownProps = {
        className: classNames({
            'is-closing': isClosing
        }, 'u-textLeftFlex dropdownPanel--dropdownBuystackAddon'),
        panelClassName: classNames({
            'is-closing': isClosing
        }, 'u-padding dropdownPanel__panel--dropdownBuystackAddon'),
        wrapperClassName: classNames('dropdownPanel__wrapper--dropdownBuystackAddon u-marginBottom--lg'),
        displayText,
        iconOverride: isClosed && quantity && isAddonSelected ? removeText() : null,
        isVariantProductInStock: inStock,
        iconName: 'more',
        iconAlt: 'More',
        iconDescription: 'More',
        ToggleChild: ConfirmButton,
        toggleCallback: dropdownToggleCallback,
        productCode: addonProductCode
    }

    return (
        <DropdownPanel {...dropdownProps}>
            <ProductSelectors
                productCode={addonProductCode}
                dataSelectorModifier='dropdownBuystackAddon'
                hiddenAttributes={['quantity']}
                isOutOfStockStyling={isOutOfStockStyling}
            />

        </DropdownPanel>
    )
}

DropdownBuystackAddon.propTypes = {
    primaryProductCode: propTypes.string,
    addonProductCode: propTypes.string,
    currencySymbol: propTypes.string
}

export default DropdownBuystackAddon
