import React from 'react'

import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { CRIB_MATTRESS, DOG_BED, CLASSIC_STARTER_BUNDLE } from '@/constants/product-codes'
import { CATEGORIES, SUBCATEGORIES } from '@/temp-configs/category-constants'

/*
Value Prop logic based on category, subCategory, deliveryType, returnPeriod, warranty, and warrantyDetails
*/

const getPropData = (valueProps = [], productCode, category, subCategory, returnPeriod, warranty, warrantyDetails, deliveryType) => {
    return valueProps.map((prop) => {
        let header
        let smallHeaderSize = false
        switch (prop) {
            case 'home':
                header = {
                    '365-night': 'home trial',
                    '180-night': 'home trial',
                    '45-night': 'home trial',
                    '45-day': 'free returns'
                }

                return {
                    name: prop,
                    icon: <SvgSprite spriteID='icon-small-house'/>,
                    linkUrl: '/trial',
                    header: `${returnPeriod} ${header[returnPeriod]}`,
                    smallHeaderSize: true
                }
            case 'delivery':
                const EXCLUDED_MATTRESSES = [DOG_BED, CRIB_MATTRESS]
                const isRemovalCopy = (
                    productCode === CLASSIC_STARTER_BUNDLE ||
                    subCategory.toLowerCase() === SUBCATEGORIES.FOUNDATIONS || 
                    (category.toLowerCase() === CATEGORIES.MATTRESSES && !EXCLUDED_MATTRESSES.includes(productCode))
                )
                header = {
                    'White Glove': 'Free in-room delivery',
                    'Direct': 'Free shipping',
                    'removal': 'Free mattress and foundation removal'
                }

                return {
                    name: prop,
                    icon: <SvgSprite spriteID='icon-small-truck'/>,
                    linkUrl: '/delivery',
                    header: isRemovalCopy ? header.removal : header[deliveryType],
                    smallHeaderSize: deliveryType === 'Direct'
                }
            case 'warranty':
                return {
                    name: prop,
                    icon: <SvgSprite spriteID='icon-small-warranty'/>,
                    linkUrl: warrantyDetails || '/warranty',
                    header: warranty?.includes('1-Year') ? `${warranty} limited warranty` : `${warranty} warranty`,
                    smallHeaderSize: !warranty.includes('1-Year')
                }
            case 'setup':
                switch (subCategory) {
                    case 'Chairs':
                    case 'Benches':
                    case 'Bed Frames':
                    case 'Nightstands':
                        header = 'Free assembly'
                        smallHeaderSize = true
                        break
                    default:
                        header = 'Free in-room delivery and setup'
                }

                return {
                    name: prop,
                    icon: <SvgSprite spriteID='icon-small-setup'/>,
                    linkUrl: '/delivery',
                    header: header,
                    smallHeaderSize: smallHeaderSize
                }
            case 'customerService':
                return {
                    name: prop,
                    icon: <SvgSprite spriteID='icon-small-customer-service'/>,
                    linkUrl: 'https://www.saatva.com/help',
                    header: `Customer service 24/7`,
                    smallHeaderSize: true
                }
            case 'usa':
                return {
                    name: prop,
                    icon: <SvgSprite spriteID='icon-small-flag'/>,
                    linkUrl: '/made-in-america',
                    header: `Assembled in America`,
                    smallHeaderSize: true
                }
            default:
                return {}
        }
    })
}

export default getPropData
