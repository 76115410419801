import React from 'react'
import PropTypes from 'prop-types'

const FoundationHeightsModal = ({
    productCode
}) => {
    return (
        <section className="u-textCenter">
            <div className="foundationHeights__intro">
                <h3 className="foundationHeights__header t-heading4">Choosing a foundation height</h3>
                <p className="u-textLeft">Bed height is a matter of personal preference. All of our foundations deliver the same performance, regardless of height. Measure your current bed height and use this illustration to determine which setup is right for you.</p>
            </div>
            <div className={`foundationHeightsDiagram foundationHeightsDiagram--${productCode}`}>
                <div className="row foundationHeightsDiagram__content">
                    <div className="col col--xs-12 col--md-4 col--offset-md-3">
                        <div className={`foundationHeightsDiagram__singleImg foundationHeightsDiagram__singleImg--${productCode}`}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

FoundationHeightsModal.propTypes = {
    productCode: PropTypes.string.isRequired
}

export default FoundationHeightsModal
