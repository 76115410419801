import React, { useState } from 'react'
import classnames from 'classnames'
import { ShowMoreButton } from '@saatva-bits/pattern-library.components.show-more-button'
import { SimpleSlider } from '@saatva-bits/pattern-library.components.simple-slider'
import useDeviceType from '@/hooks/useDeviceType'
import styles from './MarketSurveyResults.module.scss'

const MarketSurveyResults = ({ children }) => {
    const { isMobile } = useDeviceType('mobile')
    const [shouldHideSurveyCards, setShouldHideSurveyCards] = useState(isMobile)
    const marketSurveyCards = [
        {
            heading: '97.5%',
            content: 'of customers surveyed said they would recommend Saatva to their friends and family*'
        },
        {
            heading: '91%',
            content: 'of customers surveyed said they wake up more refreshed after sleeping on their Saatva than on their previous mattress*'
        },
        {
            heading: '95%',
            content: 'of customers surveyed said that they are sleeping better on their Saatva than on their previous mattress*'
        },
        {
            heading: '93.1%',
            content: 'of customers surveyed said that Saatva helped alleviate their NECK pain*'
        },
        {
            heading: '93.9%',
            content: 'of customers surveyed said that Saatva helped alleviate their BACK pain*'
        }
    ]

    const renderSurveyCard = (card, index) => {
        const surveyCardClasses = classnames(styles.surveyCard, {
            'u-hidden--md-down': shouldHideSurveyCards && index > 1
        })
        return (
            <aside key={`survey-card-${index}`} className={surveyCardClasses}>
                <div className={styles.surveyCard__heading}>{card.heading}</div>
                <div className={styles.surveyCard__content}>{card.content}</div>
            </aside>
        )
    }

    const surveyCardElements = marketSurveyCards.map((card, index) => renderSurveyCard(card, index))

    return (
        <section className={styles.marketSurveyResults} id='marketSurveyResults'>
            <div className={`${styles.marketSurveyResults__container} container`}>
                <h3 className={`${styles.marketSurveyResults__header} t-color--white t-heading1 u-textCenter`}>
                    Don't just take our word for it
                </h3>
                {isMobile
                    ? (
                        <>
                            {surveyCardElements}
                            <ShowMoreButton
                                className={styles.marketSurveyResults__showMoreButton}
                                isExpanded={!shouldHideSurveyCards}
                                onClick={() => setShouldHideSurveyCards(!shouldHideSurveyCards)}
                            />
                        </>)
                    : (
                        <SimpleSlider
                            shownSize={3}
                            onChangeOffsetAmount={1}
                            pageDots={true}
                            dotsClassName={styles.marketSurveyResults__dots}
                            leftIconClassName={`${styles.marketSurveyResults__icon} ${styles.marketSurveyResults__iconLeft} t-color--white`}
                            rightIconClassName={`${styles.marketSurveyResults__icon} ${styles.marketSurveyResults__iconRight} t-color--white`}
                            leftButtonClassName={styles.marketSurveyResults__button}
                            rightButtonClassName={styles.marketSurveyResults__button}
                            childClass='marketSurveyResults__card'
                        >
                            {surveyCardElements}
                        </SimpleSlider>
                    )}
                {children}
                <div className={`${styles.marketSurveyResults__footnote} u-textCenter t-color--white`}>
                    <div>*From a recent Saatva customer satisfaction survey, which we conduct on a regular basis to ensure service, product, and overall quality standards.</div>
                    <div>**Based off Saatva return rates.</div>
                </div>
            </div>
        </section>
    )
}

export default MarketSurveyResults
