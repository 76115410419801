import React from 'react'
import { SAATVA_CLASSIC, DOG_BED, CLASSIC_STARTER_BUNDLE } from '@/constants/product-codes'
import styles from './_GoodMorningAmericaMessage.module.scss'

const GoodMorningAmericaMessageConfig = {
    [`${SAATVA_CLASSIC}`]: () => {
        return (
            <>
                <div className={`${styles.copy} u-hidden--md-up`}>
                    Awarded “Best Overall Mattress”
                </div>
                <div className={`${styles.copy} u-hidden--md-up`}>
                    by Good Morning America
                </div>
                <div className={`${styles.copy} ${styles.copyLargeScreen} u-hidden--md-down`}>
                    Awarded “Best Overall Mattress in 2024” by Good Morning America
                </div>
            </>
        )
    },
    [`${CLASSIC_STARTER_BUNDLE}`]: () => {
        return (
            <>
                <div className={`${styles.copy} u-hidden--md-up`}>
                    Awarded “Best Overall Mattress”
                </div>
                <div className={`${styles.copy} u-hidden--md-up`}>
                    by Good Morning America
                </div>
                <div className={`${styles.copy} ${styles.copyLargeScreen} u-hidden--md-down`}>
                    Awarded “Best Overall Mattress in 2024” by Good Morning America
                </div>
            </>
        )
    },
    [`${DOG_BED}`]: () => {
        return (
            <>
                <div className={`${styles.copy} u-hidden--md-up`}>
                    Awarded “Best Pet Bed Pick in 2024”
                </div>
                <div className={`${styles.copy} u-hidden--md-up`}>
                    by Good Morning America
                </div>
                <div className={`${styles.copy} ${styles.copyLargeScreen} u-hidden--md-down`}>
                    Awarded “Best Pet Bed Pick in 2024” by Good Morning America
                </div>
            </>
        )
    }
}

export default GoodMorningAmericaMessageConfig
