import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { usePromotionsData } from '@saatva-bits/pattern-library.modules.promotions'
import { useProductState, useProductData } from '@saatva-bits/pattern-library.modules.selection'
import { Button } from '@saatva-bits/pattern-library.components.button'
import { useFeatureFlag } from '@saatva-bits/pattern-library.modules.launch-darkly'
import { storageWrapper } from '@saatva-bits/pattern-library.utils.storage'

import { BASES, MATTRESSES, FRAME_TO_PLATFORM_SURPLUS_PRODUCT_CODES } from '@/constants/product-collections'
import styles from './OutOfStockMessage.module.scss'

const OutOfStockMessage = ({
    className,
    enableBackInStockAlerts = true,
    productCode
}) => {
    const { isOn: isPlatformBedFrameCtaEnabled } = useFeatureFlag('ENABLE_BEDFRAME_OOS.EX-158')
    const { isBundleProduct } = useProductData(productCode)
    const { sku, genericName, limitedEdition: isLimitedEdition } = useProductState(productCode)
    const { surplusDiscountDefinitions } = usePromotionsData()
    const isClientSide = typeof window !== 'undefined' && typeof document !== 'undefined'
    const storage = storageWrapper()

    const subscribedToSku = sku && isClientSide && storage.getItem(`${sku}-OOS-alert`, 'local')
    const ctaInitialState = (isLimitedEdition || isBundleProduct) ? false : enableBackInStockAlerts
    const [hasCta, setHasCta] = useState(ctaInitialState)
    const [hasSubscribed, setHasSubscribed] = useState(subscribedToSku)
    const [intervalID, setIntervalID] = useState(0)
    const isFrameToPlatformProduct = FRAME_TO_PLATFORM_SURPLUS_PRODUCT_CODES.includes(productCode) && surplusDiscountDefinitions[productCode]?.discountValueType === 'percent'

    // When a user has subscribed to an out of stock email notification, third-party Cordial code
    // adds a key with named `${sku}-OOS-alert` with a value of true in localStorage. This checks for that key.
    const checkForSKUKey = () => {
        const skuKeyValue = storage.getItem(`${sku}-OOS-alert`, 'local')
        if (skuKeyValue) {
            setHasSubscribed(true)
        }
    }

    const removeLocalStorageInterval = () => {
        if (intervalID !== 0) {
            clearInterval(intervalID)
            setIntervalID(0)
        }
    }

    // Once the user opens the BackInStockAlert modal, periodically check to see if the user has asked for back in stock alerts
    const handleClick = useCallback(() => {
        // Check every 1000ms to see whether the local storage key has been set
        if (isClientSide) {
            if (window.LIGHTBOX_API && window.LIGHTBOX_API.LIGHTBOX) {
                // the oos-clicked styles are added to the page via GTM
                window.LIGHTBOX_API.LIGHTBOX.closeAllOpenLightboxes()
                document.body.classList.add('oos-clicked')
                document.documentElement.classList.add('oos-clicked')
            }
            setIntervalID(setInterval(checkForSKUKey, 1000))
        }
    }, [sku])

    useEffect(() => {
        const isBaseProduct = BASES.includes(productCode)
        const isMattressProduct = MATTRESSES.includes(productCode)
        const isMadeToOrderProduct = isBaseProduct || isMattressProduct

        if (isMadeToOrderProduct) setHasCta(true)
        if (isFrameToPlatformProduct && !isPlatformBedFrameCtaEnabled) setHasCta(false)
        setHasSubscribed(subscribedToSku)

        window.dataLayer.push({
            'event': 'outOfStock'
        })
    }, [])

    // Reset hasSubscribed state on any variant change
    useEffect(() => {
        setHasSubscribed(subscribedToSku)
        removeLocalStorageInterval()
    }, [sku])

    useEffect(() => {
        removeLocalStorageInterval()
    }, [hasSubscribed])

    // Data attributes for Cordial integration
    const cordialProductName = genericName

    // Handles both Slick slider and updated carousel
    let cordialImageSrc = ''
    if (isClientSide) {
        // TODO: Update the product carousel to include the data selector there directly
        const image = document.querySelector('.productCarouselSlider img[class^="picture-imgix"]')
        const imagePath = image && image.getAttribute('src')
        if (imagePath) {
            cordialImageSrc = `${window.location.origin}${imagePath}`
        }
    }

    // Messaging and CTA
    // This element must have the `productIsOutOfStock` class in order for the cordial integration to work
    const outOfStockContainerClasses = classNames(styles.outOfStockMessage, 'productIsOutOfStock', {
        [className]: className
    })
    const outOfStockMessageClasses = classNames(styles.text, 't-bodySm')
    const buttonClasses = classNames(styles.button, 'u-bgColor--accentBlue')

    // Subscribed messaging
    const subscribedMessage = <p data-selector="out-of-stock-messaging" className={`${styles.text} t-base t-bodySm`}>
        We've received your request to be notified by email when this comes back in stock.
    </p>

    // CTA messaging
    const ctaMessage = <p className={outOfStockMessageClasses}>
        Sign up to be notified by email once this high-demand item is back in stock or call us for a timeframe quote at <a href="tel:1-877-672-2882" className='t-underline'>1-877-672-2882</a>.
    </p>

    // Non-CTA Messaging
    const noCtaTimeframe = <p className={outOfStockMessageClasses}>
        Call us at <a href="tel:1-877-672-2882" className='t-underline'>1-877-672-2882</a> for a timeframe quote of when this high-demand item will be back in stock.
    </p>
    const noCtaLimitedEdition = <>
        <p className={outOfStockMessageClasses}>This limited edition color is out of stock in this size.</p>
        <p className={outOfStockMessageClasses}>Please select another color or style.</p>
    </>
    const productText = isBundleProduct ? 'bundle' : 'item'
    const noCtaHighDemand = <>
        <p className={outOfStockMessageClasses}>This high demand {productText} is out of stock.</p>
        <p className={outOfStockMessageClasses}>For more info, call us at <a href="tel:1-877-672-2882" className='t-underline'>1-877-672-2882</a>.</p>
    </>

    const noCtaMessage = isLimitedEdition
        ? noCtaLimitedEdition
        : (isFrameToPlatformProduct || isBundleProduct)
            ? noCtaHighDemand
            : noCtaTimeframe

    // Final Messaging
    const deducedMessage = hasSubscribed
        ? subscribedMessage
        : hasCta
            ? ctaMessage
            : noCtaMessage

    return (
        <div
            className={outOfStockContainerClasses}
            data-cordial-product-image={cordialImageSrc}
            data-cordial-product-name={cordialProductName}
            data-cordial-product-sku={sku}>
            <div className={styles.wrapper}>
                <div className={styles.message}>
                    {deducedMessage}
                </div>
            </div>
            {hasCta && !hasSubscribed && (
                <Button
                    kind='primary'
                    className={buttonClasses}
                    data-selector='back-in-stock-alert'
                    onClick={handleClick}
                >
                    Email me when in stock
                </Button>
            )}
        </div>
    )
}

OutOfStockMessage.propTypes = {
    className: PropTypes.string,
    enableBackInStockAlerts: PropTypes.bool,
    productCode: PropTypes.string
}

export default OutOfStockMessage
