const ELEMENTS_QUERY = '[href]:not([hidden]), button:not([disabled]), textarea, input, select'

/**
 * @description Focus Trapper function, avoid moving into the container outside elements
 * Call this function on the onKeyDown event of the div container element
 * @param  {object} e keyDown event
 * @param  {object} containerRef React reference of the container
 * @param  {string} elementsQuery query to get focusable elements
 */
const onFocusTrapper = (e, containerRef, onEsc, elementsQuery = ELEMENTS_QUERY) => {
    if (e.key === 'Escape' && onEsc) {
        onEsc()
        e.preventDefault()
    }

    // only execute if tab is pressed
    if (e.key !== 'Tab') return

    // here we query all focusable elements, customize as your own need
    const focusableElements = containerRef.current.querySelectorAll(elementsQuery)

    const firstElement = focusableElements[0]
    const lastElement = focusableElements[focusableElements.length - 1]

    // if going forward by pressing tab and lastElement is active shift focus to first focusable element
    if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus()
        return e.preventDefault()
    }

    // if going backward by pressing tab and firstElement is active shift focus to last focusable element
    if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus()
        e.preventDefault()
    }
}

export default onFocusTrapper
