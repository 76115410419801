import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useProductData } from '@saatva-bits/pattern-library.modules.selection'
import Reviews from '@/contexts/reviews'
import useDeviceType from '@/hooks/useDeviceType'
import styles from './YotpoPDPReviews.module.scss'
import { OFFSETS, scrollToElement } from '@saatva-bits/pattern-library.utils.position'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { Global } from '@/contexts'
import { CLASSIC_STARTER_BUNDLE, SAATVA_CLASSIC } from '@/constants/product-codes'
import { storageWrapper } from '@saatva-bits/pattern-library.utils.storage'
import logger from '@/utils/logger'

const YotpoPDPReviews = ({
    productCode
}) => {
    const { urlProductCode } = useContext(Global.Context)
    let hideReviewQuestionBtn = false
    const storage = storageWrapper()

    if (urlProductCode === CLASSIC_STARTER_BUNDLE) {
        productCode = SAATVA_CLASSIC
        hideReviewQuestionBtn = true
    }

    const { isDesktop } = useDeviceType('desktop')
    const [isYotpoWidgetLoaded, setIsYotpoWidgetLoaded] = useState(false)
    const [isYotpoInitStarted, setIsYotpoInitStarted] = useState(false)
    const { name: productName, category: productCategory, price: productPrice } = useProductData(productCode)
    const ReviewsContext = useContext(Reviews.Context)
    const { questionCount: totalQuestions } = ReviewsContext

    const currencyCode = 'USD'
    const headerText = hideReviewQuestionBtn ? `Saatva Classic Customer Reviews` : 'Customer reviews'
    const [isYotpoV3, setIsYotpoV3] = useState(false)

    function buildLogLoc(functionName) {
        return `components.YotpoPDPReviews.${functionName}`
    }

    // Check if main widget has loaded, returns true if initialization is complete
    const initializeYotpoState = () => {
        if (isYotpoWidgetLoaded) {
            return true
        }
        if (document) {
            if (document.querySelector('.yotpo.yotpo-main-widget .main-widget')?.innerText) {
                // DELETE THIS CONDITION ONCE THE OLD YOTPO VERSION IS DEPRECATED
                logger.debug({
                    message: 'Initialized as V2',
                    location: buildLogLoc('initializeYotpoState')
                })
                setIsYotpoWidgetLoaded(true)
                setIsYotpoV3(false)
                return true
            } else if (document.querySelector('.yotpo-widget-instance')?.innerText) {
                logger.debug({
                    message: 'Initialized as V3',
                    location: buildLogLoc('initializeYotpoState')
                })
                setIsYotpoWidgetLoaded(true)
                setIsYotpoV3(true)
                return true
            } else {
                if (!isYotpoInitStarted && isDesktop && typeof window !== 'undefined') {
                    logger.debug({
                        message: 'Initializing',
                        location: buildLogLoc('initializeYotpoState')
                    })
                    try {
                        window.yotpo?.initWidgets?.()
                        setIsYotpoInitStarted(true)
                    } catch (e) {
                        console.error('[YotpoPDPReviewsError] Could not initialize Yotpo widgets, will retry in 2000ms', e)
                    }
                } else {
                    logger.debug({
                        message: 'Pending initialization',
                        location: buildLogLoc('initializeYotpoState')
                    })
                }
            }
        }
        return false
    }

    // Sets default tab within the Yotpo reviews widget based on number of reviews and questions for a product
    const setDefaultTab = async () => {
        const yotpoActiveClass = 'yotpo-active'
        const yotpoNavContentElements = document.querySelectorAll('.new-yotpo-small-box, .yotpo-nav-content') // Selects the nav content bar and all reviews/questions content below
        const reviewsTabElements = document.querySelectorAll(`.yotpo-reviews, .yotpo-reviews-filters, .yotpo-reviews-header, [data-type='reviews']`) // Selects all review related components within the nav content section
        const questionsTabElements = document.querySelectorAll(`.yotpo-questions, [data-type='questions']`) // Selects all question & answer related components within the nav content section

        // The data-attribute, 'total-reviews-search', is used to determine if reviews have been processed and published to the PDP.
        // We use this instead of the data from the bottomline API request because the API response returns a different count that
        // does not match what is returned by the widget. This causes styling issues until the submitted reviews are processed.
        // This process takes anywhere from three hours to one day after submission to reconcile.
        const totalReviewsSearchElement = document.querySelector('.total-reviews-search')
        const totalReviewsCount = totalReviewsSearchElement && totalReviewsSearchElement.getAttribute('total-reviews-search') // returns a string

        const hasReviews = totalReviewsCount && totalReviewsCount !== '0'
        const hasQuestions = typeof totalQuestions === 'number' && totalQuestions > 0

        switch (true) {
            case hasReviews:
                reviewsTabElements.forEach((node) => {
                    node.classList.add(yotpoActiveClass)
                })
                break
            case !hasReviews && hasQuestions:
                reviewsTabElements.forEach((node) => {
                    node.classList.remove(yotpoActiveClass)
                })
                questionsTabElements.forEach((node) => {
                    node.classList.add(yotpoActiveClass)
                })

                updateReviewsSummarySection(hasQuestions)
                break
            default:
                yotpoNavContentElements.forEach((node) => {
                    node.classList.add('yotpo-hidden')
                })
                updateReviewsSummarySection()
        }
    }

    // Updates the styling of reviews summary section when a product has no reviews or questions, or there's an API error.
    const updateReviewsSummarySection = (hasQuestions = false) => {
        // handle mobile specific styling
        const filterStarsMobile = document.querySelector(`.bottom-line-items-container-mobile .yotpo-filter-stars`).querySelectorAll(`.yotpo-icon`)
        const filterStarsContainerMobile = document.querySelector(`.bottom-line-items-container-mobile`)

        // Removes empty star class and adds filled star class for mobile component
        filterStarsMobile && filterStarsMobile.forEach((starIconNode) => {
            starIconNode.classList.remove('yotpo-icon-empty-star')
            starIconNode.classList.add('yotpo-icon-star')
        })

        // add element inline styling to mobile elements only to override current inline styles
        filterStarsContainerMobile.style.margin = '1.5rem auto'
        filterStarsContainerMobile.style.zIndex = 'unset'

        // handle desktop specific styling, finds icons within the desktop container
        const filterStarsDesktop = document
            .querySelector(`.bottom-line-items-container-desktop .yotpo-filter-stars`)
            .querySelectorAll(`.yotpo-icon`)

        // Removes empty star class and adds filled star class for desktop component
        filterStarsDesktop && filterStarsDesktop.forEach((starIconNode) => {
            starIconNode.classList.remove('yotpo-icon-empty-star')
            starIconNode.classList.add('yotpo-icon-star')
        })

        // handle general styling
        const reviewsQaLabelsContainer = document.querySelectorAll(`.reviews-qa-labels-container`)
        const writeReviewButton = document.querySelector(`.write-review-button`)
        const filterStarsContainer = document.querySelectorAll(`.bottom-line-items-container-desktop, .bottom-line-items-container-mobile, .bottom-line-items-wrapper, .bottom-line-only-container, .bottom-line-items, .reviews-qa-labels-container, .yotpo-filter-stars`)
        const writeReviewQuestionButtons = document.querySelectorAll(`.write-question-review-buttons-container, .write-question-review-button`)

        // Add display none to the reviews-qa-label-container
        reviewsQaLabelsContainer && !hasQuestions && reviewsQaLabelsContainer.forEach((node) => {
            node.classList.add('yotpo-hidden')
        })

        // Updates inner text of the first write a review button
        if (writeReviewButton) writeReviewButton.innerText = 'Be the first to write a review for this product'

        // applies a 'no-review' class to each component above the nav content section
        filterStarsContainer && filterStarsContainer.forEach((node) => {
            node.classList.add('no-reviews')
        })
        writeReviewQuestionButtons && writeReviewQuestionButtons.forEach((node) => {
            node.classList.add('no-reviews')
        })
    }

    // Set up both click and keydown events for analytics
    const addEvents = (selector, dataLayerObject) => {
        const result = document.querySelectorAll(selector)
        if (!result) return

        dataLayerObject.productName = productName

        result.forEach(element => {

            if (!element) return 

            try {
                element.addEventListener('click', () => window.dataLayer.push(dataLayerObject))
                element.addEventListener('keydown', () => window.dataLayer.push(dataLayerObject))
            } catch (e) {
                console.error('[YotpoPDPReviewsAnalyticsEventsError] Could not add analytics events', e)
            }
        })
    }

    // Adds event tracking for the user navigating through pages of reviews.
    // The function also handles the logic to re-add the listener after each new page is loaded.

    const getReviewsPageNumber = () => {
        const selectedPage = document.querySelectorAll('a.selected.yotpo-reviews-pagination-item')
        return selectedPage[0]?.getAttribute('page')
    }

    // This handler needs to be out of scope of the `addReviewPageLinkEvents` function to ensure 
    // a stable function reference for removing the event listener.
    // If this is defined within the function or its loop:
    //  - each function reference is scoped to the loop (and therefore unique)
    //  - the event listeners stack on the button rather than being removed,
    //  - we get an exponential increase of the number of events fired on each successive click
    const reviewLinkEventHandler = (e) => {
        const newEventData = {
            'event': 'reviewPage',
            productName,
            pageNumber: getReviewsPageNumber()
        }
        logger.debug({
            message: `Review Pagination Link ${e.type} Event (v3)`,
            location: buildLogLoc('reviewLinkEventHandler'),
            extraInfo: newEventData
        })
        window.dataLayer.push(newEventData)
        setTimeout(() => addReviewPaginationLinkEvents(), 3000)
    }

    const addReviewPaginationLinkEvents = () => {
        const reviewPaginationLinks = document.querySelectorAll('a.yotpo-reviews-pagination-item')
        if (!reviewPaginationLinks) {
            setTimeout(() => addReviewPaginationLinkEvents(), 3000)
            return
        }

        reviewPaginationLinks.forEach(link => {
            if (!link) return

            try {
                // Remove each listener before adding to ensure they don't stack
                link.removeEventListener('click', reviewLinkEventHandler)
                link.addEventListener('click', reviewLinkEventHandler)

                link.removeEventListener('keydown', reviewLinkEventHandler)
                link.addEventListener('keydown', reviewLinkEventHandler)
            } catch (e) {
                console.error('[YotpoPDPReviewsAnalyticsEventsError] Could not re-add pagination link events', e)
            }
        })
    }

    // DELETE THIS, ONCE THE OLD YOTPO VERSION IS DEPRECATED
    // Adds event tracking for the user navigating through pages of reviews.
    // The function also handles the logic to re-add the listener after each new page is loaded.
    const addReviewPaginationLinkEventsV2 = () => {
        const reviewPageLinks = document.querySelectorAll('a.yotpo-page-element.goTo, a.yotpo_previous, a.yotpo_next')
        if (!reviewPageLinks) {
            setTimeout(() => addReviewPaginationLinkEventsV2(), 3000)
        }

        reviewPageLinks.forEach(link => {
            if (!link) return
            // Use page data attribute on number links and parse from href for arrows
            const pageNumber = (link.getAttribute('data-page') || link.getAttribute('href') || '').split('=').pop()
            if (!pageNumber) return // for disabled anchor links or other special cases

            const eventData = {
                'event': 'reviewPage',
                productName: productName,
                pageNumber
            }

            try {
                link.addEventListener('click', () => {
                    logger.debug({
                        message: 'Review Pagination Link click event (v2)',
                        location: buildLogLoc('addReviewPaginationLinkEventsV2'),
                        extraInfo: eventData
                    })
                    window.dataLayer.push(eventData)
                    setTimeout(() => addReviewPaginationLinkEventsV2(), 3000)
                })
                link.addEventListener('keydown', () => {
                    logger.debug({
                        message: 'Review Pagination Link keydown event (v2)',
                        location: buildLogLoc('addReviewPaginationLinkEventsV2'),
                        extraInfo: eventData
                    })
                    window.dataLayer.push(eventData)
                    setTimeout(() => addReviewPaginationLinkEventsV2(), 3000)
                })
            } catch (e) {
                console.error('[YotpoPDPReviewsAnalyticsEventsError] Could not re-add page link events', e)
            }
        })
    }

    const pushDataLayerEvent = () => {
        const reviewDataLayerObject = {
            'event': 'reviewSubmit',
            'productName': productName,
            'rating': isYotpoV3 ? storage.getItem('starRated', 'session') : document.querySelectorAll('span.review-star.yotpo-icon-star')?.length
        }
        window.dataLayer.push(reviewDataLayerObject)
        storage.removeItem('starRated', 'session')
    }

    // DELETE THIS, ONCE THE OLD YOTPO VERSION IS DEPRECATED
    // Separate function to ensure the rating is dynamic on form submit rather than the default value (0)
    const addReviewSubmitEventV2 = () => {
        const reviewSubmitButton = document.querySelector('div.write-review-wrapper input.yotpo-submit')

        if(!reviewSubmitButton) return 

        reviewSubmitButton.addEventListener('click', () => pushDataLayerEvent())
        reviewSubmitButton.addEventListener('keydown', () => pushDataLayerEvent())
    }

    const addStarsEventListeners = () => {
        const stars = document.querySelectorAll('fieldset svg.yotpo-star-rating-icon.yotpo-sr-star-full.yotpo-star-rating-icon')
        
        if(!stars) return

        stars.forEach((star, index) => {
            if (!star) return
            star.addEventListener('click', () => {
                storage.setItem('starRated', index + 1, 'session')
            })
        })
    }

    // Separate function to ensure the rating is dynamic on form submit rather than the default value (0)
    const addReviewSubmitEvent = () => {
        const reviewSubmitButton = document.querySelector('div.yotpo-modal button.yotpo-new-review-submit')

        if(!reviewSubmitButton) return 

        reviewSubmitButton.addEventListener('click', () => pushDataLayerEvent())
        reviewSubmitButton.addEventListener('keydown', () => pushDataLayerEvent())
    }

    // Duplicated in ReviewsAccordion component for mobile purposes
    const addWidgetTabSelectors = () => {
        const widgetTabs = document.getElementsByClassName('ugc-storefront-widgets-tabs-container__tab')
        if (widgetTabs.length >= 2) {
            widgetTabs[0].setAttribute('data-selector', `${widgetTabs[0].textContent.toLowerCase()}`)
            widgetTabs[1].setAttribute('data-selector', `${widgetTabs[1].textContent.toLowerCase()}`)
        }
    }

    // Analytics event tracking
    const addYotpoEventListeners = () => {
        window.dataLayer = window.dataLayer || []
        if (isYotpoV3) { // New version changes
            addEvents('button.yotpo-new-review-btn', { 'event': 'reviewCreate' }) // Write a review button
            addEvents('div.yotpo-review-votes-icons[aria-label="Vote up"]', { 'event': 'reviewLike' }) // Like / thumbs up review
            addEvents('div.yotpo-review-votes-icons[aria-label="Vote down"]', { 'event': 'reviewDislike' }) // Dislike / thumbs down review
            addReviewPaginationLinkEvents()
            addWidgetTabSelectors()
        } else { // DELETE THIS, ONCE THE OLD YOTPO VERSION IS DEPRECATED
            addEvents('button.write-review-button', { 'event': 'reviewCreate' }) // Write a review button
            addEvents('.yotpo span.yotpo-action', { 'event': 'reviewShare' }) // Share a review
            addEvents('div.yotpo-icon-btn-small.vote-btn[data-type="up"]', { 'event': 'reviewLike' }) // Like / thumbs up review
            addEvents('div.yotpo-icon-btn-small.vote-btn[data-type="down"]', { 'event': 'reviewDislike' }) // Dislike / thumbs down review
            addEvents('button.write-question-button', { 'event': 'questionCreate' }) // Ask a question button
            addEvents('div.write-question-wrapper input.yotpo-submit', { 'event': 'questionSubmit' }) // Ask a question submit
            addReviewSubmitEventV2()
            addReviewPaginationLinkEventsV2()
        }
    }

    // DEF-22328: the "More filters" button on mobile has a bug where it sets the body to `overflow: hidden`,
    // which prevents users from scrolling the page. This is needed until we migrate to the v3 reviews widget.
    const checkIfMobileFiltersModalIsOpen = () => {
        const selector = '.yotpo.yotpo-display-wrapper.mobile-filters-modal > .yotpo-modal-base.yotpo-modal-active'
        const filtersModal = document.querySelector(selector)
        // If the filters modal is open, wait and check again in a second
        if (filtersModal) {
            setTimeout(() => checkIfMobileFiltersModalIsOpen(), 1000)
        } else { // Once closed, remove the overflow hidden from the body
            document.body.style.overflow = 'unset'
        }
    }

    const addFilterButtonListener = () => {
        const moreFiltersButton = document.querySelector('.yotpo-icon-btn.more-filters-btn')
        if (!moreFiltersButton) return
        moreFiltersButton.addEventListener('click', checkIfMobileFiltersModalIsOpen)
    }

    const writeReviewHandler = () => {
        let sectionId = null

        if (typeof window !== 'undefined' && window) {
            const urlHash = window.location.hash
            sectionId = urlHash && urlHash.includes('#write-a-review') ? 'write-review-tabpanel-main-widget' : null // Looks for 'write-a-review' hash in url
        }

        if (sectionId) {
            const writeReviewButton = isYotpoV3
                ? document.querySelector('button.yotpo-new-review-btn')
                : document.querySelector('button.write-review-button')

            logger.debug({
                message: 'Found section successfully',
                location: buildLogLoc('writeReviewHandler'),
                extraInfo: { 
                    buttonFound: !!writeReviewButton,
                    isYotpoV3: isYotpoV3
                }
            })

            // Adds a scrollToElement function when the review button is clicked.
            const handleWriteAReviewClick = (e) => {
                e.preventDefault()
                scrollToElement(sectionId, OFFSETS.both, 1000, 'easeInQuad', 100, false)
            }

            if (writeReviewButton) {
                writeReviewButton.addEventListener('click', handleWriteAReviewClick)

                if (isDesktop) {
                    writeReviewButton.click()
                } else {
                    // On Mobile and Tablet, this finds the reviews accordion tab and clicks it to review the review button and contents.
                    const reviewsAccordionButton = document.getElementById('reviews-accordion-title')
                    if (reviewsAccordionButton) reviewsAccordionButton.click()
                    writeReviewButton.click()
                }
            }

            // Removes event so scrolling does not happen on each click.
            return () => {
                if (writeReviewButton) {
                    writeReviewButton.removeEventListener('click', handleWriteAReviewClick)
                }
            }
        }
    }

    const scrollToReviewsHandler = () => {
        let scrollToReviews = false

        if (typeof window !== 'undefined' && window) {
            const urlHash = window.location.hash
            scrollToReviews = urlHash && urlHash.includes('#customer-reviews')
        }

        if (scrollToReviews) {
            if (!isDesktop) {
                // Toggles the mobile review accordion open
                ReviewsContext.dispatch({
                    type: 'SET_ACCORDION_VALUES',
                    accordionName: 'reviews-accordion',
                    accordionContent: 'reviews-accordion-content',
                    accordionTitle: 'reviews-accordion-title',
                })
            }
            scrollToElement('customer-reviews', OFFSETS.both, 1000, 'easeInOutQuad', 100, false, 150)
        }
    }

    useEffect(() => {
        let timeoutId
        if (document && window) {
            logger.debug({
                message: 'Calling initializeYotpoState',
                location: buildLogLoc('useEffect')
            })
            if (!initializeYotpoState()) {
                logger.debug({
                    message: 'Setting timeout for initialization check',
                    location: buildLogLoc('useEffect')
                })
                timeoutId = setTimeout(() => initializeYotpoState(), 2000)
            }
        }
        return () => {
            clearTimeout(timeoutId)
        }
    }, [])

    useEffect(() => {
        if (isYotpoWidgetLoaded && !isYotpoV3) {
            addFilterButtonListener() // TODO: Remove once Yotpo widget version is updated.
            setDefaultTab()
        }
        writeReviewHandler()
        scrollToReviewsHandler()
        addYotpoEventListeners()
    }, [isYotpoWidgetLoaded, isYotpoV3])

    const checkIfQATabIsClicked = () => {
        if (!document) {
            setTimeout(() => checkIfQATabIsClicked(), 2000)
        }             

        document.getElementsByClassName('ugc-storefront-widgets-tabs-container__tab')[1]?.addEventListener('click', () => {
            addEvents('a.yotpo-question-btn', { 'event': 'questionCreate' }) // Ask a question button
            document.getElementsByClassName('yotpo-question-btn')[0]?.addEventListener('click', () => { // Event on ask question btn
                setTimeout(() => { // Gives time to the element
                    addEvents('.yotpo-question-submit', { 'event': 'questionSubmit' }) // Ask a question submit
                }, 1000)
            })
        })
    }

    const checkIfModalReviewIsOpen = () => {
        if (!document) {
            setTimeout(() => checkIfModalReviewIsOpen(), 2000)
            return
        }  

        document.getElementsByClassName('yotpo-new-review-btn')[0]?.addEventListener('click', () => {
            setTimeout(() => { // Gives time to the element
                addReviewSubmitEvent()
                addStarsEventListeners()
            }, 1000)
            
        })
    }
    
    // This two methods triggers if the element exists
    if (isYotpoV3) {
        checkIfQATabIsClicked()
        checkIfModalReviewIsOpen()
    }
    // Fixes a bug where the widget disappears when switching mobile to desktop
    useEffect(() => {
        if (typeof window !== 'undefined' && window.yotpo && isDesktop) {
            try {
                window.yotpo?.initWidgets?.()
            } catch (error) {
                console.error(`[YotpoPDPReviews] Error when running initWidgets on desktop`, error)
            }
        }
    }, [isDesktop])

    // Write question and review button will be hidden when the classic-starter-bundle renders reviews
    const hideButtons = () => {
        if(typeof document !== 'undefined' && !isYotpoV3) {
            document.querySelectorAll('.write-question-review-button').forEach(function(el) {
                el.style.display = 'none'
            })
        }
    }

    // If the reviews are for classic-starter-bundle, it will render the links to the other addons
    const showLinksToAddons = () => {
        return(
            <div className={`u-marginTop--lg ${styles.addonLinks}`}>
                <span className="t-link"><a target="_blank" href='/furniture/foundation#customer-reviews' class='t-link'>Foundation & Frame Reviews<SvgSprite spriteID='icon-link'/></a></span>
                <span className="t-link"><a target="_blank" href='/bedding/organic-mattress-pad#customer-reviews' class='t-link'>Organic Mattress Pad Reviews<SvgSprite spriteID='icon-link'/></a></span>
            </div>
        )
    }

    if(hideReviewQuestionBtn) {
        hideButtons()
    }

    return (
        <section id="customer-reviews">
            <div className={`u-bgColor--white ${styles.yotpoReviews}`}>
                <h2 className={`${styles.section__reviewsTitle} t-heading2 u-textCenter t-black`}>
                    {headerText}
                </h2>
                {hideReviewQuestionBtn && showLinksToAddons()}
            </div>
            <div
                className="yotpo yotpo-main-widget container"
                data-product-id={productCode}
                data-price={productPrice}
                data-currency={currencyCode}
                data-name={productName}
                data-url={`https://www.saatva.com/${productCategory}/${productCode}`}
            ></div>
        </section>
    )
}

YotpoPDPReviews.propTypes = {
    productCode: PropTypes.string.isRequired,
}

export default YotpoPDPReviews
