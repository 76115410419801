import React from 'react'
import PropTypes from 'prop-types'

import { useAddProductsToCart, useProductData, useProductState } from '@saatva-bits/pattern-library.modules.selection'
import { usePromotionsData, getTotalPotentialValueDiscount } from '@saatva-bits/pattern-library.modules.promotions'

import { BannerPDPValueDiscount, BannerPDPSitewideDiscount } from './partials'
import { useAddonContext } from '@/contexts/addon'

const PromoBanner = ({
    productCode
}) => {
    const { category } = useProductData(productCode)
    const { selectedAddonProductCodes, getProductCode } = useAddonContext()
    const addonProductCodes = [...selectedAddonProductCodes].map((code) => getProductCode(code))
    const { items } = useAddProductsToCart([productCode, ...addonProductCodes])
    const productInfoForDiscount = items.map(item => {
        return {
            category: item.category,
            isInStock: item.inStock,
            isPrimaryProduct: item.parentSku === productCode,
            parentSku: item.parentSku,
            price: item.price,
            quantity: item.quantity,
            sku: item.sku
        }
    })
    const { valueDiscountDefinitions } = usePromotionsData()

    // Build the list of all PDPs that are eligible for a value discount (when active).
    // Pillow pair is excluded because it's always applied and mentioned on the PDPs.
    // discountedProductSkus is an array of applicable product codes for each discount type.
    // Array.map returns an array of arrays so we flatten it for easier comparison.
    const discountProductTypes = Object.keys(valueDiscountDefinitions).filter(key => key !== 'pillowPair')
    const valueProducts = discountProductTypes.map(key => valueDiscountDefinitions[key].discountedProductSkus || []).flat()
    const onValueProductPage = valueProducts.includes(productCode) // Check if PDP is eligble for banner
    const totalPotentialValueDiscount = onValueProductPage && getTotalPotentialValueDiscount(productInfoForDiscount, valueDiscountDefinitions)
    const showValueDiscountBanner = !!totalPotentialValueDiscount

    const variantSelected = useProductState(productCode)
    if (!variantSelected.inStock) {
        return null
    }

    // Display a value discount if applicable, otherwise default to the sitewide discount
    if (showValueDiscountBanner) {
        return <BannerPDPValueDiscount totalPotentialValueDiscount={totalPotentialValueDiscount} />
    } else {
        return <BannerPDPSitewideDiscount productCode={productCode} category={category} />
    }
}

PromoBanner.propTypes = {
    productCode: PropTypes.string
}

export default PromoBanner
