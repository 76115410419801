import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from '@saatva-bits/pattern-library.components.button'
import { Icon } from '@saatva-bits/pattern-library.components.icon'

const AddOnToggle = ({
    addOnText,
    className,
    isSelected,
    onClick
}) => {
    const CirclePlusIcon = () => {
        return (
            <Icon
                name="more"
                className="addOnToggle__plusIcon"
                alt="Add product"
                description="Add product"
                titleId="circlePlusIcon" />
        )
    }

    const CircleCheckIcon = () => {
        return (
            <Icon
                name="check"
                className="addOnToggle__checkIcon"
                alt="Remove product"
                description="Remove product"
                titleId="circleCheckIcon" />
        )
    }

    const ActiveIcon = () => isSelected
        ? <CircleCheckIcon />
        : <CirclePlusIcon />

    const classes = classNames('addOnToggle', {
        'is-active': isSelected
    }, className)

    const buttonText = isSelected
        ? 'Added'
        : addOnText

    return (
        <Button
            kind="tertiary"
            className={classes}
            onClick={onClick}>
            <ActiveIcon />
            {buttonText}
        </Button>
    )
}

AddOnToggle.propTypes = {
    addOnText: PropTypes.node.isRequired,
    className: PropTypes.string,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func
}

export default AddOnToggle
