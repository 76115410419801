import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useProductState } from '@saatva-bits/pattern-library.modules.selection'

import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import InfoTooltip from '@/components/InfoTooltip'
import EpigraphDisclaimer from './EpigraphDisclaimer'
import { epigraphSKUOverrides } from '@/utils/epigraph-sku-overrides'
import get from 'lodash/get'

const EpigraphModelViewer = ({
    isArButtonOnly = false,
    productCode,
    className
}) => {
    const vrComponentRef = useRef(null)
    const arButtonRef = useRef(null)
    const uaCode = process.env.NEXT_PUBLIC_UA_CODE
    const measurementId = process.env.NEXT_PUBLIC_GTM_TRACKING_ID
    const { sku: selectedProductSku, size } = useProductState(productCode)
    const [isQrModalVisible, setIsQrModalVisible] = useState(false)
    const [, setIsValidArSku] = useState(true) // we don't read the state, we only use this to force a re-render based on Epigraph call
    const overrideSku = get(epigraphSKUOverrides, `${productCode}.skus[${selectedProductSku}]`, null)
    const sku = overrideSku || selectedProductSku
    const hasArButton = !['Twin', 'Full'].includes(size) // Twin and Full sizes do not have AR capabilities
    const [arHover, setARHover] = useState(false)

    useEffect(() => {
        // Add event listeners to the Epigraph AR Component on PDP, that checks if the consumed SKU is valid in Epigraph's database and if the model viewer is visible.
        const vrComponent = vrComponentRef.current // Reference for an Epigraph VR Model Viewer
        const arButton = arButtonRef.current // Reference for an Epigraph AR button only
        const handleValidProduct = () => {
            setIsValidArSku(true)
        }
        const handleInValidProduct = () => {
            setIsValidArSku(false)
        }

        const handleModelVisibility = (e) => {

            const modelViewer = e.target.shadowRoot.querySelector('model-viewer')
            if (modelViewer) {
                // TODO: REMOVE vrWigglePrompt once Epigraph fixes bug related to pointer remaining after closing maximized viewer.
                const vrWigglePrompt = modelViewer.shadowRoot.querySelector('.slot.interaction-prompt.wiggle')
                modelViewer.addEventListener('model-visibility', (e) => {
                    vrWigglePrompt.classList.toggle('visible', e.detail.visible)
                    if (e.detail.visible === false) {
                        document.querySelector('.vrReplacementPoster--img').classList.remove('u-hidden')
                    }
                })
            }
        }

        // If a 3d Model Viewer is present, add the needed event listeners
        if (vrComponent) {
            vrComponent.addEventListener('epigraph-valid-product', handleValidProduct) // Checks if sku is valid within Epigraph
            vrComponent.addEventListener('epigraph-invalid-product', handleInValidProduct)
            vrComponent.addEventListener('epigraph-experience-type', handleModelVisibility) // checks if model viewer is active/not-active

            // Add class to the desktop instance of the ar component.
            vrComponent.classList.add('epigraph-ar')

            return () => {
                vrComponent.removeEventListener('epigraph-valid-product', handleValidProduct)
                vrComponent.removeEventListener('epigraph-invalid-product', handleInValidProduct)
                vrComponent.removeEventListener('epigraph-experience-type', handleModelVisibility)
            }
        }

        // If an AR button is present, add the needed event listeners
        if (arButton) {
            arButtonRef.current.addEventListener('epigraph-valid-product', handleValidProduct)
            arButtonRef.current.addEventListener('epigraph-invalid-product', handleInValidProduct)

            return () => {
                arButtonRef.current.removeEventListener('epigraph-valid-product', handleValidProduct)
                arButtonRef.current.removeEventListener('epigraph-invalid-product', handleInValidProduct)
            }
        }
    }, [])

    // 3d Model Viewer Logic -------------------
    const vrReplacementPosterInnerClassNames = classNames('vrReplacementPoster-inner',
        'vrReplacementPoster--loadingIcon'
    )

    // Epigraph replacement poster: A loading icon for Desktop and mobile/tablet experiences.
    const vrReplacementIcon = !isArButtonOnly && (
        <div className='vrReplacementPoster' slot='poster'>
            <div className={vrReplacementPosterInnerClassNames}>
                <SvgSprite spriteID='icon-epigraph-3d-cube' className="vrReplacementPoster__svg"/>
            </div>
        </div>
    )

    // AR Button Only Logic ------------------------------
    const qrModalRequirementClasses = classNames('qrModalRequirement t-bodySm', {
        'show': isQrModalVisible
    })

    const handleEpigraphClick = () => {
        // check is the Epigraph QR modal is visible and hides/shows the replacement text respectively - Desktop Only
        const qrModal = arButtonRef.current.shadowRoot.querySelector('qr-modal').shadowRoot.querySelector('.modal')
        const qrModalVisible = qrModal.classList.contains('show')

        qrModalVisible ? setIsQrModalVisible(true) : setIsQrModalVisible(false)
    }

    const arButtonClasses = classNames('arButtonContainer', {
        [`${className}`]: className
    })

    const arButton = hasArButton ? (
        <div className={arButtonClasses} key={`parent-ar-${sku}`} id={sku}>
            <epigraph-ar
                sku={sku}
                ua-code={uaCode}
                ga-measurement-id={measurementId}
                onClick={handleEpigraphClick}
                ref={arButtonRef}>
                <button type='button' data-selector='view-in-your-space' className='arButtonContainer__arButton' slot="ar-button"  
                    onMouseEnter={() => setARHover(true)}
                    onMouseLeave={() => setARHover(false)}>
                    <div className='arButtonContainer__arButton__inner'>
                        {!arHover ? <SvgSprite spriteID='icon-epigraph-ar' className='arButtonContainer__svg'/> : <SvgSprite spriteID='icon-epigraph-ar-white' className='arButtonContainer__svg'/>}
                        <div>View in Your Space</div>
                    </div>
                </button>
                <div className={qrModalRequirementClasses} slot='qr-modal-requirement'>
                    Technical requirements:
                    <ul className='qrModalRequirement__techList'>
                        <li>iOS Version 11 and later or Android 8 and later.</li>
                        <li>Apple iOS users: open in Safari browser for best experience</li>
                    </ul>
                </div>
            </epigraph-ar>
            <InfoTooltip
                className='arButtonContainer__tooltip'
                buttonClassName='arButtonContainer__tooltip--button'
                contentClassName='arButtonContainer__tooltip--content'>
                {overrideSku && <><EpigraphDisclaimer productCode={productCode} /><br /></>}
                Apple iOS users: open in Safari browser for best experience
            </InfoTooltip>
        </div>
    ) : null // If the product size is Twin or Full, the AR button will not be rendered.

    return (
        isArButtonOnly
            ? (
                arButton
            )
            : (
                <>
                    <epigraph-ar
                        viewer-visible
                        disable-mobile-fullscreen={true}
                        auto-start
                        sku={sku}
                        ua-code={uaCode}
                        ga-measurement-id={measurementId}
                        button-mode={'none'}
                        max-camera-orbit='auto 90deg auto'
                        orbit-sensitivity='0.5'
                        stop-touch-propagation={true}
                        ref={vrComponentRef}
                    >
                        {vrReplacementIcon}
                    </epigraph-ar>
                    <EpigraphDisclaimer productCode={productCode} selectedProductSku={selectedProductSku} className={'arDisclaimer arDisclaimer__carousel'}/>
                </>
            )
    )
}

EpigraphModelViewer.propTypes = {
    isArButtonOnly: PropTypes.bool,
    imageFilename: PropTypes.string,
    imageFolder: PropTypes.string
}

export default EpigraphModelViewer
