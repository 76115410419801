import React, { useState, useMemo } from 'react'
import classNames from 'classnames'
import styles from './ProductCarouselGallery.module.scss'

import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'
import { Picture } from '@saatva-bits/pattern-library.components.picture-imgix'
import { useFeatureFlag } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.hooks'

import { CATEGORIES } from '@/temp-configs/category-constants'
import ImageGalleryModal from './partials/ImageGalleryModal/ImageGalleryModal'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { createImgixVideo, createImgixVideoThumbnail } from '@/utils/pdpCarousel'
import { default as carouselVideoConfig } from './carouselVideoConfig'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.hooks'

const HERO_WIDTHS = {
    mobile: 575,
    tablet: 768,
    desktop: 1025
}   

const THUMBNAIL_WIDTHS = {
    mobile: 100,
    tablet: 100,
    desktop: 100
}

// Handles both hero and thumbnail images
// TODO: Update function from pdp-carousel-utils to support different classes and widths and replace this.
function createAssetDrivenImage (image, alt, imgixDomain, isHero) {
    const imageClassName = isHero ? [styles.productCarouselGalleryPicture] : 'thumbnail__picture__img'
    const widths = isHero ? HERO_WIDTHS : THUMBNAIL_WIDTHS

    return (
        image &&
        <Picture
            key={`${image.filename}`}
            imageClassName={imageClassName}
            className={isHero && [styles.productCarouselGalleryPicture]}
            widths={widths}
            prefixOverride={{
                mobile: 'none',
                tablet: 'none',
                desktop: 'none'
            }}
            folder={image.folder}
            name={image.filename}
            alt={alt}
            imgixDomain={imgixDomain}
        />
    )
}

const ProductCarouselGallery = ({
    arChild,
    category,
    imgixDomain,
    productCode,
    productImageData,
    children
}) => {
    const { isOn: isMattressDisabled } = useFeatureFlag('DISABLE_GALLERY_M.PD-13729')
    const { isOn: isFurnitureDisabled } = useFeatureFlag('DISABLE_GALLERY_F.PD-13729')
    const { isOn: isBeddingDisabled } = useFeatureFlag('DISABLE_GALLERY_B.PD-13729')
    const isFeatureDisabled = (category === CATEGORIES.MATTRESSES && isMattressDisabled) || (category === CATEGORIES.FURNITURE && isFurnitureDisabled) || (category === CATEGORIES.BEDDING && isBeddingDisabled)
    const { isDesktop, isMobile } = useBreakpoints('desktop')
    const [isGalleryVisible, setIsGalleryVisible] = useState(false)
    const [ activeIndex, setActiveIndex ] = useState(0)
    const { isV1: showLayerDiagram } = useExperiment('EXP.LAYER_PDP_GALLERY.EX-172')
    const videoConfig = showLayerDiagram ? null : carouselVideoConfig[productCode]
    const hasVideoInGallery = !showLayerDiagram && !isFeatureDisabled && videoConfig

    const { heroImages, thumbnailImages } = useMemo(() => {
        const heroImages = productImageData.heroImages.map((productImage) => {
            const isARHero = productImage?.folder?.includes('/ar/')
            if (isARHero) {
                return arChild
            } else {
                return createAssetDrivenImage(productImage, productImageData.altTag, imgixDomain, true)
            }
        })
        const thumbnailImages = productImageData.thumbnailImages.map((productImage) => {
            return createAssetDrivenImage(productImage, productImageData.altTag, imgixDomain, false)
        })

        return {
            heroImages,
            thumbnailImages
        }
    }, [productImageData, isDesktop])

    let galleryAssets, thumbnailAssets
    if (hasVideoInGallery) {
        const videoAssetIndex = videoConfig?.carouselIndex
        // Default to same config as the carousel, but override settings for larger video file
        const galleryVideoConfig = {
            ...videoConfig,
            aspectRatio: isDesktop ? '16-9' : isMobile ? '3-2' : '4-3',
            videoProps: {
                ...videoConfig.videoProps,
                autoPlay: false,
                width: 1600,
                height: 900
            }
        }
        delete galleryVideoConfig.lazyLoadOffset // remove lazy loading

        // Use the video index to splice in the video and thumbnail
        galleryAssets = [
            ...heroImages.slice(0, videoAssetIndex), // images up until the video
            createImgixVideo(galleryVideoConfig, videoAssetIndex), // the video
            ...heroImages.slice(videoAssetIndex) // remaining images
        ]

        const thumbnailAspectRatio = '3-2' // same for mobile and desktop
        thumbnailAssets = [
            ...thumbnailImages.slice(0, videoAssetIndex), // images up until the video
            createImgixVideoThumbnail(videoConfig, undefined, '', false, false, thumbnailAspectRatio), // the video thumbnail
            ...thumbnailImages.slice(videoAssetIndex) // remaining images
        ]
    } else {
        galleryAssets = heroImages
        thumbnailAssets = thumbnailImages
    }
    const isZoomable = heroImages[activeIndex] !== arChild ? activeIndex !== videoConfig?.carouselIndex : heroImages[activeIndex] !== arChild
    const zoomTagClassNames = classNames(styles.productCarouselGalleryHeroImageZoomTag, styles.productCarouselGalleryHeroImageZoomTagRedesign)

    const getHeroImageHeaderContent = () => {
        return (
            isZoomable &&
            <span className={zoomTagClassNames}>
                {isDesktop ? 'Click to zoom' : 'Tap to zoom'}
                <SvgSprite spriteID='icon-zoom' />
            </span>
        )
    }
    const handleOnHeroImageClick = () => {
        if (isZoomable) setIsGalleryVisible(true)
    }

    if (isFeatureDisabled) return children

    return (
        <>
            <ImageGalleryModal
                visibility={isGalleryVisible}
                activeIndex={activeIndex}
                setVisibility={setIsGalleryVisible}
                heroImages={galleryAssets}
                thumbnailImages={thumbnailAssets}
            />
            {React.Children.map(children, child => {
                if (!child) return
                return React.cloneElement(child, {
                    heroImageUlClasses: classNames([styles.productCarouselGalleryHeroImage]),
                    heroImageRightContent: getHeroImageHeaderContent(),
                    onHeroImageClick: handleOnHeroImageClick,
                    setIndex: setActiveIndex
                })
            })}
        </>
    )
}

export default ProductCarouselGallery
