import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const IncludedAddonList = ({
    titleText,
    addonItems
}) => {
    const copyClasses = classNames('includedAddonList__selectionCopy')

    const addonCopy = addonItems.map((product) => {
        const { html, isSelected } = product
        const addedSpan = <span className="includedAddonList__added">Added</span>
        return (
            <>
                <span className={`includedAddonList__check`}>
                    <div className={copyClasses}>
                        {html}
                        {isSelected && addedSpan}
                    </div>
                </span>
            </>
        )
    })

    return (
        <div className={'includedAddonList__container'}>
            <div className='includedAddonList__title'>{titleText}</div>
            {addonCopy}
        </div>
    )
}

IncludedAddonList.propTypes = {
    titleText: PropTypes.string,
    addonItems: PropTypes.shape({
        html: PropTypes.string,
        isSelected: PropTypes.bool
    })
}

export default IncludedAddonList
