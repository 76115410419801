import React from 'react'
import PropTypes from 'prop-types'

const Context = React.createContext()
const Consumer = Context.Consumer

const Provider = ({ children, urlProductCode }) => {
    return (
        <Context.Provider value={{urlProductCode}}>
            { children }
        </Context.Provider>
    )
}

// add display name for dev tool readability
Context.displayName = 'Global'
Provider.displayName = 'GlobalContext'

Provider.propTypes = {
    children: PropTypes.object,
    globalContextData: PropTypes.string,
}

const Global = { Consumer, Context, Provider }

export default Global
