import { scroller } from 'react-scroll'

// Similar to bit scrollToElement, but uses custom delay/duration to avoid jankiness
const scrollToSection = (sectionId, offset) => {
    scroller.scrollTo(sectionId, {
        duration: 1500,
        delay: 100,
        smooth: 'easeInOutQuad',
        offset,
        isDynamic: true,
    })
}

export {
    scrollToSection
}
