const copy = {
    'adjustable-base': {
        'description': 'Our premium remote-controlled base elevates head and feet for customized comfort. No foundation (or boxspring) required.',
        'features': [
            'Zero-clearance design, can be used without legs on platform bases.',
            'Slim profile virtually disappears underneath the mattress',
            'Quiet motor'
        ],
        'imgAlt': 'Saatva Adjustable Base'
    },
    'adjustable-base-plus': (isAdjustableBaseOnly) => {
        return isAdjustableBaseOnly
            ? {
                'description': 'Get even more customized comfort options with deluxe upgrades.',
                'features': [
                    'Zero-clearance design, can be used without legs on platform bases',
                    'Quiet motor',
                    'Full-body massage',
                    'Preset positions',
                    'Under-bed lighting',
                    'Upper-flex option'
                ],
                'imgAlt': 'Saatva Adjustable Base Plus'
            }
            : {
                'description': 'Our deluxe adjustable base elevates head and feet, plus more customized comfort options. No foundation (or boxspring) required.',
                'features': [
                    'Full-body massage',
                    'Preset positions',
                    'Under-bed lighting',
                    'Upper-flex option'
                ],
                'imgAlt': 'Saatva Adjustable Base Plus'
            }
    }
}

export const getRelatedProductDropdownPanelAddonTileCopy = (sku = '', alternateCopyBoolean = false) => {
    if (typeof (copy[sku]) === 'function') {
        return copy[sku](alternateCopyBoolean)
    } else {
        return copy[sku]
    }
}
