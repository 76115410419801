import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { AffirmMessaging } from '@saatva-bits/pattern-library.components.affirm-messaging'
import { affirmRefresh } from '@/utils/affirm'
import styles from './AffirmMessage.module.scss'

const AffirmMessage = ({ finalPrice, productCode }) => {
    const affirmPrice = finalPrice
    const showAffirmMessage = affirmPrice > 0

    useEffect(() => {
        affirmRefresh()
    }, [affirmPrice])

    let attributeData = {
        'class': `${styles.subtotal__affirmMessaging} affirm-as-low-as u-marginBottom u-textRight`,
        'data-amount': affirmPrice * 100,
        'data-page-type': 'product'
    }

    if (productCode) {
        attributeData['data-sku'] = productCode
    }

    const displayMessage = showAffirmMessage ? <AffirmMessaging
        htmlAttributes={attributeData}
    ></ AffirmMessaging>
        : null

    return displayMessage
}

AffirmMessage.propTypes = {
    finalPrice: PropTypes.number,
    productCode: PropTypes.string
}

export default AffirmMessage
