import { SelectionProvider } from '@saatva-bits/pattern-library.modules.selection'
import { FaqProvider } from '@saatva-bits/pattern-library.modules.faq'
import classNames from 'classnames'

import Layout from '@/layouts/Default'
import ProductCarouselWrapper from '@/components/ProductCarouselWrapper'
import Buystack from '@/components/Buystack'
import BundlesBuystack from '@/components/BundlesBuystack'
import DeliveryAndSetup from '@/components/DeliveryAndSetup/DeliveryAndSetup'
import ProductStickyNavBar from '@/components/ProductStickyNav/ProductStickyNavBar'
import { linkConfig } from '@/components/ProductStickyNav/NavLinkConfig'
import GoodMorningAmericaMessage from '@/components/GoodMorningAmericaMessage'
import VideoBelowPDPCarousel from '@/components/VideoBelowPDPCarousel'

import { AddonProvider } from '@/contexts/addon'
import Reviews from '@/contexts/reviews'
import Recommendations from '@/contexts/recommendations'
import Global from '@/contexts/global'
import useDeviceType from '@/hooks/useDeviceType'
import { MATTRESSES } from '@/constants/product-collections'
import { CLASSIC_STARTER_BUNDLE, SAATVA_CLASSIC } from '@/constants/product-codes'
import { ContentThemeProvider } from '@/contexts/contentTheme'
import Content from './content'
import styles from './styles.module.scss'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'
export default function PDPView(props) {
    const {
        products,
        productCode,
        selectionContextProductOverrides,
        faqsAndSpecs,
        reviewsData,
        crossSellSkus,
        contentThemeContextData
    } = props

    const { questionCount, reviewCount, totalProductReviewCount } = reviewsData
    const { isDesktop } = useDeviceType('desktop')
    const hasStickyNavBar = MATTRESSES.includes(productCode)
    const hideFromDesktopClasses = 'u-hidden--lg-up'
    const hideFromMobileClasses = 'u-hidden--lg-down'

    // BEGIN PDP Carousel Redesign test EX-134, this changes the layout of multiple components on the page
    const productPannelWrapperClasses = classNames('section u-paddingTop--none', {
        [styles.overflowVisible]: (isDesktop && productCode == SAATVA_CLASSIC) // style only applies at large breakpoint
    })

    const { isV1: isCarouselRedesignEnabledV1, isV2: isCarouselRedesignEnabledV2 } = useExperiment('EXP.CAROUSEL_REDESIGN_2.EX-121')
    const isAlternateLayout = productCode === SAATVA_CLASSIC && (isCarouselRedesignEnabledV1 || isCarouselRedesignEnabledV2)
    // END PDP Carousel Redesign test EX-134

    return (
        <Layout {...props}>
            <Global.Provider urlProductCode={productCode}>
                <SelectionProvider productsData={products} productOverrides={selectionContextProductOverrides}>
                    <ContentThemeProvider contextData={contentThemeContextData}>
                        <FaqProvider faqList={faqsAndSpecs?.faqList} specList={faqsAndSpecs?.specList}>
                            <Reviews.Provider
                                questionCount={questionCount}
                                reviewCount={reviewCount}
                                totalProductReviewCount={totalProductReviewCount}
                            >
                                <AddonProvider>
                                    <Recommendations.Provider
                                        crossSellSkus={crossSellSkus}
                                    >
                                        <section id='productPanel' className={productPannelWrapperClasses}>
                                            {hasStickyNavBar && <ProductStickyNavBar links={linkConfig[productCode]} />}
                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col col--xs-12 col--md-12 col--lg-7 productPanelWrapper__leftPanel'>
                                                        <ProductCarouselWrapper productCode={productCode} />
                                                        {!isDesktop && <GoodMorningAmericaMessage productCode={productCode} />}
                                                        {/* EX-134 v0: default order of components */}
                                                        {!isAlternateLayout && (
                                                            <>
                                                                {isDesktop && <DeliveryAndSetup productCode={productCode} />}
                                                                {isDesktop && <GoodMorningAmericaMessage productCode={productCode} />}
                                                                <VideoBelowPDPCarousel productCode={productCode} className={hideFromMobileClasses} />
                                                            </>
                                                        )}
                                                        {/* EX-134 v0: END */}
                                                    </div>
                                                    <div className='col col--xs-12 col--md-12 col--lg-5 productPanelWrapper__rightPanel'>
                                                        {productCode === CLASSIC_STARTER_BUNDLE ? <BundlesBuystack productCode={productCode} /> : <Buystack />}
                                                        <VideoBelowPDPCarousel productCode={productCode} className={hideFromDesktopClasses} />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <Content productCode={productCode} />
                                    </Recommendations.Provider>
                                </AddonProvider>
                            </Reviews.Provider>
                        </FaqProvider>
                    </ContentThemeProvider>
                </SelectionProvider>
            </Global.Provider>
        </Layout>
    )
}
