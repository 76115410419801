import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import LegacyPicture from '@/components/LegacyPicture'
import { FormCheckbox } from '@saatva-bits/pattern-library.components.form-checkbox'
import { getRelatedProductDropdownPanelAddonTileCopy } from './relatedProductDropdownPanelAddonTileConfig'

const RelatedProductDropdownPanelAddonTile = ({
    parentSku,
    dropdownPanelClassNames,
    expandConfigFeaturesClasses,
    expandConfigProductDescriptionClasses,
    expandConfigProductDescriptionSubheaderClasses,
    expandConfigPictureClasses,
    isCheckboxDisabled = false,
    checkboxOnChange,
    isSplitSize,
    isChecked,
    checkboxLabel = '',
    tag = false,
    tagLabel,
    addonTileSubheaderLabel = 'Featuring:',
    isAdjustableBasePlusOnly = false
}) => {
    const productCopy = getRelatedProductDropdownPanelAddonTileCopy(parentSku, isAdjustableBasePlusOnly)

    const addonTileContainerClasses = classNames('addonTileContainer', {
        [dropdownPanelClassNames]: dropdownPanelClassNames,
        'addonTileContainer__upgradeSelected': isChecked
    })
    const addonTileDescriptionClasses = classNames('addonTile__description expandConfig__productDescription', {
        [expandConfigProductDescriptionClasses]: expandConfigProductDescriptionClasses
    })
    const addonTileDescriptionSubheaderClasses = classNames('addonTile__description__subheader expandConfig__productDescriptionHeader', {
        [expandConfigProductDescriptionSubheaderClasses]: expandConfigProductDescriptionSubheaderClasses
    })
    const addonTilePictureClasses = classNames('addonTile__description__picture expandConfig__labelImage', {
        [expandConfigPictureClasses]: expandConfigPictureClasses
    })
    const addonFeaturesClasses = classNames('addonTile__description__features__list', {
        [expandConfigFeaturesClasses]: expandConfigFeaturesClasses
    })

    return (
        <div className={addonTileContainerClasses}>
            {tag && tagLabel && <div className='addonTile__tag'>{tagLabel}</div>}
            <div className='addonTile__title'>
                <FormCheckbox
                    id={parentSku}
                    value={parentSku}
                    className='addonTile__checkbox'
                    checked={isChecked}
                    disabled={isCheckboxDisabled}
                    data-selector={`addonTileCheckbox_${parentSku}`}
                    onChange={(e) => { checkboxOnChange(e.target.checked, parentSku) }}
                    containerAttributes={{ 'data-selector': `foundationBaseAddon-${parentSku}-container` }}>
                </FormCheckbox>
                {checkboxLabel}
            </div>
            <div className={addonTileDescriptionClasses}>
                <div>{productCopy.description}</div>
                <div className={addonTileDescriptionSubheaderClasses}>{addonTileSubheaderLabel}</div>
                <div className='addonTile__description__features'>
                    <a href={`/furniture/${parentSku}`}>
                        <LegacyPicture
                            lazyLoad={false}
                            pictureClassName={addonTilePictureClasses}
                            folder='bases'
                            name={isSplitSize ? `thumb-${parentSku}-addon-split` : `thumb-${parentSku}-addon`}
                            alt={productCopy.imgAlt || 'Add foundation or adjustable base'}
                            type={'jpg'}
                            targetedDevices={[]}
                        />
                    </a>
                    <ul className={addonFeaturesClasses}>
                        {productCopy.features.map((feature, i) => (
                            <li key={`${parentSku}-feature-${i + 1}`} className='addonTile__description__features__list__item'>{ feature }</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

RelatedProductDropdownPanelAddonTile.propTypes = {
    parentSku: PropTypes.string.isRequired,
    dropdownPanelClassNames: PropTypes.string,
    expandConfigFeaturesClasses: PropTypes.string,
    expandConfigProductDescriptionClasses: PropTypes.string,
    expandConfigProductDescriptionSubheaderClasses: PropTypes.string,
    expandConfigPictureClasses: PropTypes.string,
    isCheckboxDisabled: PropTypes.bool,
    checkboxOnChange: PropTypes.func.isRequired,
    isSplitSize: PropTypes.bool,
    isChecked: PropTypes.bool,
    checkboxLabel: PropTypes.any.isRequired,
    tag: PropTypes.bool,
    tagLabel: PropTypes.string,
    addonTileSubheaderLabel: PropTypes.string,
    isAdjustableBasePlusOnly: PropTypes.bool
}

export default RelatedProductDropdownPanelAddonTile
