import React from 'react'
import PropTypes from 'prop-types'
import { FormRadioGroup } from '@saatva-bits/pattern-library.components.form-radio-group'
import { FormToggle } from '@saatva-bits/pattern-library.components.form-toggle'
import { useAttributeOptions } from '@saatva-bits/pattern-library.modules.selection'
import { ProductConfigLabel } from '@/components/Addon/Addons/FoundationBaseAddon/partials/ProductConfigLabel'

const ProductSelectToggle = ({
    productCode,
    attributeCode,
    dataSelector,
    displayLabel = true
}) => {
    const options = useAttributeOptions(productCode, attributeCode)

    const optionElements = options && options.values.map((value) =>
        ({
            label: (value.label || '').split(' ')[0], // removes extra "Foundation" from the label
            value: value.code
        })
    )

    const labelText = `Select ${options.displayName}`

    const onChange = (selectedItem) => {
        options.setAttributeValue(selectedItem)
    }

    return (
        <div data-selector={dataSelector} className="productSelectToggle">
            {displayLabel &&
                <ProductConfigLabel
                    index={0}
                    label={labelText}
                    isCustomLabel={true}
                />
            }
            <FormRadioGroup
                name={`product-option-${attributeCode}`}
                dataSrc={optionElements}
                initialSelection={options.selectedValue}
                onSelectionUpdate={onChange}
                className="productSelectToggle__group"
            >
                <FormToggle />
            </FormRadioGroup>
        </div>
    )
}

ProductSelectToggle.propTypes = {
    productCode: PropTypes.string,
    attributeCode: PropTypes.string,
    dataSelector: PropTypes.string,
    displayLabel: PropTypes.bool
}

export default ProductSelectToggle
