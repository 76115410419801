import { PRODUCT_CODES } from '@/constants'

const FoundationModalConfig = {
    [PRODUCT_CODES.SAATVA_HD]: {
        diagramImage: 'hdFoundationDiagram',
        secondaryHeader: 'Choosing an HD foundation height',
        modalText: `
            <p>Our high-durability HD foundation comes in two heights. Choose the lower <span class="t-fontWeight--normal">4.75” HD foundation</span> if you are shorter, prefer a lower bed height, or have a bed frame that requires a lower foundation so it doesn’t stick out above the side rails.</p>
            <p>Choose the taller <span class="t-fontWeight--normal">8.74” HD foundation</span> if you prefer a taller bed height or if your bed frame side rails require a taller foundation. Don’t have a bed frame? The optional Universal Metal Frame elevates your foundation and mattress 7.5” off the ground.</p>
        `
    },
    [PRODUCT_CODES.SAATVA_YOUTH]: {
        diagramImage: 'youthFoundationDiagram',
        secondaryHeader: 'Choosing a foundation height for your child',
        modalText: `
        <p>Our mattress foundation comes in two heights. Choose the lower <span class="t-fontWeight--normal">4.75” foundation</span> for a smaller child or if your bed frame requires a lower foundation so it doesn’t stick out above the side rails.</p>
        <p>Choose the taller <span class="t-fontWeight--normal">8.74” foundation</span> for a taller child or if your bed frame side rails require a taller foundation. Don’t have a bed frame? The optional Universal Metal Frame elevates your foundation and mattress 7.5” off the ground.</p>
        `
    },
    [PRODUCT_CODES.SAATVA_CLASSIC]: {
        diagramImage: 'smMattressFoundationDiagram',
        subtext: 'NOTE: The 14.5" mattress is NOT compatible with adjustable bases',
        modalText: `
        <p>All of our mattresses and foundations deliver the same feel and durability, regardless of height. In the Saatva Classic, the difference between the 11.5" and 14.5" comes from two less turns in the base unit coils.</p>
        <p><span class="t-fontWeight--normal">Note that the combination of the 14.5" mattress and the 8.75" foundation can be too high for some people</span>. Measure your current bed height and use this illustration to determine which setup is right for you.</p>
        `
    }
}

export default FoundationModalConfig
