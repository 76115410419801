/* eslint-disable @next/next/no-img-element */
import React from 'react'
import classNames from 'classnames'
import styles from './DualSidedYouthMattressModal.module.scss'

const DualSidedYouthMattressModal = () => {
    const optionClasses = classNames(styles.option, styles.selectable, styles.nonInteractable)
    const comfortSelectionClasses = classNames(styles.comfortSelection, styles.nonInteractable)
    return (
        <section>
            <p className="u-marginBottom--2xl">
                Saatva Youth has a unique dual-sided design that grows with your
                child's needs. Simply flip it over from the Years 3-7 side to the
                Years 8-12 side when your child is ready.
            </p>
            <span className={styles.flipLabel}>Years 3 - 7</span>
            <img
                alt="Mattress sides"
                src="/static/assets/product/youth/youth-flip-diagram.svg"
                className={styles.flipImage}
            />
            <span className={`${styles.flipLabel} u-marginBottom--2xl`}>Years 8 - 12</span>
            <div className={optionClasses}>
                <label for="Years 3 - 7" className={styles.visuallyHidden}>Years 3 - 7</label>
                <input
                    type="button"
                    className={comfortSelectionClasses}
                    aria-describedby="comfort-Years3-7"
                    tabindex="0"
                    id="Years 3 - 7"
                />
                <h2 className={`${styles.label} u-hidden--sm-up`}>Years 3 - 7</h2>
                <span className={styles.level}>3-7</span>
                <div
                    className={styles.description}
                    aria-label="Firmer in the center third of the mattress to maintain proper spinal alignment for younger children"
                    id="comfort-Years3-7"
                >
                    <h2 className={`${styles.label} u-hidden--sm-down`}>Years 3 - 7</h2>
                    Firmer in the center third of the mattress to maintain proper
                    spinal alignment for younger children
                </div>
            </div>
            <div className={optionClasses}>
                <label for="Years 8 - 12" className={styles.visuallyHidden}>Years 8 - 12</label>
                <input
                    type="button"
                    className={comfortSelectionClasses}
                    aria-describedby="comfort-Years8-12"
                    tabindex="0"
                    id="Years 8 - 12"
                />
                <h2 className={`${styles.label} u-hidden--sm-up`}>Years 8 - 12</h2>
                <span className={styles.level}>8-12</span>
                <div
                    className={styles.description}
                    aria-label="Features our patented Lumbar Zone® Active Spinal Wire to maintain proper spinal alignment for older children up to age 12"
                    id="comfort-Years8-12"
                >
                    <h2 className={`${styles.label} u-hidden--sm-down`}>Years 8 - 12</h2>
                    Features our patented Lumbar Zone® Active Spinal Wire to
                    maintain proper spinal alignment for older children up to age 12
                </div>
            </div>
        </section>
    )
}

export default DualSidedYouthMattressModal
