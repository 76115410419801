import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'

const StrikethroughPrice = ({
    className,
    discountClassName,
    discountAmount,
    price,
    subtotal,
    reverseOrder,
    fullPriceDataSelector = '',
    useFree = false
}) => {
    const normalizedPrice = subtotal ? parseInt(subtotal) : parseInt(price)
    const normalizedDiscount = Math.abs(parseInt(discountAmount))

    const formattedOriginalPrice = formatCurrency(normalizedPrice)
    const discountedPrice = subtotal ? price : normalizedPrice - normalizedDiscount
    const formatedDiscountPrice = useFree && discountedPrice === 0 ? 'Free' : formatCurrency(discountedPrice)

    const classes = classNames('strikethroughPrice', {
        'strikethroughPrice--reverseOrder': reverseOrder
    }, className)

    const discountClasses = classNames('strikethroughPrice__discounted', {
        'strikethroughPrice__freeLabel': useFree && discountedPrice === 0
    }, discountClassName)

    // TODO: decide if we want to show the normal "bad" price or show nothing
    const hasValidValues = !Number.isNaN(normalizedPrice) && (subtotal ? !Number.isNaN(subtotal) : !Number.isNaN(normalizedDiscount))

    return (hasValidValues
        ? (
            <div className={classes}>
                <span className="clipped">Current price:</span>
                <span className={discountClasses} data-selector="sale-price">{formatedDiscountPrice}</span>
                <span className="clipped">Original Price:</span>
                <span className="strikethroughPrice__original" data-selector={fullPriceDataSelector}>{formattedOriginalPrice}</span>
            </div>
        )
        : <div className={classes}>{formattedOriginalPrice}</div>
    )
}

StrikethroughPrice.propTypes = {
    className: PropTypes.string,
    discountClassName: PropTypes.string,
    price: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    subtotal: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    discountAmount: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
    ]).isRequired,
    reverseOrder: PropTypes.bool,
    fullPriceDataSelector: PropTypes.string,
    useFree: PropTypes.bool
}

export default StrikethroughPrice
