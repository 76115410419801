import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Icon } from '@saatva-bits/pattern-library.components.icon'
import { BREAKPOINTS, OFFSETS, scrollToElement } from '@saatva-bits/pattern-library.utils.position'

import OutOfStockMessage from '@/components/OutOfStockMessage'

const DropdownPanel = ({
    className,
    wrapperClassName,
    panelClassName,
    displayText,
    iconAlt = 'expand',
    iconDescription = 'expand',
    iconName = 'expand',
    iconOverride,
    disabled = false,
    toggleCallback,
    ToggleChild,
    children,
    adjustableBasePlusErrorVisible,
    isMattressPage,
    isOpen = false,
    isVariantProductInStock = true,
    productCode,
    scrollOnOpen = true,
    ...otherProps
}) => {
    const [isPanelOpen, setIsPanelOpen] = useState(isOpen)
    const containerRef = useRef(null)
    const closeBtnRef = useRef(null)

    useEffect(() => {
        if (isPanelOpen) {
            const dropdownPanel = containerRef.current
            const closeBtn = closeBtnRef.current

            //add any focusable HTML element you want to include to this string
            const focusableElements = dropdownPanel.querySelectorAll(
                `div, button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])`
            )

            const lastElement = focusableElements[focusableElements.length - 1]

            const handleTabKeyPress = (event) => {
                if (event.key === 'Tab') {
                    if (event.shiftKey && document.activeElement === closeBtn) {
                        event.preventDefault()
                        closeBtn.focus()
                    } else if (
                        !event.shiftKey &&
                        document.activeElement === lastElement
                    ) {
                        event.preventDefault()
                        closeBtn.focus()
                    }
                }
            }

            const handleEscapeKeyPress = (event) => {
                if (event.key === 'Escape') {
                    setIsPanelOpen((prevState) => !prevState)
                    toggleCallback && toggleCallback(event, !isPanelOpen)
                }
            }

            dropdownPanel.addEventListener('keydown', handleTabKeyPress)
            dropdownPanel.addEventListener('keydown', handleEscapeKeyPress)

            return () => {
                dropdownPanel.removeEventListener('keydown', handleTabKeyPress)
                dropdownPanel.removeEventListener('keydown', handleEscapeKeyPress)
            }
        }
    }, [isPanelOpen, setIsPanelOpen, toggleCallback])

    const toggle = (event) => {
        if (disabled) return

        event.persist()

        if (scrollOnOpen && !isPanelOpen && window.innerWidth < BREAKPOINTS.md) {
            scrollToElement('js-dropdownPanel', OFFSETS.both)
        }

        const isKeyPressTarget = event.target === event.currentTarget
        const isValidKeyPress = [' ', 'Enter'].indexOf(event.key) !== -1

        if ((isKeyPressTarget && isValidKeyPress) || event.type === 'click') {
            // both of these need to be the inverted value of the previous state to avoid display errors
            setIsPanelOpen((prevState) => !prevState)
            toggleCallback && toggleCallback(event, !isPanelOpen)
        }
    }

    const classes = classNames({
        'dropdownPanel': true,
        'adjustableBasePlus-error': adjustableBasePlusErrorVisible,
        'is-disabled': disabled,
        'is-open': isPanelOpen
    }, className)

    const wrapperClasses = classNames({
        'dropdownPanel__wrapper': true,
        'is-open': isPanelOpen
    }, wrapperClassName)

    const toggleClasses = classNames({
        'dropdownPanel__toggle': true,
        'is-open': isPanelOpen,
        'u-bgColor--contrast1': isMattressPage,
        'dropdownPanel__toggle--stateActive': isPanelOpen && isMattressPage
    })

    const panelClasses = classNames({
        'dropdownPanel__panel': true,
        'is-open': isPanelOpen
    }, panelClassName)

    const toggleTextClasses = classNames('dropdownPanel__text')

    const iconProps = {
        name: iconName,
        className: isPanelOpen ? 'dropdownPanel__icon is-open' : 'dropdownPanel__icon',
        description: isPanelOpen ? 'Close' : iconDescription,
        alt: isPanelOpen ? 'Close' : iconAlt,
        titleId: iconName
    }

    const outOfStockMessage = () => {
        if (!isVariantProductInStock) {
            return productCode
                ? (
                    <OutOfStockMessage
                        productCode={productCode}
                        enableBackInStockAlerts={false}
                    />
                )
                : null // we don't pass a product code for FoundationBaseAddon products, because they don't need to show an OOS message
        }
    }

    return (
        <div ref={containerRef} className={wrapperClasses} id="js-dropdownPanel" data-selector='dropdown-panel'>
            <div className={classes} {...otherProps}>
                <button
                    className={toggleClasses}
                    onClick={toggle}
                    onKeyDown={toggle}
                    data-selector='dropdownPanelButton'
                >
                    <span className={toggleTextClasses} dangerouslySetInnerHTML={{ __html: displayText }}></span>
                    {iconOverride || <button ref={closeBtnRef} aria-label={`${isPanelOpen ? 'Close' : 'Open'} dropdown`}><Icon {...iconProps} /> </button>}
                </button>
                <div id="panel" role="dialog" aria-modal="true" className={panelClasses}>
                    {children && children}
                    {ToggleChild && isVariantProductInStock && (
                        <ToggleChild onClick={toggle} />
                    )}
                    {outOfStockMessage()}
                </div>
            </div>
        </div>
    )
}

DropdownPanel.propTypes = {
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    panelClassName: PropTypes.string,

    iconAlt: PropTypes.string,
    iconDescription: PropTypes.string,
    iconName: PropTypes.string,
    iconOverride: PropTypes.element,
    ariaLabel: PropTypes.string,

    disabled: PropTypes.bool,
    isOpen: PropTypes.bool,
    toggleCallback: PropTypes.func,

    ToggleChild: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    isVariantProductInStock: PropTypes.bool,
    productCode: PropTypes.string,
    scrollOnOpen: PropTypes.bool
}

export default DropdownPanel
