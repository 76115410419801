import { useState, useEffect } from 'react'
import { useProductData } from '@saatva-bits/pattern-library.modules.selection'
import getSelectorProps from '@/temp-configs/product-selector-props'

// get the selector props that appear above the product selectors based on the product code
const useSelectorProps = (productCode) => {
    const { category, subcategory } = useProductData(productCode)
    const [selectorProps, setSelectorProps] = useState(null)

    useEffect(() => {
        const getData = async () => {
            const data = await getSelectorProps(productCode, subcategory, category)
            setSelectorProps(data)
        }

        getData()
    }, [productCode])

    return selectorProps
}

export default useSelectorProps
