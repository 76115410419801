import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ProductSwatch as ProductSwatchBit } from '@saatva-bits/pattern-library.modules.selection'

const ProductSwatch = ({
    className,
    index,
    optionsList,
    sku,
    productCode,
    inputIdModifier = '',
    initialSelection,
    onChange = () => {}, 
    swatchClasses,
    size = 'medium'
}) => {
    const classes = classNames('productSwatch', {
        'has-quantity': index === 0
    }, className)

    const options = optionsList.map(option => {
        const newOption = option.childProps ? { ...option, ...option.childProps } : option
        delete newOption.childProps
        return {
            ...newOption
        }
    })

    return (
        <ProductSwatchBit
            className={classes}
            swatchClasses={swatchClasses}
            size={size}
            optionsList={options}
            inputIdModifier={inputIdModifier}
            initialSelection={initialSelection}
            productCode={productCode || sku}
            onChange={onChange}
            key={`product-option-${productCode || sku}-${index}${inputIdModifier}`}
        />
    )
}

ProductSwatch.propTypes = {
    className: PropTypes.string,
    index: PropTypes.number.isRequired,
    inputIdModifier: PropTypes.string,
    optionsList: PropTypes.array.isRequired,
    product: PropTypes.object,
    onChange: PropTypes.func,
    initialSelection: PropTypes.string,
    sku: PropTypes.string,
    productCode: PropTypes.string,
    swatchClasses: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium'])
}

export default ProductSwatch
