import React from 'react'
import { useGetDiscounts } from '@saatva-bits/pattern-library.modules.promotions'
import { getSwatchImages, transformSwatchOptionsForCarousel } from '@/components/CrossSells/utilities'
import { DetailProductTile } from '@saatva-bits/pattern-library.components.detail-product-tile'

const DetailProductTileSlide = ({ product, tileClasses, useSwatches, hideReviewStars = false, ctaText = null }) => {
    const { getDiscounts } = useGetDiscounts()

    const commonImageProps = {
        widths: { mobile: 348 },
        lazyLoad: true,
        /** Override the lazyload offset added by the detail product tile from bit  */
        lazyLoadOffset: null,
        isUniversal: true,
        imgixDomain: process.env.NEXT_PUBLIC_IMGIX_URL
    }

    const lowestPrice = product.priceRange?.lowestPrice || product.lowestPrice
    const highestPrice = product.priceRange?.highestPrice || product.highestPrice

    const baseProduct = {
        sku: product.sku,
        parentSku: product.parentSku || product.sku,
        category: product.category,
        quantity: 1,
        isPrimaryProduct: true
    }

    const { finalPrice: lowestPriceDiscount } = getDiscounts([{ ...baseProduct, price: lowestPrice }])
    const { finalPrice: highestPriceDiscount } = getDiscounts([{ ...baseProduct, price: highestPrice }])

    const priceRange = {
        fullPriceMin: lowestPrice,
        fullPriceMax: highestPrice,
        finalPriceMin: lowestPriceDiscount,
        finalPriceMax: highestPriceDiscount
    }

    const productOptions = product?.product?.options || product?.options
    const swatches = useSwatches && productOptions && transformSwatchOptionsForCarousel(productOptions || [])

    return (
        <DetailProductTile
            containerClassName={tileClasses}
            imageHref={product.url}
            swatches={swatches}
            priceRange={priceRange}
            subtitle={{
                tag: 'h3',
                text: product.subtitle
            }}
            title={{
                href: product.url,
                tag: 'h2',
                text: product.name
            }}
            getImages={getSwatchImages(product.productCode, product.name, product.category.toLowerCase(), { ...commonImageProps })}
            reviews={ product.reviews && product.reviews.reviewCount && !hideReviewStars
                ? {
                    rating: product.reviews.averageScore,
                    reviewCount: product.reviews.reviewCount
                } : null
            }
            ctaButton={ ctaText
                ? { text: ctaText, href: product.url }
                : null
            }
        />
    )
}

export default DetailProductTileSlide
