import React, { useRef, useEffect } from 'react'
import styles from './ImageGalleryModal.module.scss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { ImageGallery } from '@saatva-bits/pattern-library.components.image-gallery'
import Modal from '@/components/Modal/Modal'

const ImageGalleryModal = ({
    visibility,
    setVisibility,
    heroImages,
    thumbnailImages,
    activeIndex
}) => {
    const startingImageIndex = useRef(activeIndex)
    useEffect(() => {
        startingImageIndex.current = activeIndex
    }, [activeIndex])
    const toggleModal = () => {
        setVisibility(false)
        document.body.classList.remove('u-overflow-hidden')
    }

    if (!visibility) {
        return null
    }

    return (
        <Modal
            isModalVisible={visibility}
            toggleModal={toggleModal}
            className={classNames({ [styles.galleryModalIsVisible]: visibility }, [styles.galleryModal])}
            contentWrapperClassName={classNames({ [styles.galleryModalContentIsVisible]: visibility }, [styles.galleryModalContent])}
            closeButtonClass={classNames([styles.galleryModalCloseButton])}
            overlayClass={classNames([styles.galleryModalOverlay])}
            modalLabel="Image Gallery Modal"
            data-selector="product-carousel-zoom-gallery"
        >
            <ImageGallery
                imagesArray={heroImages}
                thumbnailImagesArray={thumbnailImages}
                transformWrapperClasses={classNames([styles.galleryModalTransform])}
                transformComponentClasses={classNames([styles.galleryModalTransform], [styles.galleryModalTransformWrapper])}
                startingImageIndex={startingImageIndex.current}
                useUncontrolledLeftMostShownIndex={false}
            />
        </Modal>
    )
}

ImageGalleryModal.propTypes = {
    visibility: PropTypes.bool,
    setVisibility: PropTypes.func,
    heroImages: PropTypes.array,
    thumbnailImages: PropTypes.array,
    activeIndex: PropTypes.number
}

export default ImageGalleryModal
