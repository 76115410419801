export const CATEGORIES = {
    MATTRESSES: 'mattresses',
    FURNITURE: 'furniture',
    BEDDING: 'bedding',
    BUNDLES: 'bundles',
}

export const SUBCATEGORIES = {
    BEDFRAMES: 'bed-frames',
    BASES: 'bases',
    BENCHES: 'benches',
    CHAIRS: 'chairs',
    RUGS: 'rugs',
    CANDLES: 'candles',
    FOUNDATIONS: 'foundations',
    NIGHTSTANDS: 'nightstands'
}
