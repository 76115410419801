
import React from 'react'
import PropTypes from 'prop-types'

const Context = React.createContext()
const Consumer = Context.Consumer

const Provider = ({ children, crossSellSkus }) => {
    const providedItems = {
        crossSellSkus: crossSellSkus || {}
    }

    return <Context.Provider value={providedItems}>{children}</Context.Provider>
}

// add display name for dev tool readability
Context.displayName = 'Recommendation'
Provider.displayName = 'RecommendationContext'

Provider.propTypes = {
    children: PropTypes.any,
    crossSellSkus: PropTypes.object
}

const Recommendations = {
    Context,
    Provider,
    Consumer
}

export default Recommendations
