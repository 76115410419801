import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import DetailProductTileSlider from '@/components/CrossSells/partials/DetailProductTileSlider/DetailProductTileSlider'

import { Recommendations, Global } from '@/contexts'

const CrossSells = ({
    crossSellData = 'othersAlsoBought',
    title = 'Others also bought',
    isCompact = false,
    classes
}) => {
    const { urlProductCode: productCode } = useContext(Global.Context)
    const { crossSellSkus } = useContext(Recommendations.Context)

    let filteredCrossSellData
    const crossSellList = crossSellSkus[crossSellData]
    if (Array.isArray(crossSellList) && crossSellList.length) {
        filteredCrossSellData = isCompact 
            ? crossSellList.filter((crossSellProduct) => crossSellProduct.sku !== productCode).slice(0,2) 
            : crossSellList.filter((crossSellProduct) => crossSellProduct.sku !== productCode)
    } else {
        return null
    }
    const sectionClasses = classNames('productTileSlider', classes)
    const titleClasses = classNames('u-textCenter t-color', {
        't-heading3': isCompact
    })

    return (
        <DetailProductTileSlider
            dataSelector={isCompact ? `${productCode}-buystack-pdp-recommended-products` : `${productCode}-pdp-recommended-products`}
            products={filteredCrossSellData}
            title={title}
            titleClassName={titleClasses}
            sectionClassName={sectionClasses}
            leftButtonClassName='u-paddingNone'
            rightButtonClassName='u-paddingNone'
            dotsClassName='productTileSlider__navigation'
            pageDotsAndNumbers
            cartType
            hasYotpoReviewStars
            useSwatches
            isCompact={isCompact}
            enableHoverImg={false}
        />
    )
}

CrossSells.propTypes = {
    crossSellData: PropTypes.string,
    title: PropTypes.string,
    isCompact: PropTypes.bool,
    classes: PropTypes.string
}

export default React.memo(CrossSells)
