import React, { useState, useMemo, useRef, useEffect } from 'react'
import styles from './PDPDesktopCarousel.module.scss'
import classNames from 'classnames'
import { scrollToElement } from '@saatva-bits/pattern-library.utils.position'
import { createAssetDrivenImage, createImgixVideo, createImgixVideoThumbnail } from '@/utils/pdpCarousel'
import carouselVideoConfig from '@/temp-configs/carousel-video-config'
import useDeviceType from '@/hooks/useDeviceType'
import DeliveryAndSetup from '@/components/DeliveryAndSetup'
import GoodMorningAmericaMessage from '@/components/GoodMorningAmericaMessage'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'

const PDPDesktopCarousel = ({
    productImageData,
    imgixDomain,
    assetData,
    productCode,
    heroImageUlClasses,
    heroImageRightContent,
    onHeroImageClick,
    wrapperClassName = '',
    setIndex
}) => {
    const [thumbnailIndex, setThumbnailIndex] = useState(0)
    const [zoomButtonIndex, setZoomButtonIndex] = useState(undefined)
    const videoConfig = carouselVideoConfig[productCode]
    const hasVideo = Boolean(videoConfig)
    const videoAssetIndex = videoConfig?.carouselIndex
    const { isDesktop } = useDeviceType('desktop')
    const { isV2: isCarouselRedesignEnabledV2 } = useExperiment('EXP.CAROUSEL_REDESIGN_2.EX-121') // see EX-134 also

    const carouselAssetRefs = useRef([]) // Refs for hero images to observe

    const updateCarouselAssetsRefs = () => {
        const totalAssets = hasVideo ? productImageData.heroImages.length + 1 : productImageData.heroImages.length
        carouselAssetRefs.current = Array(totalAssets).fill(null).map((_, i) => carouselAssetRefs.current[i] || React.createRef())
    }

    useEffect(() => {
        updateCarouselAssetsRefs()
    }, [productImageData.heroImages, hasVideo])

    // Dynamically update the stickyness of the buystack if the carousel is taller than the buystack.
    // TODO: If test is a winner, we'll need to add the ability to make the carousel sticky as well
    // This doesn't apply to the Classic test because its buystack is shorter than the number of assets
    // displayed, but there are other products with fewer assets and/or longer buystacks where that could apply.
    const updateBuystackStickiness = () => {
        const buystack = document.getElementById('buystack')
        const carouselWrapper = document.querySelector('[class*="pdpCarousel"]')
        if (!buystack || !carouselWrapper) return

        const buystackHeight = buystack.offsetHeight
        const carouselHeight = carouselWrapper.offsetHeight
        const isBuystackSticky = carouselHeight > buystackHeight
        const isCarouselSticky = carouselHeight < buystackHeight

        if (isBuystackSticky) {
            buystack.style.position = 'sticky'
            buystack.style.top = `${(window.innerHeight - buystackHeight - 40)}px` // 40px allows for whitespace under banner
        }
        if (isCarouselSticky) {
            carouselWrapper.style.position = 'sticky'
            carouselWrapper.style.top = `${(window.innerHeight - carouselHeight - 40)}px` // 40px allows for whitespace under banner
        }
    }

    const thumbnails = useMemo(() => {
        if (!productImageData.thumbnailImages) return null
        const isLongCarousel = productImageData.thumbnailImages.length > 3
        const thumbnailsToShow = isLongCarousel ? productImageData.thumbnailImages.slice(0, 3) : productImageData.thumbnailImages
        const lastElementIndex = thumbnailsToShow.length - 1
        const thumbnailElements = thumbnailsToShow.map((thumbnail, index) => {
            // If there's a video, use the carousel index up until the point we would insert the video.
            // This is based on a key in the config. For all elements after, add 1 to the index to account for the video.
            const finalCarouselIndex = hasVideo && index >= videoAssetIndex ? index + 1 : index
            const thumbnailClassName = classNames(styles.pdpCarouselThumbnail, {
                [styles.pdpCarouselThumbnailActive]: thumbnailIndex === finalCarouselIndex
            })
            const isLastElement = index === lastElementIndex
            const hasMoreButton = isLastElement && isLongCarousel
            const overlayText = isLongCarousel ? `+ ${(productImageData.thumbnailImages.length - thumbnailsToShow.length) + 1} more` : ''
            const handleLastItemOnClick = () => {
                setZoomButtonIndex(undefined)
                setIndex(3) // hardcoded because it should be the first image not displayed (the 4th image in the array)
                setTimeout(() => onHeroImageClick(), 250) // adding a delay to allow for setIndex to finish first
            }
            const handleOnClick = () => {
                scrollToElement(`pdpCarousel__carouselAsset-${finalCarouselIndex}`)
                setThumbnailIndex(finalCarouselIndex)
            }
            return (
                <>
                    <button
                        key={thumbnail.filename}
                        className={thumbnailClassName}
                        onClick={hasMoreButton ? handleLastItemOnClick : handleOnClick}
                        data-selector={hasMoreButton ? 'product-carousel-slider-more-button' : null}
                    >
                        {createAssetDrivenImage(thumbnail, assetData.altTag, imgixDomain, false)}
                        {hasMoreButton ? <div className={styles.pdpCarouselThumbnailTextOverlay}>{overlayText}</div> : null}
                    </button>
                </>
            )
        })

        const videoThumbnailOnClick = () => {
            scrollToElement(`pdpCarousel__carouselAsset-${videoAssetIndex}`)
            setThumbnailIndex(videoAssetIndex)
        }

        const videoThumbnailClasses = classNames(styles.pdpCarouselThumbnail, {
            [styles.pdpCarouselThumbnailActive]: thumbnailIndex === videoAssetIndex
        })

        // slice the array to insert the thumbnail at the correct index
        const allThumbnailAssets = hasVideo ? [
            ...thumbnailElements.slice(0, videoAssetIndex), // images up until the video
            createImgixVideoThumbnail(videoConfig, videoThumbnailOnClick, videoThumbnailClasses), // the video thumbnail
            ...thumbnailElements.slice(videoAssetIndex) // remaining images
        ] : thumbnailElements

        return (
            <div className={styles.pdpCarouselThumbnailWrapper}>
                <div className={styles.pdpCarouselThumbnails} data-selector='product-carousel-slider-thumbnails'>
                    {allThumbnailAssets}
                </div>
            </div>
        )
    }, [productImageData.thumbnailImages, thumbnailIndex])

    const carouselAssets = useMemo(() => {
        if (!productImageData.heroImages) return null
        const carouselElementsToShow = productImageData.heroImages.slice(0, 2)
        const carouselElements = carouselElementsToShow.map((heroImage, index) => {
            // If there's a video, use the carousel index up until the point we would insert the video.
            // This is based on a key in the config. For all elements after, add 1 to the index to account for the video.
            const finalCarouselIndex = hasVideo && index >= videoAssetIndex ? index + 1 : index

            const handleHeroHover = () => {
                setIndex(finalCarouselIndex) // zoom gallery index, needs to be set before onClick is called
                setZoomButtonIndex(finalCarouselIndex)
            }

            const handleHeroBlur = () => {
                setZoomButtonIndex(undefined)
            }

            const shouldLazyLoadImage = finalCarouselIndex !== 0 // lazy load images after the first

            return (
                <button
                    key={heroImage.filename}
                    className={styles.pdpCarouselAssetsPicture}
                    id={`pdpCarousel__carouselAsset-${finalCarouselIndex}`}
                    ref={carouselAssetRefs.current[finalCarouselIndex]}
                    onMouseOver={handleHeroHover}
                    onMouseLeave={handleHeroBlur}
                    onFocus={handleHeroHover}
                    onBlur={handleHeroBlur}
                    onClick={() => {
                        setZoomButtonIndex(undefined) // remove the zoom button content
                        onHeroImageClick()
                    }}
                >
                    <div className={heroImageUlClasses}>
                        {/* TODO: If test wins, determine if conditional rendering of content on hover is still best implementation */}
                        {zoomButtonIndex === finalCarouselIndex && heroImageRightContent}
                        {createAssetDrivenImage(heroImage, assetData.altTag, imgixDomain, true, shouldLazyLoadImage)}
                    </div>
                </button>
            )
        })

        // Add nested ref to match other assets
        const setVideoRef = (element) => {
            carouselAssetRefs.current[videoAssetIndex] = { current: element }
        }

        const videoElement = hasVideo && (
            <div
                ref={setVideoRef}
                className={styles.pdpCarouselVideoWrapper}
                id={`pdpCarousel__carouselAsset-${videoAssetIndex}`}
                data-selector='product-carousel-slider-video'
            >
                {createImgixVideo(videoConfig, videoAssetIndex)}
            </div>
        )

        const deliveryAndGMA = isDesktop && <div className={styles.deliveryAndGMAContainer}>
            <DeliveryAndSetup productCode={productCode} className={styles.serviceValuePropsReducedPadding} />
            <GoodMorningAmericaMessage productCode={productCode} />
        </div>

        // slice the array to insert the video at the correct index
        const allAssets = hasVideo ? [
            ...carouselElements.slice(0, videoAssetIndex), // images up until the video
            isCarouselRedesignEnabledV2 && deliveryAndGMA, // add this message only in one specific test variation, EX-134
            videoElement,
            ...carouselElements.slice(videoAssetIndex) // remaining images
        ] : carouselElements

        return (
            <div className={styles.pdpCarouselCarouselAssetWrapper}>
                <div className={styles.pdpCarouselAssets}>{allAssets}</div>
            </div>
        )
    }, [productImageData.heroImages, zoomButtonIndex])

    // Observe the carousel assets to determine which thumbnail is active
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const elementID = entry.target.id
                    const index = Number(elementID.split('-')[1]) // get the number from the last part of the ID
                    setThumbnailIndex(index)
                }
            })
        }, {
            root: null,
            threshold: 1 // only count as intersecting if the entire element is visible in the viewport
        })

        carouselAssetRefs.current.forEach((ref) => {
            if (ref.current) observer.observe(ref.current) // Observe the selected DOM element
        })

        return () => {
            observer.disconnect() // Clean up the observer on unmount
        }
    }, [productImageData.heroImages, hasVideo])

    useEffect(() => {
        updateBuystackStickiness()
        window.addEventListener('resize', updateBuystackStickiness) // needed because of changing dimensions on resize
        return () => {
            window.removeEventListener('resize', updateBuystackStickiness)
        }
    }, [])

    return (
        <div className={`${styles.pdpCarousel} ${wrapperClassName}`}>
            {thumbnails}
            {carouselAssets}
        </div>
    )
}

export default PDPDesktopCarousel
