import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useProductState } from '@saatva-bits/pattern-library.modules.selection'

import { epigraphSKUOverrides, COLORS_WITH_MODELS, FABRICS_WITH_MODELS, SIZES_WITH_MODELS } from '@/utils/epigraph-sku-overrides'
import get from 'lodash/get'

const EpigraphDisclaimer = ({
    productCode,
    className
}) => {
    const { sku, size, fabric, color } = useProductState(productCode, [])
    // TODO: Replace with SKU override from catalog service in the future
    const overrideSku = get(epigraphSKUOverrides, `${productCode}.skus[${sku}]`, null)

    if (!overrideSku) {
        return null
    }

    const {
        fallbackSize = null,
        fallbackFabric = null,
        fallbackColor = null,
        hasSingleFabricForModels = false,
        hasSingleColorForModels = false,
    } = epigraphSKUOverrides[productCode]

    // There are currently four possible display reasons:
    // 1. The product has one or more fabrics without a 3D model (e.g. Pewter Linen, Terra Suede Leather, and Limited Editions)
    // 2. The product has only one color/fabric with a 3D model (e.g. Natural Linen or Espresso Antica Leather for products with hasSingleFabricForModels = true, Brown Oak for hasSingleColorForModels = true)
    // 3. The product has a size that does not have a 3D model (e.g. Twin or Full on the original three fabrics for Santorini)
    // 4. A combination of #1 and #3 (e.g. Twin and Full size of Pewter Linen on the Rhodes)
    // This lets us detect reasons #1, #2, and #3, and display both fallbacks for reason #4.
    const sizeText = SIZES_WITH_MODELS.includes(size) ? '' : `${fallbackSize} ` // extra space at the end is on purpose
    const fabricText = (hasSingleFabricForModels || !FABRICS_WITH_MODELS.includes(fabric)) ? fallbackFabric : ''
    const colorText = (hasSingleColorForModels && !COLORS_WITH_MODELS.includes(color) ? fallbackColor : '')

    const disclaimerClasses = classNames('arDisclaimer', {
        [`${className}`]: className
    })

    return (
        <div className={disclaimerClasses}>{`3D model shown in ${sizeText}${fabricText || colorText}`}</div>
    )
}

EpigraphDisclaimer.propTypes = {
    productCode: PropTypes.string.isRequired,
    className: PropTypes.string
}

export default EpigraphDisclaimer
