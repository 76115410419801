import React from 'react'
import PropTypes from 'prop-types'
import { useFeatureFlag } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.hooks'
import GoodMorningAmericaMessageConfig from './GoodMorningAmericaMessageConfig'
import styles from './_GoodMorningAmericaMessage.module.scss'

function GoodMorningAmericaMessage({ productCode }) {
    const { isOn: showGMAComponent } = useFeatureFlag('ENABLE_GMA_FEATURE_MESSAGE.PD-15172')
    const message = GoodMorningAmericaMessageConfig[productCode]
    if (!message || !showGMAComponent) return null
    return (
        <div className={styles.container}>
            {message()}
        </div>
    )
}

GoodMorningAmericaMessage.propTypes = {
    productCode: PropTypes.string.isRequired
}

export default GoodMorningAmericaMessage
