import { findClosestVariant } from '@saatva-bits/pattern-library.modules.selection'
import { generateAssetURLs } from '@saatva-bits/pattern-library.utils.product'
import carouselConfig from '@/temp-configs/carousel-config'

const formatName = (name, addBundleInName = false) => {
    const pattern = /^(.*?)((\s[in])|[,])+/g
    const returnPattern = pattern.exec(name)

    if (!returnPattern) return name
    return addBundleInName ? `${returnPattern[1]}, 2-pack` : returnPattern[1]
}

const findSelectedVariant = (product, productOverrides) => {
    // The selectedVariant tells us the default state of the product and lets us do the best guess
    // at choosing the correct image to preload. This should produce a correct default in >95% of cases,
    // but storing selected state in localStorage means the following products might not have the correct hero preloaded:
    // zenhaven, solaire, dog-bed, foundation, plush-towels, waffle-towels
    // It's limited to products where the first image varies based on a non-configurable attribute such as mattressType
    // or where the first image varies based on size that could be different on the client side.
    let selectedVariant

    if (productOverrides?.skuQuery) {
        // defaults to empty object because missing variant would return undefined, breaking generateAssetURLs later
        selectedVariant = product.variants.find(variant => variant.sku === productOverrides.skuQuery) || {}
    } else {
        let defaultAttributes = {}
        const { variants, configurableAttributes, options } = product
        options.forEach(option => { defaultAttributes[option.code] = option.default }) // reformats into a list of key/value pairs
        selectedVariant = findClosestVariant(variants, configurableAttributes, defaultAttributes)
    }
    return selectedVariant
}

// Build OpenGraph social sharing tag
const buildOpenGraphTag = (data) => {
    try {
        const {
            productCode,
            category,
            title,
            description,
            products,
            selectionContextProductOverrides
        } = data

        const assetData = {
            altTag: carouselConfig[productCode]?.altTag,
            attributeSets: carouselConfig[productCode]?.attributeSets,
            imageDescriptors: [carouselConfig[productCode]?.imageDescriptors[0]]
        }

        const overrides = selectionContextProductOverrides[productCode]
        const selectedVariant = findSelectedVariant(products[0], overrides)
        const assetURLs = generateAssetURLs(productCode, assetData, selectedVariant, '16-9')
        const { folder, filename } = assetURLs[0] // We don't have specific social sharing assets, so we use the first carousel image
        if (!folder || !filename) return null

        const image = `https://${process.env.NEXT_PUBLIC_IMGIX_URL}/${folder}/${filename}?w=1200&fit=crop&auto=format`
        const url = `https://www.${process.env.NEXT_PUBLIC_STOREFRONT_CORESITE_NODE_DOMAIN}/${category}/${productCode}`

        return {
            title, // og:title currently the meta title, could also be product name
            description, // og:description
            type: 'product', // og:type
            locale: 'us', // og:locale 
            image, // og:image
            url, // og:url
        }
    } catch (error) {
        const { productCode, category } = data
        console.error(`[pages.product.buildOpenGraphTag] Could not create OpenGraph tags for product ${productCode} in category ${category}`)
        return null
    }
}


export {
    buildOpenGraphTag,
    findSelectedVariant,
    formatName,
}
