import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import styles from './DeliveryAndSetup.module.scss'

import { useProductData } from '@saatva-bits/pattern-library.modules.selection'
import { Button } from '@saatva-bits/pattern-library.components.button'
import { ValueProp } from '@saatva-bits/pattern-library.components.value-props'
import { PRODUCT_CODES } from '@/constants'
import getPropData from './valuePropsConfig'
import aboveValuePropsText from './aboveValuePropsTextConfig'
import useDeviceType from '@/hooks/useDeviceType'

const {
    CRIB_MATTRESS,
    DOG_BED,
    FEATHERBED_MATTRESS_TOPPER,
    CLASSIC_STARTER_BUNDLE,
} = PRODUCT_CODES

const ServiceValueProp = ({ heading, icon, linkUrl, smallHeaderSize }) => {
    const valuePropHeaderClasses = classNames(styles.heading, {
        [styles.smallHeading]: smallHeaderSize
    })
    return (
        <div>
            <Button href={linkUrl} target='_blank' className={styles.button}>
                <ValueProp
                    className={styles.valueProp}
                    icon={<div className={styles.iconWrapper}>{icon}</div>}
                    heading={heading}
                    headingTag='h3'
                    headingClassName={valuePropHeaderClasses} />
            </Button>
        </div>
    )
}

const DeliveryAndSetup = ({
    productCode,
    className,
    id = 'delivery'
}) => {
    const { category, subcategory, deliveryType, returnPeriod, warranty, warrantyDetails } = useProductData(productCode)
    const [valuePropData, setValuePropData] = useState([])
    // If any data is missing, don't render the component rather than throwing an error
    let requiredData = ['category', 'subcategory', 'deliveryType', 'returnPeriod', 'warranty']
    const { isDesktop } = useDeviceType('desktop')

    // There is no warranty for scented candles, so this handles adding warranty details for all other products
    if (productCode !== PRODUCT_CODES.SCENTED_CANDLES) {
        requiredData.push('warrantyDetails')
    }

    const isMissingRequiredData = !requiredData.every(data => data) // function returns true if all data is present, so we invert it

    const textAboveValueProps = aboveValuePropsText[productCode]

    useEffect(() => {
        if (!isMissingRequiredData) {
            let valueProps = []
            switch (category.toLowerCase()) {
                case 'mattresses':
                    valueProps.push('home', 'setup', 'warranty', 'delivery')

                    switch (productCode) {
                        case DOG_BED:
                            valueProps[1] = 'delivery'
                            valueProps[3] = 'usa'
                            break
                        case CRIB_MATTRESS:
                            valueProps[1] = 'delivery'
                            valueProps.pop()
                            break
                    }

                    break
                case ('bedding'):
                    valueProps.push('home', 'delivery', 'warranty')

                    if (subcategory === 'Mattress Toppers' && productCode !== FEATHERBED_MATTRESS_TOPPER) {
                        valueProps[3] = 'usa'
                    }

                    break
                case ('furniture'):
                    valueProps.push('home', 'setup', 'warranty')

                    switch (subcategory) {
                        case 'Foundations':
                            valueProps[3] = 'delivery'
                            break
                        case 'Adjustable Bases':
                            valueProps[0] = 'setup'
                            valueProps[1] = 'customerService'
                            valueProps[2] = 'warranty'
                            break
                        case 'Bed Frames':
                        case 'Benches':
                        case 'Chairs':
                        case 'Nightstands':
                            valueProps[0] = 'home'
                            valueProps[1] = 'delivery'
                            valueProps[2] = 'setup'
                            valueProps[3] = 'warranty'
                            break
                        case 'Candles':
                            valueProps[0] = 'delivery'
                            valueProps[1] = 'customerService'
                            valueProps[2] = 'usa'
                    }
                    break
                case 'bundles':
                    if (productCode === CLASSIC_STARTER_BUNDLE) {
                        valueProps.push('home', 'setup', 'warranty', 'delivery')
                    } else { // original bedding bundles
                        valueProps.push('home', 'delivery', 'warranty')
                    }
                    break
            }

            const propData = getPropData(valueProps, productCode, category, subcategory, returnPeriod, warranty, warrantyDetails, deliveryType)
            setValuePropData(propData)
        }
    }, [])

    const deliveryAndSetupClasses = classNames(styles.deliveryAndSetup, {
        [styles.threeItems]: valuePropData.length === 3,
        [styles.mobileAndTabletSpacing]: !isDesktop,
        [styles.forceWidth]: textAboveValueProps,
    })
    const sectionClasses = classNames(styles.section, className, {
        [styles.sectionWithTextAbove]: textAboveValueProps,
    })

    const renderValueProp = ({
        name,
        icon,
        linkUrl,
        header,
        smallHeaderSize
    }, id) => {
        return (
            <ServiceValueProp
                key={`${id}-${productCode}-serviceValueProp-${name}`}
                heading={header}
                icon={icon}
                linkUrl={linkUrl}
                smallHeaderSize={smallHeaderSize} />
        )
    }

    if (isMissingRequiredData) return null // skip rendering if any data is missing

    return (
        <section id={id} className={sectionClasses}>
            {textAboveValueProps && <p className={styles.aboveValuePropsText}>{textAboveValueProps}</p>}
            <div className={deliveryAndSetupClasses}>
                {
                    valuePropData.map(valueProp => {
                        return renderValueProp(valueProp, id)
                    })
                }
            </div>
        </section>
    )
}

export default DeliveryAndSetup
