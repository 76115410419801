import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

const DataContext = React.createContext()

const ContentThemeProvider = ({ children, contextData }) => {

    const {
        themeIds,
        luxuryEligible,
        innerspringEligible
    } = contextData

    const data = {
        themeIds,
        luxuryEligible,
        innerspringEligible
    }

    const staticData = useMemo(() => data, [contextData])

    return (
        <DataContext.Provider value={staticData}>
            {children}
        </DataContext.Provider>
    )
}

function useContentThemeData () {
    const data = useContext(DataContext)
    if (typeof data === 'undefined') {
        throw new Error('useContentThemeData must be used within ContentThemeProvider')
    }

    return data
}

// add display name for dev tool readability
ContentThemeProvider.displayName = 'ContentThemeContext'
DataContext.displayName = 'ContentThemeData'

ContentThemeProvider.propTypes = {
    children: PropTypes.any,
    contextData: PropTypes.shape({
        themeIds: PropTypes.array,
        luxuryEligible: PropTypes.bool,
        innerspringEligible: PropTypes.bool
    })

}

export {
    ContentThemeProvider,
    useContentThemeData
}
