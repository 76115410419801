import React, { useContext, useState, useEffect } from 'react'
import classNames from 'classnames'
import { Accordion } from '@saatva-bits/pattern-library.components.accordion'
import Reviews from '@/contexts/reviews'
import YotpoPDPReviews from '../YotpoPDPReviews/YotpoPDPReviews'
import YotpoReviewStars from '../YotpoReviewStars/YotpoReviewStars'
import styles from './ReviewsAccordion.module.scss'

const ReviewsAccordion = ({ productCode, isBundleProduct }) => {
    const [isOpen, setIsOpen] = useState(false)
    const ReviewsContext = useContext(Reviews.Context)
    const { accordionContent, accordionName } = ReviewsContext
    const isOpenOnRender = accordionName === 'reviews-accordion'

    const contentClassName = classNames('u-paddingHorizontal--none',
        { [`${styles.reviewsAccordion__content}`]: accordionContent || isOpen }
    )
    const accordionClassnames = `${styles.reviewsAccordion__accordion} u-paddingHorizontal--md`

    const accordionTitle =
        <div className={styles.reviewsAccordion__title}>
            <span className='t-heading2 u-marginRight u-marginBottom--none'>Customer Reviews</span>
            <YotpoReviewStars productCode={productCode} />
        </div>

    const closeReviewsAccordion = () => {
        setIsOpen(!isOpen)
        if (ReviewsContext) {
            ReviewsContext.dispatch({
                type: 'RESET_ACCORDION_VALUES'
            })
        }
    }

    // Duplicated from YotpoPDPReviews, adds data selectors to Reviews / Q&A tabs
    const addWidgetTabSelectors = () => {
        const widgetTabs = document.getElementsByClassName('ugc-storefront-widgets-tabs-container__tab')
        if (widgetTabs.length >= 2) {
            widgetTabs[0].setAttribute('data-selector', `${widgetTabs[0].textContent.toLowerCase()}`)
            widgetTabs[1].setAttribute('data-selector', `${widgetTabs[1].textContent.toLowerCase()}`)
        }
    }

    useEffect(() => {
        setIsOpen(isOpenOnRender)
    }, [isOpenOnRender])

    useEffect(() => {
        let timeoutId // not initialized since clearTimeout will work even with undefined
        if (typeof window !== 'undefined' && window.yotpo) {
            try {
                if (!document.querySelector('.yotpo.yotpo-main-widget')?.innerText) {
                    window.yotpo?.initWidgets?.()
                }
            } catch (error) {
                console.error(`[YotpoReviewStars]`, error)
            }
        }
        // Fixes missing data selectors in Yotpo v3
        if (typeof window !== 'undefined') {
            timeoutId = setTimeout(addWidgetTabSelectors, 2000)
        }

        // Clean up the timeout
        return () => {
            clearTimeout(timeoutId)
        }
    }, [isOpen])

    return (
        <div className="reviewsAccordion">
            {isOpen
                ? <YotpoPDPReviews productCode={productCode} key={`yotpo-reviews-accordion-${isOpen}`} isBundleProduct={isBundleProduct}/>
                : <Accordion
                    key={`reviews-ac-${accordionContent}`}
                    id='reviews-accordion'
                    isOpenOnRender={isOpenOnRender}
                    className={accordionClassnames}
                    title={accordionTitle}
                    titleClassName={'t-heading2 u-marginBottom--none'}
                    headingLevel={2}
                    contentClassName={contentClassName}
                    iconContainerClassName={styles.reviewsAccordion__iconContainer}
                    titleContainerClassName={'u-paddingHorizontal--none'}
                    titleLabel={'reviews-accordion-title'}
                    contentLabel={'reviews-accordion-content'}
                    onAccordionItemClick={() => closeReviewsAccordion()}
                >
                    <YotpoPDPReviews productCode={productCode} key={`yotpo-reviews-accordion-${isOpen}`} isBundleProduct={isBundleProduct}/>
                </Accordion>
            }
        </div>
    )
}

export default ReviewsAccordion
