interface SvgImageProps {
    imageID: string,
    folderPath?: string,
    className?: string,
    dataSelector?: string,
    alt: string,
}

const SvgImage = ({
    imageID,
    folderPath = '/static/assets/svg-images/',
    className,
    dataSelector = `svgImage--${imageID}`,
    alt,
}: SvgImageProps): JSX.Element => {
    const domain = `https://${process.env.NEXT_PUBLIC_STOREFRONT_CORESITE_NODE_DOMAIN}`
    const src = `${domain}${folderPath}${imageID}.svg`
    return (
        <img
            data-selector={dataSelector}
            className={className}
            src={src}
            alt={alt}
        />
    )
}

export default SvgImage
