const config = {
    'luxury': {
        title: `Who it's best for:`,
        content: [
            'Sleepers who like the responsive feel of an innerspring and the cushioned feel of a pillow top',
            'Those who sleep hot',
            'Those who want great lower back support in any sleep position'
        ]
    },
    'innerspring': {
        title: 'Who it\'s best for:',
        content: [
            'Sleepers who want back pain relief',
            'Combination sleepers (those who tend to switch sleep positions)',
            'Those who want cooler sleep'
        ]
    }
}

export default config
