import { useEffect } from 'react'
import { useProductState } from '@saatva-bits/pattern-library.modules.selection'
import { pushDataLayerEvent } from './useEECAddToCart'

const useEECProductDetails = (productCode) => {
    const productState = useProductState(productCode)
    useEffect(() => {
        if (typeof window !== 'undefined' && productCode) {
            window.dataLayer = window.dataLayer || []

            const { genericName, sku, category, subcategory, price, quantity, bundledVariants } = productState
            const productDetails = {
                item_id: bundledVariants?.length > 0 ? productCode : sku,
                item_name: genericName,
                item_category: category,
                item_category2: subcategory,
                price: price,
                location_id: 'pdp',
                item_variant: getProductVariant(productState),
                quantity: quantity || 1,
            }

            const viewItemObject = {
                event: 'view_item',
                ecommerce: {
                    items: [productDetails]
                }
            }
            const pushEvent = () => {
                pushDataLayerEvent(viewItemObject)
            }

            if (window.dataLayer.some(event => event.event === 'gtm.js')) {
                pushEvent()
            } else {
                window.addEventListener('gtm.js', pushEvent)
            }

            return () => {
                window.removeEventListener('gtm.js', pushEvent)
            }
        }
    }, [productCode])
}

export default useEECProductDetails

export const getProductVariant = (product) => {
    if (!product.attributes) return null
    return product.attributes.map((attribute) => {
        return attribute.value
    }).join('/') || null
}

