const importModule = async (moduleName) => {
    try {
        const selectorProps = await import(`./${moduleName}`)
        return selectorProps.default
    } catch {
        return null
    }
}

const getSelectorProps = async (productCode, subcategory = '', category = '') => {
    const categoryLC = category.toLocaleLowerCase()
    const subCategoryLC = subcategory.toLowerCase()

    // Retrieve selector props configured for the product, category, and subcategory.
    // In some subcategories, such as rugs, all products share the same selector props
    const categoryConfig = await importModule(categoryLC)
    const subcategoryConfig = await importModule(subCategoryLC)
    const productConfig = await importModule(productCode)

    // Merge configs in order of increasing specificity.
    // This allows for overrides to include (or exclude)
    // anything configured the higher, more generic level:
    //      category > subcategory > productCode
    return { ...categoryConfig, ...subcategoryConfig, ...productConfig }
}

export default getSelectorProps
