import React, { useEffect } from 'react'

import propTypes from 'prop-types'
import { useProductData, useProductPricing, useProductState } from '@saatva-bits/pattern-library.modules.selection'
import useAddon from '@/hooks/addon'
import { FormCheckbox } from '@saatva-bits/pattern-library.components.form-checkbox'
import PictureImgix from '@/components/PictureImgix/PictureImgix'
import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'
import { checkCheckboxAddonConditions } from '@/components/Addon/Addons/checkAddonConditions'
import { PRODUCT_CODES } from '@/constants'
import styles from './CheckboxAddon.module.scss'

const CheckboxAddon = ({
    primaryProductCode,
    addonProductCode,
    addonLabel
}) => {
    const { name } = useProductData(addonProductCode)
    const { finalPrice } = useProductPricing(addonProductCode)
    const { inStock } = useProductState(addonProductCode)
    const { isAddonSelected, selectAddon, unselectAddon } = useAddon(addonProductCode, addonLabel)

    // This addon should always display on the foundation PDP, but should
    // only display on other PDPs when the foundation is already selected.
    // Uses HD foundation for Saatva HD mattress, and standard foundation everywhere else.
    const foundationProductCode = primaryProductCode === PRODUCT_CODES.SAATVA_HD ? PRODUCT_CODES.FOUNDATION_HD : PRODUCT_CODES.FOUNDATION
    const { isProductSelectedViaAddons: isFoundationSelected } = useAddon(foundationProductCode)

    const shouldShowAddon = checkCheckboxAddonConditions(primaryProductCode, isFoundationSelected)

    const createLabel = (label, price) => {
        const formatedPrice = formatCurrency(price)
        return <span className={styles.checkboxAddon__labelText}>{label}, <span className={styles.checkboxAddon__labelPrice}>{formatedPrice}</span></span>
    }

    const frameLabel = createLabel(`Add ${name}`, `${finalPrice}`)

    const handleAddonSelect = (isChecked) => {
        if (isChecked) {
            !isAddonSelected && selectAddon()
        } else {
            isAddonSelected && unselectAddon()
        }
    }

    // Remove the addon so it doesn't get added to card when the foundation is removed.
    useEffect(() => {
        if (!shouldShowAddon && isAddonSelected) {
            unselectAddon()
        }
    }, [shouldShowAddon]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!inStock || !shouldShowAddon) return null

    return (
        <div className={`${styles.checkboxAddon} u-marginTop u-marginBottom--md`}>
            <FormCheckbox
                className={styles.checkboxAddon__checkbox}
                id="metal_frame_checkbox"
                value={name}
                label={frameLabel}
                checked={isAddonSelected}
                onChange={(e) => handleAddonSelect(e.target.checked)}
                data-selector="checkboxAddon_metalFrame"
                containerAttributes={{ 'data-selector': `checkboxAddon-${addonProductCode}-container` }}
            />
            <div className={styles.checkboxAddon__imageWrapper}>
                <PictureImgix
                    imageClassName={styles.checkboxAddon__image}
                    name={`${addonProductCode}.png`}
                    folder={`generic/${addonProductCode}`}
                    alt={`${name} addon image`}
                    isUniversal
                />
            </div>
        </div>
    )
}

CheckboxAddon.propTypes = {
    primaryProductCode: propTypes.string.required,
    addonProductCode: propTypes.string.required
}

export default CheckboxAddon
