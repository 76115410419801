import * as productCodes from '@/constants/product-codes'

module.exports = {
    [productCodes.ORGANIC_MATTRESS_PAD]: {
        description: {
            default: 'Our luxurious diamond-patterned mattress pad in 100% organic cotton is the ultimate in breathable protection and comfort.'
        },
        ardadConfig: {
            attributeSets: [],
            imageDescriptors: [
                'silo-folded'
            ]
        }
    },
    [productCodes.NATURAL_RUG_PAD]: {
        productIntro: {
            header: 'Recommended'
        },
        description: {
            default: 'Made with natural rubber-coated polyester. Eliminates slipping, bunching and prevents damage to floor & rug.'
        },
        ardadConfig: {
            attributeSets: [],
            imageDescriptors: [
                'side'
            ]
        }
    },
    [productCodes.THE_SAATVA_PILLOW]: {
        isBundle: true,
        description: {
            default: 'With a loft height of 6-7", this pillow is specially suited for side and combination sleepers.'
        },
        ardadConfig: {
            attributeSets: ['pillowHeightOptions'],
            imageDescriptors: ['silo-set']
        }
    },
    [productCodes.MEMORY_FOAM_PILLOW]: {
        isBundle: true,
        description: {
            default: 'Give your head &amp; neck the same chiropractor-approved support with our plush latex pillows.',
            [productCodes.LOOM_AND_LEAF]: 'Enjoy the cool comfort and contouring support of our graphite-infused pillow.'
        },
        ardadConfig: {
            attributeSets: [],
            imageDescriptors: ['silo-set']
        }
    },
    [productCodes.DOWN_ALTERNATIVE_PILLOW]: {
        isBundle: true,
        description: {
            default: 'Enjoy the airy comfort of this hypoallergenic pillow covered in 100% organic cotton.'
        },
        ardadConfig: {
            attributeSets: [],
            imageDescriptors: ['silo-set']
        }
    },
    [productCodes.ORGANIC_COTTON_SHEETS]: {
        productIntro: {
            header: 'Add a second sheet set',
            description: `Did you know that you should change your sheets at least every 2 weeks? <br className='u-hidden--xl-down' />Get a back-up set of our flagship Organic Cotton Sheets while you're here!`
        },
        description: {
            default: 'Experience our flagship organic cotton sheets in a 300-thread count, silky-soft, sateen weave.'
        },
        ardadConfig: {
            generic: {
                imageFolder: 'generic/sheet-set/white',
                imageName: 'sheet-set-white-1-1.jpg',
                imageAlt: 'A white sheet set'
            }
        }
    },
    [productCodes.CRIB_SHEETS]: {
        productIntro: {
            header: 'ADD A PAIR OF CRIB SHEETS'
        },
        checkboxLabel: 'Organic Cotton Crib Sheet Pair',
        description: {
            default: 'This breathable fitted sheet adds an extra layer of protection and comfort to your baby’s crib mattress.'
        },
        ardadConfig: {
            attributeSets: [],
            imageDescriptors: ['messy']
        }
    },
    [productCodes.WATERPROOF_MATTRESS_PROTECTOR]: {
        description: {
            default: 'Our organic cotton mattress protector adds a breathable layer of protection from spills and excess moisture.'
        },
        ardadConfig: {
            attributeSets: [],
            imageDescriptors: ['lifestyle-corner']
        }
    }
}
