import { FormatLogArgs } from './types'

/**
 * @example
 * // Example of logging an info message
 * const logMessage = formatLog({ 
 *     level: LogLevel.INFO,
 *     message: 'This is an info message',
 *     location: 'api.test-logger.handler',
 *     error: new Error('An error occurred'),
 *     extraInfo: { userID: '123', action: 'fetchData' }
 * });
 */
export function formatStringLog({ level, message, location, error, extraInfo }: FormatLogArgs): string {
    const errorString = error ? `\nError: ${error.message}\nStack: ${error.stack}` : ''
    const extraInfoString = extraInfo && Object.keys(extraInfo).length > 0
        ? `\n${JSON.stringify(extraInfo, null, 2)}`
        : ''
    return `${level.toUpperCase()}: [${location}] ${message}${errorString}${extraInfoString}`
}

/**
 * Formats log data into a structured object for better organization and searchability in Humio.
 * 
 * @example
 * // Example of logging an info message
 * const logMessage = formatLog({
 *     level: LogLevel.INFO,
 *     message: 'This is an info message',
 *     location: 'api.test-logger.handler',
 *     error: new Error('An error occurred'),
 *     extraInfo: { userID: '123', action: 'fetchData' }
 * });
 */
export function formatStructuredLog({ level, message, location, error, extraInfo }: FormatLogArgs): string {
    const structuredLog = {
        level: level.toUpperCase(),
        message,
        location,
        error: error ? { message: error.message, stack: error.stack } : undefined,
        extraInfo: extraInfo && Object.keys(extraInfo).length > 0 ? extraInfo : undefined,
    }

    // prevent line breaks from separating your log data into multiple events by logging a single JSON string
    // Humio should be be able to extract each field from the JSON string
    return JSON.stringify(structuredLog)
}
