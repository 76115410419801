import { formatStructuredLog } from './formatters'
import sentryOutput from './sentryOutput'
import { LogLevel, LogArgs } from './types'
import ENVIRONMENTS from '@/constants/environments'

/**
 * General information about events that should appear in every environment.
 * @example
 * logger.info({
 *     message: 'User login successful',
 *     location: 'api.user.login',
 *     error: null,
 *     extraInfo: { userID: '123', action: 'login' }
 * });
 */
export function info(logArgs: LogArgs): void {
    const formattedLog = formatStructuredLog({ ...logArgs, level: LogLevel.Info })
    console.log(formattedLog)
}

/**
 * Information that our team should be made aware of, but requires no immediate action.
 * @example
 * logger.warn({
 *     message: 'User attempted to access a restricted area',
 *     location: 'api.user.access',
 *     error: null,
 *     extraInfo: { userID: '123', attemptedPath: '/admin' }
 * });
 */
export function warn(logArgs: LogArgs): void {
    const formattedLog = formatStructuredLog({ ...logArgs, level: LogLevel.Warn})
    console.warn(formattedLog)
}

/**
 * Issues which require immediate action.
 * @example
 * const error = new Error('Database connection failed');
 * logger.error({
 *     message: 'Failed to retrieve user data',
 *     location: 'api.user.getData',
 *     error: error,
 *     extraInfo: { userID: '123' }
 *     enableClientAlerts: true
 *     tags: { tagName: 'tagValue'}
 * });
 */
export function error(logArgs: LogArgs): void {
    const formattedLog = formatStructuredLog({ ...logArgs, level: LogLevel.Error })
    console.error(formattedLog)
    sentryOutput(logArgs)
}

/**
 * Verbose debugging information that should appear in development environment, but not in production.
 * @example
 * logger.debug({
 *     message: 'Debugging user login process',
 *     location: 'api.user.login.debug',
 *     error: null,
 *     extraInfo: { step: 'checking credentials', userID: '123' }
 * });
 */
export function debug(logArgs: LogArgs): void {
    if (process.env.NEXT_PUBLIC_APP_ENV === ENVIRONMENTS.PRODUCTION) return // Only log in non-production environments

    const formattedLog = formatStructuredLog({ ...logArgs, level: LogLevel.Debug })
    console.debug(formattedLog)
}
