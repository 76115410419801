// import React from 'react'
import PropTypes from 'prop-types'
import { useProductData, useProductState, useVariantsData } from '@saatva-bits/pattern-library.modules.selection'
import styles from './WhatsIncludedBB.module.scss'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import { ADJUSTABLE_FRAME, FOUNDATION, ORGANIC_MATTRESS_PAD, SAATVA_CLASSIC } from '@/constants/product-codes'
import { capitalize } from 'lodash'

const WhatsIncludedBB = ({
    productCode,
    title
}) => {
    const { name } = useProductData(productCode)
    const { bundledVariants } = useProductState(productCode)
    const variantsData = useVariantsData(bundledVariants || [])

    if(!variantsData.length) {
        return null
    }

    const titleSction = title || `What's included in your ${name.replace('Saatva', '')}:`

    // This may need to change if other bundle are included in the future
    const itemName = (variant) => {
        switch(variant.productCode){
            case SAATVA_CLASSIC: {
                return `${variant.genericName} (${variant.size}, ${variant.comfortLevel}, ${variant.mattressHeight}")`
            }
            case FOUNDATION: {
                return `${capitalize(variant.productCode)} (${variant.foundationHeight}")`
            }
            case ADJUSTABLE_FRAME: {
                return variant.name
            }
            case ORGANIC_MATTRESS_PAD: {
                return variant.genericName
            }
            default: {
                return ''
            }
        }
    }

    const bundleItems = variantsData.map((variant) => {
        return(
            <li className={`u-marginBottom--sm ${styles.bundleItems}`} key={`whats-included-${variant.productCode}`}>
                <span className={styles.bundleItemsName}>{itemName(variant)}</span>
                <span><SvgSprite spriteID='icon-checkmark-no-circle' className={styles.icon}/></span>
            </li>
        )
    })

    return(
        <section id={`${styles.bundleItems}`}>
            <h3 className={`u-textLeft ${styles.title}`}>{titleSction}</h3>
            <ul className={styles.bundleList}>    
                {bundleItems}
            </ul>
        </section>
    )
}

WhatsIncludedBB.propTypes = {
    productCode: PropTypes.string,
    title: PropTypes.string
}

export default WhatsIncludedBB
