import * as Cache from '@saatva-bits/pattern-library.modules.cache'
import * as Sentry from '@sentry/node'
import isEmpty from 'lodash/isEmpty'

import { getExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly'
import baseRouter from '@/utils/baseRouter'
import { getFaqsAndSpecsByProduct } from '@/utils/prismic'
import getRecommendationData from '@/services/recommendation/getRecommendationData'
import { buildServerSideContext, buildServerSideProps } from '@/utils/buildServerSide'
import { getPrismicRef } from '@/utils/prismicPreview'
import { createAssetsToPreload } from '@/utils/pdpCarousel'
import { capitalizeFirstLetter } from '@/utils/string'
import { buildOpenGraphTag } from '@/utils/products'
import { getYotpoReviewsAndScoresBottomline, getYotpoQuestionsBottomline } from '@/services/yotpo'

import { getCatalogProducts } from '@/services/catalog'
import * as Content from '@/services/content'
import { getTotalProductReviewCount } from '@/services/yotpo'

import { EXTRA_LONG_REVALIDATE, LONG_REVALIDATE } from '@/temp-configs/cache-constants'
import { CATEGORIES } from '@/temp-configs/category-constants'
import PDPView from '@/views/PDP'
import { CLASSIC_STARTER_BUNDLE, SAATVA_CLASSIC } from '@/constants/product-codes'
import { PRODUCTS_WITHOUT_PDPS } from '@/constants/product-collections'

const router = baseRouter().get(async (req, res) => {
    const category = req.params?.category?.toLowerCase()
    const productCode = req.params?.productCode?.toLowerCase()
    const prismicRef = getPrismicRef(req)

    if (!category || !Object.values(CATEGORIES).includes(category) || !productCode || PRODUCTS_WITHOUT_PDPS.includes(productCode)) {
        return {
            notFound: true
        }
    }

    let primaryProductData
    let pageData = {
        applyDoNotIndex: false,
        category,
        productCode,
        pageType: 'product',
        faqsAndSpecs: {},
        reviewsData: {},
        products: [], // We may be switching this more specific in the future
        selectionContextProductOverrides: {},
        assetsToPreload: [],
        breadcrumbs: [
            {
                label: 'Home',
                href: '/',
            },
            {
                label: capitalizeFirstLetter(category),
                href: `/${category}`
            },
            {
                name: ''
            }
        ]
    }

    try {
        /*
         * Fetch:
         * Primary product data from content service (Prismic data)
         * Product and related product data from catalog service
         * Primary product FAQs/Specs from content service
         */
        const [
            prismicProductDataByTags,
            faqsAndSpecsByCategory,
            catalogProducts
        ] = await Promise.all([
            Content.getProductDataByTags(productCode, prismicRef),
            Content.getFaqsAndSpecsByCategory(category, prismicRef),
            getCatalogProducts([productCode.toLowerCase()], ['PRE_CART'], true)
        ])

        if(!catalogProducts || !catalogProducts.length) { // if product is not retrieved from catalog service
            return {
                notFound: true
            }
        }

        // First product returned from catalog service is the primary product
        primaryProductData = catalogProducts[0]

        // Disable search indexing of products that are not set to active in Plytix
        if (!primaryProductData.active) {
            pageData.applyDoNotIndex = true
        }

        // organize primary product data from content service (Prismic data)
        if (!isEmpty(prismicProductDataByTags)) {
            const { metaDescription, metaTitle, title, longDescription } = prismicProductDataByTags[0] ?? {}
    
            // add metadata to pageData
            pageData.title = metaTitle || title
            pageData.description = metaDescription || ''

            // enrich primary product data with content from Prismic
            primaryProductData.content = primaryProductData.content || {}
            primaryProductData.content.longDescription = longDescription
        }

        pageData.products = catalogProducts
        pageData.faqsAndSpecs = getFaqsAndSpecsByProduct(productCode, faqsAndSpecsByCategory)

        if (pageData.breadcrumbs.length >= 1 && pageData.products[0]) {
            pageData.breadcrumbs[2].name = pageData.products[0].name
        }

        // Fetch review and question count for components that conditionally display review content
        // Change productCode === CLASSIC_STARTER_BUNDLE ? SAATVA_CLASSIC : productCode 
        // once  EARLY_REVIEW_THRESHOLD.PD-14332 is done. Leave just productCode
        const reviewsProductCode = productCode === CLASSIC_STARTER_BUNDLE ? SAATVA_CLASSIC : productCode
        const reviewsCacheKey = Cache.buildKey('reviews', 'pdp', reviewsProductCode) // reviews:product:example-product
        const getReviewsData = async () => {
            const [bottomlineData, questionsData] = await Promise.all([
                getYotpoReviewsAndScoresBottomline(reviewsProductCode), // returns totalReviews and averageScore
                getYotpoQuestionsBottomline(reviewsProductCode), // returns totalQuestions
            ])
            return {
                ...bottomlineData,
                ...questionsData,
            }
        }

        // Get reviews data for regular products and bundles that we show reviews on
        // TODO: Remove specific reference to CLASSIC_STARTER_BUNDLE as more bundles show reviews
        if (productCode === CLASSIC_STARTER_BUNDLE || !primaryProductData.isBundleProduct) {
            const productReviewsData = await Cache.getRevalidatingCache(getReviewsData, reviewsCacheKey, LONG_REVALIDATE)
            if (productReviewsData) {
                pageData.reviewsData = {
                    ...productReviewsData,
                }
            }
        }

        // Reads all PDP review counts from the cache and combines the total
        const totalProductReviewCountCacheKey = Cache.buildKey('reviews', 'total') // reviews:total, won't conflict with existing PDP key
        const totalProductReviewCount = await Cache.getRevalidatingCache(getTotalProductReviewCount, totalProductReviewCountCacheKey, EXTRA_LONG_REVALIDATE)

        if (totalProductReviewCount) {
            pageData.reviewsData.totalProductReviewCount = totalProductReviewCount
        }

        // Set the sku and quantity overrides when applicable
        const { sku: skuQueryValue, qty: qtyQueryValue } = req.query
        let productOverrides = {} // reused for asset preloading
        if (skuQueryValue || qtyQueryValue) {
            productOverrides = {
                skuQuery: skuQueryValue || null,
                qtyQuery: parseInt(qtyQueryValue) || null,
            }
            pageData.selectionContextProductOverrides = {
                [productCode]: productOverrides
            }
        }

        // BEGIN PDP Carousel Redesign test EX-134. New carousels use different aspect ratios, so this handles the change for preloading.
        const { isV1: isCarouselRedesignEnabledV1, isV2: isCarouselRedesignEnabledV2 } = getExperiment(res.locals.experiments, 'EXP.CAROUSEL_REDESIGN_2.EX-121', res.locals.flagOverrides)
        const isCarouselRedesignEnabled = isCarouselRedesignEnabledV1 || isCarouselRedesignEnabledV2
        pageData.assetsToPreload = createAssetsToPreload(productCode, primaryProductData, productOverrides, isCarouselRedesignEnabled)
        // END PDP Carousel Redesign test EX-134
    } catch (error) {
        console.error(`[pages.product]: Error fetching product and filter data for product ${productCode} with message: ${error.message}`)
        Sentry.captureException(error)
    }

    pageData.crossSellSkus = await getRecommendationData({
        parentProductCode: productCode,
        parentProductCategory: primaryProductData.category.toLowerCase(),
        prismicPreviewRef: req.cookies['prismicPreviewRef'],
        experiments: res.locals.experiments,
        featureFlags: res.locals.featureFlags,
        flagOverrides: res.locals.flagOverrides,
        isBundleProduct: primaryProductData.isBundleProduct
    })

    pageData.contentThemeContextData = {
        themeIds: res.locals.themeCodes || [],
        luxuryEligible: res.locals.luxuryEligible || null,
        innerspringEligible: res.locals.innerspringEligible || null
    }

    // Add social sharing tags
    pageData.openGraphValues = buildOpenGraphTag(pageData)

    return {
        props: buildServerSideProps(pageData, res.locals)
    }
})

export function getServerSideProps(context) {
    const { req, res } = buildServerSideContext(context)
    return router.run(req, res)
}

export default function ProductPage(props) {
    return(
        <PDPView {...props} />
    )
}
