import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ProductTitle from '@/components/ProductTitle'
import ProductPrice from '@/components/ProductPrice'
import styles from './Buystack.module.scss'
import { ProductSelectors, QuantitySelector, useProductData, useProductState } from '@saatva-bits/pattern-library.modules.selection'
import Addon from '@/components/Addon'
import YotpoReviewStars from '@/components/YotpoReviewStars'
import ProductLongDescription from '@/components/ProductLongDescription'
import DeliveryEstimate from '@/components/DeliveryEstimate'
import ProductPromoCallout from '@/components/ProductPromoCallout'
import ProductSubtotal from '@/components/ProductSubtotal'
import PromoBanner from '@/components/PromoBanner'
import useDeviceType from '@/hooks/useDeviceType'
import DeliveryAndSetup from '@/components/DeliveryAndSetup/DeliveryAndSetup'
import { AddToCart } from '@/components/AddToCart'
import { useExperiment } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.hooks'
import useSelectorProps from '@/hooks/useSelectorProps'
import { Global } from '@/contexts'
import EpigraphModelViewer from '@/components/EpigraphModelViewer'
import { PRODUCT_COLLECTIONS } from '@/constants'
import { BED_FRAMES } from '@/constants/subcategories'
import { SAATVA_CLASSIC } from '@/constants/product-codes'
import MattressFirmnessModal from '@/components/MattressFirmnessModal'
import ProductWhatsIncluded from '@/components/ProductWhatsIncluded'
import PersonalizationPanel from '@/components/PersonalizationPanel'
import CrossSellLinkToggle from '@/components/CrossSellLinkToggle'
import GoodMorningAmericaMessage from '@/components/GoodMorningAmericaMessage'
import CrossSells from '@/components/CrossSells'
import useEnrichBundleVariants from '@/hooks/useEnrichBundleData'

const Buystack = () => {
    const { urlProductCode: productCode } = useContext(Global.Context)
    const { isDesktop } = useDeviceType('desktop')
    const selectorProps = useSelectorProps(productCode)
    const { isBundleProduct, variants, subcategory } = useProductData(productCode)

    // BEGIN PDP Out of Stock Styling test EX-260
    const { isV1: isOutOfStockStylingEnabled } = useExperiment('EXP.OOS_BUYSTACK.EX-260')
    const isOutOfStockStyling = isOutOfStockStylingEnabled && !isBundleProduct
        
    const primaryProductState = useProductState(productCode)
    const bundledVariants = useEnrichBundleVariants(primaryProductState.bundledVariants)
    const inStock = bundledVariants.length ? bundledVariants.every((variant => variant.inStock)) : primaryProductState.inStock
    const showCompactCrossSell = (subcategory === BED_FRAMES) && !inStock
    // END PDP Out of Stock Styling test EX-260

    useEffect(() => {
        if (variants) {
            const hasOOSVariant = variants.some(variant => variant.inStock === false)
            if (hasOOSVariant) {
                window.dataLayer = window.dataLayer || []
                dataLayer.push({
                    'event': 'oosVariantPdp'
                })
            }
        }
    },[variants])

    const buystackClasses = classNames(styles.buystack, 'section', 'u-paddingVertical--none')

    const showArButton = PRODUCT_COLLECTIONS.BEDFRAMES.includes(productCode) || PRODUCT_COLLECTIONS.PLATFORM_BEDFRAMES.includes(productCode)
    const epigraphArButtonProps = {
        EpigraphModelViewer: showArButton && <EpigraphModelViewer productCode={productCode} isArButtonOnly={true} />
    }

    // BEGIN PDP Carousel Redesign test EX-134
    const { isV1: isCarouselRedesignEnabledV1 } = useExperiment('EXP.CAROUSEL_REDESIGN_2.EX-121')
    const isAlternateLayout = productCode === SAATVA_CLASSIC && isCarouselRedesignEnabledV1
    // END PDP Carousel Redesign test EX-134

    return (
        <section id="buystack" className={buystackClasses}>
            <ProductTitle productCode={productCode} />
            {!isBundleProduct && <YotpoReviewStars
                productCode={productCode}
                enableEarlyReviewMessage={true}
                showBadge
                className={'u-marginTop--2xs'}
            />}
            <ProductPrice productCode={productCode} />
            <ProductPromoCallout productCode={productCode} />
            <PersonalizationPanel productCode={productCode} />
            <CrossSellLinkToggle productCode={productCode} />
            <ProductSelectors
                productCode={productCode}
                customOptionProps={selectorProps}
                dataSelectorModifier='buystack'
                swatchesSize='medium'
                hiddenAttributes={['quantity']}
                epigraphArButtonProps={epigraphArButtonProps}
                isOutOfStockStyling={isOutOfStockStyling}
            />
            <MattressFirmnessModal />
            <ProductWhatsIncluded productCode={productCode} />
            <Addon productCode={productCode} />
            <ProductLongDescription productCode={productCode} />
            <ProductSubtotal productCode={productCode} />
            <div className={`${styles.quantityAddToCartRow} u-marginBottom u-marginTop`}>
                <QuantitySelector
                    productCode={productCode}
                    dataSelector='primaryQty'
                    className='u-marginRight'
                    displayIfOutOfStock={false}
                />
                <AddToCart productCode={productCode} />
            </div>
            {isOutOfStockStylingEnabled && showCompactCrossSell &&
                <CrossSells
                    crossSellData='lookingSimilar'
                    title='Discover other great options'
                    classes='u-padding--none'
                    subcategory={subcategory}
                    inStock={inStock}
                    isCompact 
                />
            }
            <DeliveryEstimate productCode={productCode} />
            <PromoBanner productCode={productCode} />
            {/* EX-134: We always want to show service value props here on mobile */}
            {!isDesktop && <DeliveryAndSetup productCode={productCode} />}
            {/* EX-134 v1 & v2: We want to show the GMA message and service value props in the test */}
            {isAlternateLayout && isDesktop && <>
                <DeliveryAndSetup productCode={productCode} />
                <GoodMorningAmericaMessage productCode={productCode} />
            </>}
            {/* EX-134 END */}
        </section>
    )
}

Buystack.propTypes = {
    productCode: PropTypes.string,
    category: PropTypes.string
}

export default Buystack
