import React from 'react'
import useDeviceType from '@/hooks/useDeviceType'
import BBBReview from '@/components/BBBReview'
import MarketSurveyResults from '@/components/MarketSurveyResults'
import ReviewsAccordion from '@/components/ReviewsAccordion'
import YotpoPDPReviews from '@/components/YotpoPDPReviews'
import { useProductData } from '@saatva-bits/pattern-library.modules.selection'

// TODO bundleProduct is used for bundles/classic-starter-bundle
// We may need to change the bundleProduct prop to avoid any future confusion
// because the bundle just show saaatva-classic reviews
const PDPReviews = ({ productCode }) => {
    const { isMobile, isTablet } = useDeviceType('mobile')
    const { isBundleProduct } = useProductData(productCode)

    return (
        <>
            <MarketSurveyResults>
                <BBBReview />
            </MarketSurveyResults>
            {(isTablet || isMobile)
                ? !isBundleProduct && <ReviewsAccordion productCode={productCode} />
                : !isBundleProduct && <YotpoPDPReviews productCode={productCode} />
            }
        </>
    )
}

export default React.memo(PDPReviews)
