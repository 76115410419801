import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useFeatureFlag } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.hooks'
import { useProductData } from '@saatva-bits/pattern-library.modules.selection'
import { scrollToElement, OFFSETS } from '@saatva-bits/pattern-library.utils.position'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'

import useDeviceType from '@/hooks/useDeviceType'
import Reviews from '@/contexts/reviews'
import styles from './YotpoReviewStars.module.scss'

const YOTPO_WIDGET = 'YOTPO_WIDGET'
const EARLY_REVIEW_MESSAGE = 'EARLY_REVIEW_MESSAGE'

// Formats review counts to a string representing the nearest thousand (rounded down)
const formatCombinedReviews = (combined) => {
    const rounded = Math.floor(combined / 1000)
    return `${rounded}k Saatva Reviews`
}

const YotpoReviewStars = ({
    productCode,
    clickEventName = 'clickStars',
    enableEarlyReviewMessage = false,
    showBadge,
    className,
}) => {
    const ReviewContext = useContext(Reviews.Context)
    // reviewCount is not used for display, only for comparing to early review threshold
    const { dispatch, reviewCount, totalProductReviewCount } = ReviewContext
    // number of reviews required to show early review message when enabled
    const { value: earlyReviewThreshold } = useFeatureFlag('EARLY_REVIEW_THRESHOLD.PD-14332')
    const [visibleUi, setVisibleUi] = useState(null)
    const { name } = useProductData(productCode)
    const { isDesktop } = useDeviceType('desktop')
    const hasReviewsAccordion = !isDesktop  

    // conditionally show early review message or yotpo widget if the early
    // review feature is enabled and the early review can be shown in this instance
    useEffect(() => {
        // if early review message isn't shown here, or the feature flag is disabled
        // do not emit event for analytics because widget is always shown if early review message is disabled
        if (!enableEarlyReviewMessage || !earlyReviewThreshold || !productCode) {
            setVisibleUi(YOTPO_WIDGET)
        } else {
            if (reviewCount < earlyReviewThreshold) {
                setVisibleUi(EARLY_REVIEW_MESSAGE)
                emitAnalyticsEvent('showEarlyReviewMessage')
            } else {
                setVisibleUi(YOTPO_WIDGET)
                emitAnalyticsEvent('showYotpoWidget')
            }
        }
    }, [])

    if (!productCode) {
        return null
    }

    // for analytics tracking
    const emitAnalyticsEvent = (event) => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            'event': event,
            'productName': name
        })
    }

    const clickStarsHandler = (e) => {
        if (e.type === 'click' || e.code === 'Enter') {
            if (hasReviewsAccordion) {
                // opens the ReviewsAccordion component if enabled
                dispatch({
                    type: 'SET_ACCORDION_VALUES',
                    accordionName: 'reviews-accordion',
                    accordionContent: 'reviews-accordion-content',
                    accordionTitle: 'reviews-accordion-title',
                })
            }
            scrollToElement('customer-reviews', OFFSETS.both, 1000, 'easeInOutQuad', 100, false, 150)
            emitAnalyticsEvent(clickEventName)
        }
    }

    const clickEarlyReviewMessageHandler = (e) => {
        if (e.type === 'click' || e.code === 'Enter') {
            scrollToElement('marketSurveyResults', OFFSETS.both, 1000, 'easeInOutQuad', 100, false, 150)
            emitAnalyticsEvent('clickEarlyReviewMessage')
        }
    }

    const earlyReviewMessage = () => {
        return (
            <div className={styles.earlyReviewMessage}>
                <span>
                    <button onClick={clickEarlyReviewMessageHandler} onKeyDown={clickEarlyReviewMessageHandler}>
                        We’re the best reviewed sleep brand since 2010
                    </button>
                </span>
                <SvgSprite
                    spriteID='icon-reviews-verified'
                    className={styles.bestReviewedIcon}
                />
            </div>
        )
    }

    const totalProductReviews = () => {
        return (
            <div className={styles.yotpoProductReviews}>
                <SvgSprite
                    spriteID='icon-reviews-checkmark'
                    className={styles.verifiedReviewsIcon}
                />
                <span className={styles.reviewCountText}>{formatCombinedReviews(totalProductReviewCount)}</span>
            </div>
        )
    }

    const yotpoWidget = () => {
        const widgetClasses = classNames(styles.yotpoWrapper, {
            'u-hidden': visibleUi !== YOTPO_WIDGET
        })

        // the widget doesn't appear to embed properly if it isn't rendered right away
        // render the widget, hide it if it shouldn't appear on the page
        // a focusable element is embedded
        return (
            <div className={widgetClasses}>
                {/* Yotpo requires this to be a div, otherwise we could choose a different element */}
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div
                    className="yotpo bottomLine"
                    data-product-id={productCode}
                    onClick={clickStarsHandler}
                    onKeyDown={clickStarsHandler}
                />
                {showBadge && totalProductReviews()}
            </div>
        )
    }

    // reduce CLS when neither early review message or yotpo widget is visible
    const containerClasses = classNames(className, styles.yotpoReviewStars, {
        [styles.yotpoReviewStars.placeholder]: !visibleUi
    })

    return (
        <div className={containerClasses}>
            {visibleUi === EARLY_REVIEW_MESSAGE && earlyReviewMessage()}
            {/* the yotpo widget is always rendered, but it may be hidden */}
            {yotpoWidget()}
        </div>
    )
}

YotpoReviewStars.propTypes = {
    productCode: PropTypes.string.isRequired,
    clickEventName: PropTypes.string,
    enableEarlyReviewMessage: PropTypes.bool,
    showBadge: PropTypes.bool,
    className: PropTypes.string,
}

export default YotpoReviewStars
