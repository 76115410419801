import React, { useState, useEffect, useMemo, useCallback } from 'react'
import * as ReactIs from 'react-is'
import PropTypes from 'prop-types'
import styles from './BidirectionalTab.module.scss'

function modulo (number, mod) {
    return ((number % mod) + mod) % mod
}

const BidirectionalTab = ({
    onSelectionChange = () => {},
    selectedAria,
    ariaControls,
    onKeyDown,
    children,
    id,
    className,
    focusedAria,
    subscribeRef,
    selectedIconColor,
    dataSelector,
    onChange = () => {},
    label
}) => {
    const refCallBack = useMemo(() => subscribeRef(ariaControls), [subscribeRef, ariaControls])
    const classes = selectedAria === ariaControls ? `${className} u-bgColor--${selectedIconColor} ${styles.bidirectionalTab}` : `${className} ${styles.bidirectionalTab}`

    return (
        <button
            onClick={onSelectionChange ? () => {
                onSelectionChange(ariaControls)
                onChange(label)
            } : undefined}
            ref={refCallBack}
            role="tab"
            tabIndex={focusedAria === ariaControls ? 0 : -1}
            aria-selected={selectedAria === ariaControls}
            aria-controls={ariaControls}
            id={id}
            onKeyDown={focusedAria === ariaControls ? onKeyDown : undefined}
            className={classes}
            data-selector={dataSelector}
        >
            {children}
        </button>
    )
}

BidirectionalTab.propTypes = {
    onSelectionChange: PropTypes.func,
    selectedAria: PropTypes.string,
    ariaControls: PropTypes.string.isRequired,
    onKeyDown: PropTypes.func,
    children: PropTypes.node,
    id: PropTypes.string,
    className: PropTypes.string,
    focusedAria: PropTypes.string,
    subscribeRef: PropTypes.func,
    selectedIconColor: PropTypes.string,
    dataSelector: PropTypes.string,
    onChange: PropTypes.func
}

function isTabComponent (child) {
    if (ReactIs.isFragment(child) || !ReactIs.isElement(child)) return false
    return child.props.ariaControls !== undefined
}

const BidirectionalTabs = ({
    children: childrenProp,
    onChange,
    selectedAria,
    className,
    sliderBar = false,
    sliderBarClassName = '',
    dataSelector
}) => {
    const [focusedAria, setFocusedAria] = useState(selectedAria)
    const [sliderRef, setSliderRef] = useState(null)
    const [tabRefs, setTabRefs] = useState({})

    const addNewTab = useCallback((ariaLabel) => (newTab) => {
        setTabRefs((previous) => {
            if (previous[ariaLabel] !== undefined) return previous
            else return { ...previous, [ariaLabel]: newTab }
        })
    }, [])

    useEffect(() => {
        if (Object.keys(tabRefs).length > 0 && sliderRef !== null && sliderBar) updateSlider(selectedAria)
    }, [tabRefs, selectedAria])

    useEffect(() => {
        setFocusedAria(selectedAria)
    }, [selectedAria])

    const updateSlider = (selectedAria) => {
        sliderRef.style.left = tabRefs[selectedAria].offsetLeft + 'px'
        sliderRef.style.width = tabRefs[selectedAria].offsetWidth + 'px'
    }

    const childrenPropArray = useMemo(() => React.Children.toArray(childrenProp), [childrenProp])
    const onKeyDown = (event) => {
        const code = event.key
        const keycodeMap = {
            ArrowRight: 1,
            ArrowDown: 1,
            ArrowUp: -1,
            ArrowLeft: -1
        }
        if (keycodeMap[code] !== undefined) event.preventDefault()
        const offset = (keycodeMap[code]) || 0
        const keys = Object.keys(tabRefs)
        const currentFocusedAriaIndex = keys.indexOf(focusedAria)
        const newFocusedIndex = modulo(currentFocusedAriaIndex + offset, keys.length)
        const newFocusedAria = keys[newFocusedIndex]
        setFocusedAria(newFocusedAria)
        tabRefs[newFocusedAria].focus()
    }

    const wrappedOnSelectionChange = (ariaLabel) => {
        setFocusedAria(ariaLabel)
        sliderBar && updateSlider(ariaLabel)
        if (onChange) onChange(ariaLabel)
    }

    const children = childrenPropArray.map((child) => {
        if (!ReactIs.isElement(child)) return child
        if (!isTabComponent(child)) return child
        const updatedChild = React.cloneElement(child, {
            onSelectionChange: wrappedOnSelectionChange,
            selectedAria,
            focusedAria,
            onKeyDown,
            subscribeRef: addNewTab
        })
        return updatedChild
    })

    return (
        <div role='tablist' className={className} data-selector={dataSelector}>
            {children}
            { sliderBar && <div className={`${styles.bidirectionalTab__sliderBar} ${sliderBarClassName}`} ref={ref => setSliderRef(ref)} aria-hidden='true'></div> }
        </div>
    )
}

BidirectionalTabs.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    selectedAria: PropTypes.string,
    children: PropTypes.node,
    sliderBar: PropTypes.bool,
    sliderBarClassName: PropTypes.string,
    dataSelector: PropTypes.string
}

export {
    BidirectionalTabs,
    BidirectionalTab
}
