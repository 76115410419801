import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Badge } from '@saatva-bits/pattern-library.components.badge'

const SaleBadge = ({
    className,
    text,
    disclaimer,
    bumper = 'sm',
    inline = false,
    kind = 'promo',
    position,
    dataSelector
}) => {
    const contentText = disclaimer ? `${text}*` : text

    /*
        Standardize "inline" treatment for sale badge:

        "position" and "className" declation will always override "inline" declarations
    */
    const badgePosition = position || (inline ? 'inline' : 'topLeft')
    const badgeClassnames = classNames(className, {
        'u-marginLeft': inline && !className
    })

    return <Badge dataSelector={dataSelector} className={badgeClassnames} position={badgePosition} bumper={bumper} kind={kind} text={contentText} />
}

SaleBadge.propTypes = {
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    disclaimer: PropTypes.bool,
    bumper: PropTypes.string,
    inline: PropTypes.bool,
    kind: PropTypes.string,
    position: PropTypes.string
}

export default SaleBadge
