import PropTypes from 'prop-types'
import classNames from 'classnames'
import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'

const DiscountBadge = ({
    discount,
    hasDiscountRange = false,
    className
}) => {
    const classes = classNames('discountBadge', className)

    const discountPrefixText = hasDiscountRange ? 'Save up to ' : 'Save '

    return (
        <div className={classes}>
            {discountPrefixText}{formatCurrency(discount, 0)}
        </div>
    )
}

DiscountBadge.propTypes = {
    discount: PropTypes.number.isRequired,
    hasDiscountRange: PropTypes.bool,
    className: PropTypes.string
}

export default DiscountBadge
