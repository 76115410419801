import { useFaqContext } from '@saatva-bits/pattern-library.modules.faq'
import classNames from 'classnames'

const useScrollToFaqsAndSpecs = () => {
    const { scrollToAccordionContent } = useFaqContext()

    const scrollToFaqsAndSpecs = (accordionIdentifier) => {
        scrollToAccordionContent(accordionIdentifier)
    }

    const ScrollToFaqsAndSpecsButton = ({ copy, accordionContent = '', className = '', dataSelector = '' }) => {
        const accordionIdentifier = accordionContent.replace('accordion-content-', '').toLowerCase()
        const classes = classNames(className, 't-color--accent t-heading5 t-link')
        return (
            <button
                className={classes}
                data-selector={dataSelector}
                onClick={(e) => {
                    e.preventDefault()
                    scrollToFaqsAndSpecs(accordionIdentifier)
                }}>
                {copy}
            </button>
        )
    }

    return {
        scrollToFaqsAndSpecs, // replaces scrollToAccordionContent
        ScrollToFaqsAndSpecsButton, // replaces redirecToFAQsLink
    }
}

export default useScrollToFaqsAndSpecs
