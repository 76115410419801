const affirmDefined = () => {
    return window && Object.prototype.hasOwnProperty.call(window, 'affirm')
}

const affirmRefresh = () => {
    setTimeout(() => {
        if (affirmDefined()) {
            affirm.ui.ready(() => {
                affirm.ui.refresh()
            })
        }
    }, 100)
}

export {
    affirmRefresh
}
