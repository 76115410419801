import React from 'react'
import PropTypes from 'prop-types'
import styles from '../DeliveryEstimate.module.scss'

const DeliveryEstimateModal = ({ content = [] }) => {
    if (!content.length) return null

    return (
        <ul className={styles.deliveryEstimate__modal__content__list}>
            {
                content.map((item, i) => {
                    return (
                        <li id={`deliveryEstimate-modal-listItem-${i}`} key={i} className={styles.deliveryEstimate__modal__content__list__item} dangerouslySetInnerHTML={{ __html: item }}/>
                    )
                })
            }
        </ul>
    )
}

DeliveryEstimateModal.propTypes = {
    content: PropTypes.array
}

export default DeliveryEstimateModal
