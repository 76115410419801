const { getColorNameForImages } = require('@saatva-bits/pattern-library.utils.product')

export const getSwatchImages = (sku, title, category, imageProps) => {
    return (swatch) => {
        const swatchModifier = swatch && getColorNameForImages(swatch)

        const defaultImageName = swatchModifier ? `${sku}-${swatchModifier}-plp.jpg` : `${sku}-plp.jpg`
        const hoverImageName = swatchModifier ? `${sku}-${swatchModifier}-plp-hover.jpg` : `${sku}-plp-hover.jpg`
        const alt = `The ${title}`

        const defaultImage = {
            ...imageProps,
            alt,
            lazyLoadLabel: alt,
            folder: `category/${category}/${sku}-plp`,
            name: defaultImageName
        }

        const hoverImage = {
            ...imageProps,
            alt,
            folder: `category/${category}/${sku}-plp-hover`,
            name: hoverImageName
        }

        return {
            default: defaultImage,
            hover: hoverImage
        }
    }
}

const getSwatchOverride = (swatchObject, defaultSwatch) => {
    if (defaultSwatch) {
        if (swatchObject.values.some((option) => option.code === defaultSwatch)) {
            return defaultSwatch
        }
    }
    return swatchObject.default
}

/**
 * Transform the swatch options from catalog into the structure used in the productTile component for swatch selection
 * @param {array} options
 * @param {string} defaultOverride
 * @returns {object}
 */
export const transformSwatchOptionsForCarousel = (options, defaultOverride = null) => {
    let swatchObject = {
        label: '',
        initialSelection: '',
        size: 'small',
        optionsList: []
    }

    const swatch = options.find(option => option?.code === 'color') || options.find(option => option?.code === 'fabric')

    if (!swatch) return swatchObject

    const defaultSwatch = getSwatchOverride(swatch, defaultOverride)
    const optionsList = []
    swatch.values.map((option) => {
        const swatchOption = {
            value: option.code,
            label: option.label,
            background: option.swatch,
            useInverseCheckColor: true
        }
        optionsList.push(swatchOption)
    })

    return {
        ...swatchObject,
        label: swatch.displayName,
        initialSelection: defaultSwatch,
        optionsList
    }
}
