// enum values are used but eslint is not recognizing them as used
/* eslint-disable no-unused-vars */
export enum LogLevel {
    Info = 'info',
    Warn = 'warn',
    Error = 'error',
    Debug = 'debug',
}
/* eslint-enable no-unused-vars */

/**
 * Arguments used for calling a logger utility
 * @typedef LogArgs: Object
 * @property message: string - The log message to record.
 * @property message: string  - The location where the log occurred.
 * @property error: Error - An optional error object to include.
 * @property extraInfo: Record<string, any> - Any additional information you want to log.
 * @property tags: Record<string, any> - Optional tags for sentry alerts.
 * @property enableClientAlerts: boolean - Whether to allow client-side alerting.
 */
export interface LogArgs {
    message: string
    location: string
    error?: Error
    extraInfo?: Record<string, any>
    tags?: Record<string, any>
    enableClientAlerts?: boolean
}

export interface FormatLogArgs extends LogArgs {
    level: LogLevel
}

export interface SentryContext {
    tags?: Record<string, any>;
    extra?: Record<string, any>;
}
