import React, {
    createRef,
    useRef,
    useState,
    useEffect,
    useContext,
} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { OFFSETS } from '@saatva-bits/pattern-library.utils.position'
import useDeviceType from '@/hooks/useDeviceType'
import Reviews from '@/contexts/reviews'
import { scrollToSection } from './stickyNavUtils'
import styles from './ProductStickyNav.module.scss'

// This component is used to insert the sticky nav below the breadcrumbs on mobile.
const ProductStickyNavBar = ({
    links = []
}) => {
    const { isDesktop, deviceType } = useDeviceType('desktop')
    const linksRefArray = links.map(() => createRef())
    const linkRefs = useRef(linksRefArray)
    const [isStickyNav, setIsStickyNav] = useState(false)
    const [reviewOffsetAdjustment, setReviewOffsetAdjustment] = useState(150)
    const ReviewsContext = useContext(Reviews.Context)

    const handleScroll = (contentAnchorTop) => {
        if (window.scrollY >= (contentAnchorTop)) {
            setIsStickyNav(true)
        } else {
            setIsStickyNav(false)
        }
    }

    useEffect(() => {
        // Set constant offset for nav stickiness based on the content wrapper and initial window position.
        const contentAnchor = document.querySelector('.js-contentNav-anchor')
        const contentAnchorTop = Math.abs(window.scrollY + contentAnchor.getBoundingClientRect().top)

        document.addEventListener('scroll', () => handleScroll(contentAnchorTop))

        if (window.scrollY >= contentAnchorTop) {
            setIsStickyNav(true)
        } else {
            setIsStickyNav(false)
        }

        return () => {
            document.removeEventListener('scroll', () => handleScroll(contentAnchorTop))
        }
    }, [isDesktop])

    const navClasses = classNames(styles.nav, styles.navBar, {
        [styles.navSticky]: isStickyNav
    })

    const linksCount = links.filter(link => !link.isCta).length
    const linkSetClasses = classNames(styles.linkSet, {
        [styles.linkSetSpaceAdjusted]: linksCount <= 4 // Adjust tab spacing while links <= 4
    })

    const renderLinks = () => {
        return (
            <nav className={styles.linkWrapper}>
                <ul>
                    {links.map((link, i) => {
                        const linkId = link.id === 'reviews' ? 'customer-reviews' : link.id
                        const classes = classNames(
                            styles.link,
                            {
                                [styles.active]: link.id === 'overview',
                                [styles.linkSpaceAdjusted]: linksCount <= 4
                            }, link.customClass)
                        if (!link.isCta) {
                            return (
                                <li key={`link${i}`}>
                                    <button
                                        type="button"
                                        className={classes}
                                        ref={linkRefs.current[i]}
                                        onClick={() => {
                                            let offset = OFFSETS.both[deviceType]
                                            if (!isDesktop && linkId === 'customer-reviews') {
                                                // Toggles the mobile review accordion open
                                                ReviewsContext.dispatch({
                                                    type: 'SET_ACCORDION_VALUES',
                                                    accordionName: 'reviews-accordion',
                                                    accordionContent: 'reviews-accordion-content',
                                                    accordionTitle: 'reviews-accordion-title',
                                                })
                                                // Add extra height to account for unexpanded accordion on first scroll
                                                offset -= reviewOffsetAdjustment
                                                setReviewOffsetAdjustment(0) // reset to avoid extra space on subsequent scroll
                                            }
                                            scrollToSection(linkId, offset)
                                        }}>
                                        <span className={styles.linkName}>{link.name}</span>
                                    </button>
                                </li>
                            )
                        }
                    })}
                </ul>
            </nav>
        )
    }

    if (links.length === 0) {
        return null
    }

    return (
        <div className={navClasses}>
            <div className='container'>
                <div className='row'>
                    <div className={linkSetClasses}>
                        {renderLinks()}
                    </div>
                </div>
            </div>
        </div>
    )
}

ProductStickyNavBar.propTypes = {
    links: PropTypes.array.isRequired
}

export default ProductStickyNavBar
