import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const FancyBanner = ({
    children,
    className
}) => {
    const wrapperClasses = classNames(
        'fancyBanner__wrapper',
        className
    )

    const classes = classNames('fancyBanner')

    const contentClasses = classNames('fancyBanner__content')

    return (
        <div className={wrapperClasses}>
            <div className={classes}>
                <div className={contentClasses}>
                    {children}
                </div>
            </div>
        </div>
    )
}

FancyBanner.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isTall: PropTypes.string
}

export default FancyBanner
