import { useEffect } from 'react'

const useBodyOverflowControl = (isOverflowHidden) => {
    useEffect(() => {
        if (isOverflowHidden) {
            document.body.classList.add('u-overflow-hidden')
        } else {
            document.body.classList.remove('u-overflow-hidden')
        }
    }, [isOverflowHidden])
}

export default useBodyOverflowControl
