import React from 'react'
import propTypes from 'prop-types'
import { useProductData } from '@saatva-bits/pattern-library.modules.selection'
import { 
    CheckboxAddon,
    DetailedCheckboxBuystackAddon,
    FoundationBaseAddon,
    FoundationDropdownAddon,
} from '@/components/Addon/Addons'
import DropdownBuystackAddon from './Addons/DropdownBuystackAddon/DropdownBuystackAddon'

const ADDON_RELATIONSHIPS = {
    DROPDOWN_BUYSTACK_ADDON: 'dropdown_buystack_addon',
    FOUNDATION_DROPDOWN_ADDON: 'foundation_dropdown_addon',
    CHECKBOX_ADDON: 'checkbox_addon',
    DETAILED_CHECKBOX_BUYSTACK_ADDON: 'detailed_checkbox_buystack_addon',
    FOUNDATION_ADJUSTABLE_BASE_ADDON: 'foundation_adjustable_base_addon'
}

const ADDON_SORT_ORDER = [
    ADDON_RELATIONSHIPS.DROPDOWN_BUYSTACK_ADDON,
    ADDON_RELATIONSHIPS.FOUNDATION_ADJUSTABLE_BASE_ADDON,
    ADDON_RELATIONSHIPS.FOUNDATION_DROPDOWN_ADDON,
    ADDON_RELATIONSHIPS.CHECKBOX_ADDON,
    ADDON_RELATIONSHIPS.DETAILED_CHECKBOX_BUYSTACK_ADDON
]

const Addon = ({
    productCode
}) => {
    const productData = useProductData(productCode)
    const relationshipsPD = [...productData.relationships] // copied to avoid reassignment error
    // sort relationships by how we want them to appear on the PDP
    const relationships = relationshipsPD.sort((a, b) => (ADDON_SORT_ORDER.indexOf(a.relationshipLabel) || 99) - (ADDON_SORT_ORDER.indexOf(b.relationshipLabel) || 99))
    const addons = []
    relationships.forEach((relationship) => {
        const label = relationship.relationshipLabel
        if (relationship.relatedProductCodes.length) {
            switch (label) {
                // Missing Addons
                case ADDON_RELATIONSHIPS.DROPDOWN_BUYSTACK_ADDON:
                    relationship.relatedProductCodes.forEach((addonProductCode) => {
                        const dropdownBuystackAddon = (
                            <DropdownBuystackAddon
                                primaryProductCode={productCode}
                                addonProductCode={addonProductCode}
                                addonLabel={label}
                                key={`${label}-${addonProductCode}`}
                            />
                        )
                        addons.push(dropdownBuystackAddon)
                    }) 
                    break
                case ADDON_RELATIONSHIPS.FOUNDATION_ADJUSTABLE_BASE_ADDON:
                    addons.push(
                        <FoundationBaseAddon
                            primaryProductCode={productCode}
                            addonProductCodes={relationship.relatedProductCodes}
                            addonLabel={label}
                            key={label}
                        />
                    )
                    break
                case ADDON_RELATIONSHIPS.FOUNDATION_DROPDOWN_ADDON:
                    relationship.relatedProductCodes.forEach((addonProductCode) => {
                        const foundationDropdownAddon = (
                            <FoundationDropdownAddon
                                key={`${label}-${addonProductCode}`}
                                primaryProductCode={productCode}
                                addonProductCode={addonProductCode}
                                addonLabel={label}
                            />
                        )
                        addons.push(foundationDropdownAddon)
                    })
                    break
                case ADDON_RELATIONSHIPS.CHECKBOX_ADDON:
                    relationship.relatedProductCodes.forEach((addonProductCode) => {
                        const checkboxAddon = (
                            <CheckboxAddon
                                primaryProductCode={productCode}
                                addonProductCode={addonProductCode}
                                addonLabel={label}
                            />
                        )
                        addons.push(checkboxAddon)
                    })
                    break
                case ADDON_RELATIONSHIPS.DETAILED_CHECKBOX_BUYSTACK_ADDON:
                    relationship.relatedProductCodes.forEach((addonProductCode) => {
                        const detailedCheckboxBuystackAddon = (
                            <DetailedCheckboxBuystackAddon
                                primaryProductCode={productCode}
                                addonProductCode={addonProductCode}
                                addonLabel={label}
                                key={`${label}-${addonProductCode}`}
                            />
                        )
                        addons.push(detailedCheckboxBuystackAddon)
                    })
                    break
                default:
                // The relationshipLabel does not match an addon supported by this component
                    break
            }
        }
    })

    return (
        <>
            {addons}
        </>
    )
}

Addon.propTypes = {
    productCode: propTypes.string
}

export default Addon
