import React from 'react'
import PropTypes from 'prop-types'
import { useProductState } from '@saatva-bits/pattern-library.modules.selection'
import styles from './ProductWhatsIncluded.module.scss'

const ProductWhatsIncluded = ({
    productCode
}) => {
    const { attributes } = useProductState(productCode)

    if (!attributes || !attributes.length) return null

    let whatsIncludedData = attributes.find(item => item?.whatsIncluded)?.whatsIncluded

    if (!whatsIncludedData) return null

    return (
        <div className={`${styles.whatsIncluded} ${styles.sizeSelector} u-marginBottom`}>
            <div dangerouslySetInnerHTML={{ __html: whatsIncludedData }}></div>
        </div>
    )
}

ProductWhatsIncluded.propTypes = {
    productCode: PropTypes.string
}

export default ProductWhatsIncluded
