import React from 'react'
import PropTypes from 'prop-types'

import { Picture } from '@saatva-bits/pattern-library.components.picture-imgix'

const PictureImgix = (props) => {
    /* We now assume all images will use the ARDAD format, negating:
        - the need for image filename prefixes (including universal)
        - the option to NOT define a width (necessary to avoid large file images)
    */
    const pictureProps = {
        ...props,
        prefixOverride: props.prefixOverride || {
            mobile: 'none',
            tablet: 'none',
            desktop: 'none'
        },
        isUniversal: props.isUniversal || false,
        widths: props.widths || { mobile: 348 } // default to ensure we don't fetch the large default file size
    }

    return (
        <Picture imgixDomain={process.env.NEXT_PUBLIC_IMGIX_URL} {...pictureProps} />
    )
}

PictureImgix.propTypes = {
    ...Picture.propTypes,
    imgixDomain: PropTypes.string
}

export default PictureImgix
