import { FaqSection } from '@saatva-bits/pattern-library.modules.faq'
import PDPReviews from '@/components/PDPReviews'
import CrossSells from '@/components/CrossSells'

export default function StandardContent({
    productCode
}) {
    return (
        <>
            <FaqSection />
            <PDPReviews productCode={productCode} />
            <CrossSells />
        </>
    )
}
