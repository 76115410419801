import React from 'react'

import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'

import {
    CLASSIC_STARTER_BUNDLE,
    LOOM_AND_LEAF,
    MEMORY_FOAM_HYBRID,
    SAATVA_CLASSIC,
    SAATVA_CONTOUR5,
    SAATVA_HD,
    SAATVA_LATEX_HYBRID,
    SAATVA_RX,
    SOLAIRE,
    ZENHAVEN
} from '@/constants/product-codes'

import styles from './ScaleComfortLevels.module.scss'
import InfoTooltip from '../InfoTooltip'

const contour5 = {
    heading: 'Choose your comfort level',
    hasAlternateColor: true,
    customMediaClassName: 'u-bgColor--accentBlue',
    tabs: [
        {
            tabName: 'Medium',
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-luxury-blue' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText} ${styles['scale-5-7']}`}>5-7</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Hugs the body without allowing the hips to sink too far</li>
                        <li>A true medium firmness, cushioned but not too soft</li>
                    </ul>
                    <br />
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Side sleepers, but also great for back and combination sleepers</li>
                        <li>Couples who can’t agree on firmness</li>
                    </ul>
                </>
            )
        },
        {
            tabName: 'Firm',
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-firm-blue' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText} ${styles['scale-8']}`}>8</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Has more “push back” support and responsiveness than the Medium</li>
                        <li>A true firm, but never hard on the back and joints</li>
                    </ul>
                    <br />
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Back and stomach sleepers or those who need/prefer a firm mattress</li>
                        <li>Heavier weight sleepers over 230 pounds</li>
                        <li>Sleepers who love memory foam but move around a lot</li>
                    </ul>
                </>
            )
        }
    ]
}

const classic = {
    heading: 'Choose your comfort level',
    hasAlternateColor: true,
    customMediaClassName: 'u-bgColor--accentBlue',
    tabs: [
        {
            tabName: 'Plush Soft',
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-soft-blue' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText} ${styles['scale-3']}`}>3</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Soft & cushiony thanks to the extra padding on top</li>
                        <li>Body-hugging to relieve pressure points</li>
                    </ul>
                    <br />
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Side sleepers who need extra pressure relief on the hips and shoulders</li>
                        <li>Lighter-weight people who often find mattresses to be too firm</li>
                    </ul>
                </>
            )
        },
        {
            tabName: 'Luxury Firm',
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-luxury-blue' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText} ${styles['scale-5-7']}`}>5-7</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Medium firmness, perfect balance of cushioning and uplifting support</li>
                        <li>Designed to replicate the feel of luxury hotel beds</li>
                    </ul>
                    <br />
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>All sleep positions</li>
                        <li>Couples who can’t agree on firmness</li>
                    </ul>
                </>
            )
        },
        {
            tabName: 'Firm',
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-firm-blue' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText} ${styles['scale-8']}`}>8</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Lying on top of the mattress rather than sinking in</li>
                        <li>Balanced resistance that’s never hard on the back and joints</li>
                    </ul>
                    <br />
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Stomach & back sleepers or heavier body types</li>
                        <li>Sleepers who need an extra-firm mattress</li>
                        <li>Ideal for heavier weight sleepers 250-300 pounds</li>
                    </ul>
                </>
            )
        }
    ]
}

const zenhaven = {
    heading: 'Dual-sided for your perfect comfort level',
    customMediaClassName: 'u-bgColor--accentGreenLight',
    tabs: [
        {
            tabName: 'Luxury Plush',
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-plush' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText} ${styles['scale-4-5']}`}>4-5</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Soft yet incredibly weightless and buoyant</li>
                        <li>Body-contouring to relieve pressure points</li>
                    </ul>
                    <br />
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Side sleepers, but also great for back and combination sleepers</li>
                        <li>Couples who can’t agree on firmness</li>
                    </ul>
                </>
            )
        },
        {
            tabName: 'Gentle Firm',
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-firm' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText} ${styles['scale-7-8']}`}>7-8</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>18% firmer than the Luxury Plush side, with more “push back” support and responsiveness</li>
                        <li>A true firm, but never hard on the back and joints</li>
                    </ul>
                    <br />
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Stomach sleepers or those who need/prefer a firm mattress</li>
                        <li>Sleepers who love more uplifting support</li>
                    </ul>
                </>
            )
        }
    ]
}

const hd = {
    heading: 'Comfort for every body',
    hasAlternateColor: true,
    customMediaClassName: 'u-bgColor--accentBlue',
    tabs: [
        {
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-luxury-blue' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText} ${styles['scale-5-7']}`}>5-7</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul>
                        <li>Cushioned feel of a pillow top paired with the buoyancy of natural latex and body-hugging feel of memory foam</li>
                        <li>A balanced medium feel for pressure-free comfort</li>
                    </ul>
                    <br />
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul>
                        <li>All sleep positions</li>
                        <li>Couples who can't agree on firmness</li>
                    </ul>
                    <br />
                    <p className="t-italic t-bodySm u-displayBlock"><small>*For sleepers over 300 pounds. Sleepers under 300 pounds might find the Saatva HD to have a firmer feel</small></p>
                </>
            )
        }
    ]
}

const memoryFoamHybrid = {
    heading: 'The perfect medium firmness',
    hasAlternateColor: true,
    customMediaClassName: 'u-bgColor--accentBlue',
    tabs: [
        {
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-luxury-blue' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText} ${styles['scale-5-7']}`}>5-7</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul>
                        <li>Cushioned but not too soft</li>
                        <li>Body-contouring yet responsive</li>
                    </ul>
                    <br />
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul>
                        <li>Side sleepers, but also great for back and combination sleepers</li>
                        <li>Couples who can't agree on firmness</li>
                    </ul>
                </>
            )
        }
    ]
}

const latexHybrid = {
    heading: 'The perfect medium firmness',
    hasAlternateColor: true,
    customMediaClassName: 'u-bgColor--accentBlue',
    tabs: [
        {
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-medium-firm' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText} ${styles['scale-6']}`}>6</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul>
                        <li>Responsive support with a buoyant feel</li>
                        <li>Body-contouring to relieve pressure points</li>
                    </ul>
                    <br />
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul>
                        <li>All sleep positions, best suited for back and stomach sleepers</li>
                        <li>Couples who can't agree on firmness</li>
                    </ul>
                </>
            )
        }
    ]
}

const rx = {
    heading: 'Plush comfort without sacrificing support',
    hasAlternateColor: true,
    customMediaClassName: 'u-bgColor--accentBlue',
    tabs: [
        {
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-supporitve-plush' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText} ${styles['scale-3-4']}`}>3-4</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul>
                        <li>Instantly relieves pressure on back and joints</li>
                        <li>Body-contouring and responsive</li>
                    </ul>
                    <br />
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul>
                        <li>Side sleepers, but also great for back and combination sleepers</li>
                        <li>Stomach sleepers under 130 pounds</li>
                        <li>Sleepers with serious, chronic back & joint conditions</li>
                    </ul>
                </>
            )
        }
    ]
}

const loomAndLeaf = {
    heading: 'Choose your comfort level',
    hasAlternateColor: true,
    customMediaClassName: 'u-bgColor--accentBlue',
    tabs: [
        {
            tabName: 'Relaxed Firm',
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-luxury-blue' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText} ${styles['scale-5-7']}`}>5-7</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Hugs the body without allowing the hips to sink too far</li>
                        <li>A true medium firmness, cushioned but not too soft</li>
                    </ul>
                    <br />
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Side sleepers, but also great for back and combination sleepers</li>
                        <li>Couples who can’t agree on firmness</li>
                    </ul>
                </>
            )
        },
        {
            tabName: 'Firm',
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-firm-blue' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText} ${styles['scale-8']}`}>8</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Has more “push back” support and responsiveness than the Relaxed Firm</li>
                        <li>A true firm, but never hard on the back and joints</li>
                    </ul>
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Stomach sleepers or those who need/prefer a firm mattress</li>
                        <li>Sleepers who love memory foam but move around a lot</li>
                    </ul>
                </>
            )
        }
    ]
}


const solaire = {
    heading: '50 firmness settings for personalized comfort',
    hasAlternateColor: true,
    customMediaClassName: 'u-bgColor--accentBlue',
    tabs: [
        {
            tabName: 'Settings 1-29',
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-supporitve-plush' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText} ${styles['scale-3-4']}`}>3-4</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>
                            Soft to soft-medium depending on your body weight
                            <InfoTooltip>
                                <p>Most users who prefer a softer mattress start at the 10 setting and go up and down as desired. Some may find the single-digit settings to be too soft.</p>
                            </InfoTooltip>
                        </li>
                        <li>Body-hugging pressure relief thanks to the deeply cushioned Euro pillow top</li>
                    </ul>
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Side sleepers who need extra pressure relief on the hips and shoulders</li>
                        <li>Lighter weight people who often find mattresses to be too firm</li>
                    </ul>
                </>
            )
        },
        {
            tabName: 'Settings 30-39',
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-5-6-blue' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText}`}>5-6</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Soft-medium to medium depending on your body weight</li>
                        <li>Body-hugging pressure relief  thanks to the deeply cushioned Euro pillow top</li>
                    </ul>
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>All sleep positions except stomach sleeping</li>
                        <li>Light to medium weight people</li>
                    </ul>
                </>
            )
        },
        {
            tabName: 'Settings 40-50',
            content: (
                <>
                    <div className={styles.comfortMeter}>
                        <SvgSprite className={styles.comfortMeterIcon} spriteID='icon-comfort-7-8-blue' />
                        <div className={styles.comfortMeterIcons}>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-soft' />
                            <p className={`${styles.comfortMeterText} ${styles['scale-7-8']}`}>7-8</p>
                            <SvgSprite className={styles.comfortMeterSvg} spriteID='icon-luxury' />
                        </div>
                        <div className={styles.comfortMeterLabels}>
                            <p>Soft</p>
                            <p>Firm</p>
                        </div>
                    </div>
                    <h3 className={styles.comfortLevelHeading}>How it feels:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>Medium-firm to firm depending on your body weight</li>
                        <li>Body-hugging pressure relief  thanks to the deeply cushioned Euro pillow top</li>
                    </ul>
                    <h3 className={styles.comfortLevelHeading}>Ideal for:</h3>
                    <ul className={styles.comfortLevelList}>
                        <li>All sleep positions especially stomach sleepers</li>
                        <li>Heavier body types</li>
                    </ul>
                </>
            )
        }
    ]
}

const config = {
    [SAATVA_CLASSIC]: classic,
    [CLASSIC_STARTER_BUNDLE]: classic,
    [SAATVA_CONTOUR5]: contour5,
    [ZENHAVEN]: zenhaven,
    [SAATVA_HD]: hd,
    [MEMORY_FOAM_HYBRID]: memoryFoamHybrid,
    [SAATVA_LATEX_HYBRID]: latexHybrid,
    [SAATVA_RX]: rx,
    [SOLAIRE]: solaire,
    [LOOM_AND_LEAF]: loomAndLeaf
}

export default config
