import { useVariantsData } from '@saatva-bits/pattern-library.modules.selection'

// enriches bundledVariants with the variants' data from the selection store
export const useEnrichBundleVariants = (bundledVariants) => {
    if (!bundledVariants) bundledVariants = []

    // get all variant data for each bundled variant
    let variantData = useVariantsData(bundledVariants)

    // enrich and return each bundled variant
    return variantData.map((variant, i) => {
        return {
            ...variant,
            quantity: bundledVariants[i].quantity,
            isPrimaryProduct: false
        }
    })
}

export default useEnrichBundleVariants
