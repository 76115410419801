import React from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'

import { useProductState, useProductsState } from '@saatva-bits/pattern-library.modules.selection'
import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'
import { useGetDiscounts,  usePromotionsData } from '@saatva-bits/pattern-library.modules.promotions'
import { Badge } from '@saatva-bits/pattern-library.components.badge'

import useEnrichBundleVariants from '@/hooks/useEnrichBundleData'
import { useAddonContext } from '@/contexts/addon'
import AffirmMessage from '@/components/AffirmMessage'
import { CLASSIC_STARTER_BUNDLE } from '@/constants/product-codes'
import styles from './ProductSubtotal.module.scss'
import { MAP_PROMO_COLOR_TO_BADGE } from '@/constants/colors'
import { CATEGORIES } from '@/temp-configs/category-constants'

const ProductSubtotal = ({ productCode }) => {
    const { getDiscounts } = useGetDiscounts()
    const { sitewidePromo } = usePromotionsData()
    const { enableBundleDiscounts, bundleDiscountPercent } = sitewidePromo

    const bannerColor = sitewidePromo?.theme
    const badgeColor = bannerColor && MAP_PROMO_COLOR_TO_BADGE[bannerColor] ? MAP_PROMO_COLOR_TO_BADGE[bannerColor] : MAP_PROMO_COLOR_TO_BADGE.blue

    // get the states of the selected addons
    const { selectedAddonProductCodes, getProductCode } = useAddonContext()
    const addonProductCodes = [...selectedAddonProductCodes].map((code) => getProductCode(code))
    const addonVariants = useProductsState(addonProductCodes)

    // get the state of the primary product
    const primaryProductState = useProductState(productCode)

    // if the primary product is a bundle, get the data for each product variant included in the bundle
    const bundledVariants = useEnrichBundleVariants(primaryProductState.bundledVariants)

    // if the primary product is a bundle, use the bundled variants to calculate the discounts, otherwise use the primary product
    // always include the addon variants in the discount calculation
    let variantsForDiscounts = bundledVariants.length ? [...bundledVariants, ...addonVariants] : [primaryProductState, ...addonVariants]

    const productInfoForDiscount = variantsForDiscounts.map(variant => {
        return {
            category: variant.category.toLowerCase(),
            isInStock: variant.inStock,
            isPrimaryProduct: variant.isPrimaryProduct || true,
            parentSku: variant.productCode,
            price: variant.price,
            quantity: variant.quantity || 1,
            sku: variant.sku
        }
    })

    let {
        fullPrice,
        finalPrice,
        discountAmount,
    } = getDiscounts(productInfoForDiscount)

    // temporarily handling bundle discounts.
    if (primaryProductState.category.toLowerCase() === CATEGORIES.BUNDLES && enableBundleDiscounts && bundleDiscountPercent) {
        const bundleDiscount = bundleDiscountPercent / 100
        finalPrice = Math.round(fullPrice - (fullPrice * bundleDiscount))
        discountAmount = Math.round(fullPrice * bundleDiscount)
    }

    const showStrikethroughPrice = fullPrice !== finalPrice

    // We only show the promo pill for discounts on the Classic Starter Bundle.
    // This is also needed to adjust mobile styles and font sizes on that product.
    const hasPromoPill = productCode === CLASSIC_STARTER_BUNDLE && discountAmount > 0

    // determine the primary product or bundled products are in stock
    // do not check if the the addon products are in stock, if they are out of stock, they won't be added to cart
    const inStock = bundledVariants.length ? bundledVariants.every((variant => variant.inStock)) : primaryProductState.inStock

    const renderStrikethrough = () => {
        const strikethroughClasses = classNames(styles.strikethroughPrice, 'u-marginRight--sm', {
            [styles.fontSizeWithPromoPill]: hasPromoPill
        })
        return (
            showStrikethroughPrice &&
            <span
                data-selector="product-subtotal-price"
                className={strikethroughClasses}
            >
                {formatCurrency(fullPrice)}
            </span>
        )
    }

    if (Boolean(finalPrice) && inStock) {
        const subtotalClasses = classNames(styles.text, {
            [styles.fontSizeWithPromoPill]: hasPromoPill
        })
        const finalPriceClasses = classNames(styles.finalPrice, {
            [styles.fontSizeWithPromoPill]: hasPromoPill
        })
        return (
            <>
                <div className={`${styles.subtotal} u-fullWidth`}>
                    <div>
                        <span className={subtotalClasses}>Subtotal</span>
                    </div>
                    <div>
                        {hasPromoPill && <Badge className={styles.badge} text={`Save ${formatCurrency(discountAmount)}`} kind={badgeColor} position="inline" />}
                        {renderStrikethrough()}
                        <span
                            data-selector="product-total-price"
                            className={finalPriceClasses}>
                            {formatCurrency(finalPrice)}
                        </span>
                    </div>
                </div>
                <div>
                    <AffirmMessage
                        productCode={productCode}
                        finalPrice={finalPrice}
                    />
                </div>
            </>
        )
    } else {
        return null
    }
}

ProductSubtotal.propTypes = {
    productCode: string
}

export default ProductSubtotal
