import React from 'react'
import { string } from 'prop-types'
import { useProductData } from '@saatva-bits/pattern-library.modules.selection'
import styles from './ProductLongDescription.module.scss'

const ProductLongDescription = ({ productCode }) => {
    const { content } = useProductData(productCode)
    const longDescription = content ? content.longDescription : null

    return (
        <>
            {Boolean(longDescription) && (
                <div
                    className={styles.productLongDescription}
                    dangerouslySetInnerHTML={{
                        __html: longDescription
                    }}
                />
            )}
        </>
    )
}

ProductLongDescription.propTypes = {
    productCode: string
}

export default ProductLongDescription
