import React from 'react'
import PropTypes from 'prop-types'

import { useContentThemeData } from '@/contexts/contentTheme'
import { Icon } from '@saatva-bits/pattern-library.components.icon'
import { SAATVA_CLASSIC } from '@/constants/product-codes'
import config from './personalizationConfigs'
import styles from './PersonalizationPanel.module.scss'

const PersonalizationPanel = ({ productCode }) => {
    const { luxuryEligible, innerspringEligible } = useContentThemeData()
    const isClassicMattress = productCode === SAATVA_CLASSIC
    const hasPersonalizationPanel = luxuryEligible || innerspringEligible
    
    if (!isClassicMattress || !hasPersonalizationPanel) return null

    const configKey = innerspringEligible ? 'innerspring' : 'luxury'
    const panelContent = config[configKey].content

    return (
        <div className={styles.personalizationPanel}>
            <h2 className={styles.title}>{config[configKey].title}</h2>
            <ul className={styles.content}>
                {panelContent.map((item, index) => (
                    <li key={`personalizationPanel-${index}`} className={styles.contentItem}>
                        <Icon
                            className={styles.contentIcon}
                            height='16'
                            width='16'
                            minWidth='16'
                            minHeight='16'
                            name='circleCheck'
                            titleId='personalizationPanelIcon'
                            fill='#101641'
                            alt='Check icon'
                            description='Check icon'
                        />
                        <p className={styles.contentText}>
                            {item}
                        </p>
                    </li>
                ))}
            </ul>
        </div>
    )
}

PersonalizationPanel.propTypes = {
    productCode: PropTypes.string.isRequired
}

export default PersonalizationPanel
