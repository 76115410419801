import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

const Context = createContext()
const Consumer = Context.Consumer

// Used for ReviewsAccordion component to open on click
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_ACCORDION_VALUES': {
            const newState = { ...state }
            const { accordionName, accordionContent, accordionTitle } = action
            newState.accordionName = accordionName
            newState.accordionContent = accordionContent
            newState.accordionTitle = accordionTitle
            return newState
        }
        case 'RESET_ACCORDION_VALUES': {
            const newState = { ...state }
            newState.accordionName = ''
            newState.accordionContent = ''
            newState.accordionTitle = ''
            return newState
        }

        default: return state
    }
}

const Provider = ({
    children,
    accordionName = '', // expanded is 'reviews-accordion'
    accordionContent = '', // expanded is 'reviews-accordion-content'
    accordionTitle = '', // expanded is 'reviews-accordion-title'
    reviewCount = 0, // review count for product
    questionCount = 0, // question count for product
    totalProductReviewCount = 40000, // total review count for ALL products
}) => {
    const initialState = {
        accordionName,
        accordionContent,
        accordionTitle,
        reviewCount,
        questionCount,
        totalProductReviewCount,
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const providedItems = {
        accordionName: state.accordionName,
        accordionContent: state.accordionContent,
        accordionTitle: state.accordionTitle,
        reviewCount: state.reviewCount,
        questionCount: state.questionCount,
        totalProductReviewCount: state.totalProductReviewCount,
        dispatch
    }

    return (
        <Context.Provider value={providedItems}>
            {children}
        </Context.Provider>
    )
}

// add display name for dev tool readability
Context.displayName = 'Reviews'
Provider.displayName = 'ReviewsContext'

Provider.propTypes = {
    children: PropTypes.any,
    accordionName: PropTypes.string,
    accordionContent: PropTypes.string,
    accordionTitle: PropTypes.string,
    reviewCount: PropTypes.number,
    questionCount: PropTypes.number,
    totalProductReviewCount: PropTypes.number,
}

const Reviews = {
    Context,
    Provider,
    Consumer,
}

export default Reviews
