import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'
import StrikethroughPrice from '@/components/StrikethroughPrice'

const ProductPrice = ({ product, index, pricePrefix }) => {
    const sizeText = product.size
    const productName = product.shortName ? <span>&nbsp;{product.shortName}</span> : null
    const plusSign = index > 0 ? <span className="u-hidden--md-down">&nbsp;+</span> : null
    const price = product.price
    const discountAmount = product.discountAmount

    const pricePrefixClasses = 'priceSnapshot__pricePrefix'

    return (
        <div className="priceSnapshot__container">
            {pricePrefix
                ? <span className={pricePrefixClasses}>{pricePrefix}</span>
                : <span className={pricePrefixClasses}>{plusSign}{sizeText}{productName}</span>
            }
            {discountAmount
                ? <StrikethroughPrice className="priceSnapshot__prices" discountClassName="priceSnapshot__discount" price={price} discountAmount={discountAmount} />
                : <span className="priceSnapshot__noDiscount" data-selector="sale-price">{formatCurrency(price)}</span>
            }
        </div>
    )
}

const PriceSnapShot = ({
    className,
    products,
    pricePrefix
}) => {
    const classes = classNames('priceSnapshot', className)

    return (
        <div className={classes}>
            <div className="priceSnapshot__wrapper">
                {products.map((product, i) => (
                    <ProductPrice
                        key={`price${i}`}
                        index={i}
                        product={product}
                        pricePrefix={pricePrefix}
                    />
                ))}
            </div>
        </div>
    )
}

PriceSnapShot.propTypes = {
    className: PropTypes.string,
    products: PropTypes.arrayOf(PropTypes.shape({
        price: PropTypes.number,
        discountAmount: PropTypes.number,
        size: PropTypes.string
    })).isRequired,
    pricePrefix: PropTypes.string
}

export default PriceSnapShot
