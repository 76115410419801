import { useAddonContext } from '@/contexts/addon'

const useAddon = (productCode, addonLabel) => {
    const { selectedAddonProductCodes, setSelectedAddonProductCodes, encodeAddonProductCode, getProductCode } = useAddonContext()

    const formattedCode = encodeAddonProductCode(addonLabel, productCode)
    const addonProductCodes = [...selectedAddonProductCodes].map((code) => getProductCode(code))

    // Is this addon product included in the list of selected addons
    const isAddonSelected = selectedAddonProductCodes.has(formattedCode)

    // is the product selected through any existing addons on the page
    const isProductSelectedViaAddons = addonProductCodes.includes(productCode)

    // Add the addon to the set of selected addons
    const selectAddon = () => {
        setSelectedAddonProductCodes((prevState) => {
            return new Set([...prevState, formattedCode])
        })
    }

    // Remove the addon to the set of selected addons
    const unselectAddon = () => {
        setSelectedAddonProductCodes((prevState) => {
            return new Set([...prevState].filter(currentProductCode => currentProductCode !== formattedCode))
        })
    }

    // add/remove multiple addons from the set of selected addons
    const updateAddons = ({ addCodes = [], removeCodes = [] }) => {
        const formattedAddCodes = addCodes.map((code) => encodeAddonProductCode(addonLabel, code))
        const formattedRemoveCodes = removeCodes.map((code) => encodeAddonProductCode(addonLabel, code))
        setSelectedAddonProductCodes((prevState) => {
            let updatedList = [...prevState]
            updatedList = formattedRemoveCodes ? updatedList.filter(currentProductCode => !formattedRemoveCodes.includes(currentProductCode)) : updatedList
            updatedList = formattedAddCodes ? [...updatedList, ...formattedAddCodes] : updatedList
            return new Set(updatedList)
        })
    }

    return {
        isAddonSelected,
        selectAddon,
        unselectAddon,
        updateAddons,
        isProductSelectedViaAddons
    }
}

export default useAddon
