import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useFeatureFlag } from '@saatva-bits/pattern-library.modules.launch-darkly/dist/launch-darkly.hooks'
import { CRIB_MATTRESS, SAATVA_CLASSIC } from '@/constants/product-codes'
import PictureImgix from '@/components/PictureImgix'
import SvgImage from '@/components/SvgImage'
import styles from './AffiliateBadge.module.scss'
import { useContentThemeData } from '@/contexts/contentTheme'

const AffiliateBadge = ({
    productCode
}) => {
    const { isOn: showGHKComponent } = useFeatureFlag('ENABLE_GHK_ACCOLATE.PD-16182')
    const { luxuryEligible, innerspringEligible } = useContentThemeData()
    const renderCribBadge = productCode === CRIB_MATTRESS
    const badgeClasses = classNames(styles.affiliateBadge)
    // TODO add affiliate badges to product data
    let renderAffiliateBadge = (luxuryEligible || innerspringEligible) && productCode === SAATVA_CLASSIC
    let AffiliateBadge = <></>

    switch (true) {
        case renderCribBadge: // Only for crib mattress
            AffiliateBadge = <PictureImgix
                folder='pdp-images/affiliateBadges'
                name='GHK_2023.png'
                alt='Good Housekeeping’s 2023 Parenting Award'
                isUniversal
                lazyLoadOffset={300}
                widths={{
                    mobile: 52,
                    tablet: 80,
                    desktop: 80
                }}
            />
            renderAffiliateBadge = showGHKComponent
            break
        case luxuryEligible:
            AffiliateBadge =  <SvgImage imageID='icon-sleep-foundation-affiliate-badge' alt='Sleep Foundation Badge' />
            break
        case innerspringEligible:
            AffiliateBadge =  <SvgImage imageID='icon-us-news-affiliate-badge' alt='US News Badge' />
            break
        default:
            AffiliateBadge = <></>
    }

    if (!renderAffiliateBadge) return null
    
    return (
        <div className={badgeClasses}>
            {AffiliateBadge}
        </div>
    )
}

AffiliateBadge.propTypes = {
    productCode: PropTypes.string
}

export default AffiliateBadge
