import React, {useRef, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * This component is purely behavioral outside of the sliding border. The content and style of each tab is determined by what is passed in via `list`.  
 */

const TabList = ({
    className,
    tabClassName,
    sliderClassName,
    activeIndex = 0,
    list,
    onTabClick = () => {},
    ...other
}) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0)

    const sliderRef = useRef(null)

    const tabRefs = []

    useEffect(() => {
        window.addEventListener('resize', () => updateSlider(activeTabIndex))
        return () => {
            window.removeEventListener('resize', () => updateSlider(activeTabIndex))
        }
    }, [activeTabIndex])

    useEffect(() => {
        updateSlider(activeIndex)
        setActiveTabIndex(activeIndex)
    }, [activeIndex])

    const updateSlider = (index) => {
        try {
            sliderRef.current.style.left = tabRefs[index].offsetLeft + 'px'
            sliderRef.current.style.width = tabRefs[index].offsetWidth + 'px'
        } catch (error) {
            console.error(`[TabList] Error updating slider position with message: ${error.message}`)
        }
    }

    const handleTabSelection = (event, index) => {
        setActiveTabIndex(index)
        updateSlider(index)
        onTabClick({event, tabIndex: index})
    }

    const classes = classNames('tabList', className)
    const sliderClasses = classNames('tabList__slider', sliderClassName)

    const tablist = list.map((item, i) => {
        const lastItemIndex = list.length - 1

        const tabClasses = classNames({
            'tabList__tab': true,
            'is-active': i === activeTabIndex
        }, tabClassName)

        const tabIndex = i === activeTabIndex ? 0 : -1

        return (
            <button
                key={i}
                ref={(tab) => {
                    tab !== null && (tabRefs[i] = tab)
                }}
                role="tab"
                tabIndex={tabIndex}
                className={tabClasses}
                onClick={(e) => handleTabSelection(e, i) }
                onKeyDown={(event) => {
                    let newIndex = 0

                    switch (event.key) {
                        case 'ArrowDown':
                        case 'ArrowRight':
                            event.preventDefault()
                            newIndex = activeTabIndex < lastItemIndex
                                ? activeTabIndex + 1
                                : 0
                            tabRefs[newIndex].focus()
                            handleTabSelection(event, newIndex)
                            break
                        case 'ArrowUp':
                        case 'ArrowLeft':
                            event.preventDefault()
                            newIndex = activeTabIndex > 0
                                ? activeTabIndex - 1
                                : lastItemIndex
                            tabRefs[newIndex].focus()
                            handleTabSelection(event, newIndex)
                            break
                        case 'Home':
                            event.preventDefault()
                            tabRefs[newIndex].focus()
                            handleTabSelection(event, 0)
                            break
                        case 'End':
                            event.preventDefault()
                            tabRefs[lastItemIndex].focus()
                            handleTabSelection(event, lastItemIndex)
                            break
                    }
                }}>
                {item}
            </button>
        )
    })

    return (
        <div
            className={ classes }
            role="tablist"
            {...other}
        >
            {tablist}
            <span className={sliderClasses} ref={sliderRef} aria-hidden="true"></span>
        </div>
    )
}

TabList.propTypes = {
    className: PropTypes.string,
    tabClassName: PropTypes.string,
    sliderClassName: PropTypes.string,
    activeIndex: PropTypes.number,
    list: PropTypes.array.isRequired,
    onTabClick: PropTypes.func
}

export default TabList
