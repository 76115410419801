import { useEffect, useState } from 'react'

const BREAKPOINTS = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1025,
    xl: 1200,
}

// Replaces useIsDesktop, useIsMobile, useIsTablet from coresite-node
function useDeviceType(defaultDeviceType = 'mobile') {
    const [deviceType, setDeviceType] = useState(defaultDeviceType)
    const [isSmallDesktop, setIsSmallDesktop] = useState(false)
    const [isSmallMobile, setIsSmallMobile] = useState(false)

    const handleWindowSizeChange = () => {
        const width = window.innerWidth
        
        let newDeviceType
        let newIsSmallMobile = false
        let newIsSmallDesktop = false

        if (width < BREAKPOINTS.md) {
            newDeviceType = 'mobile'
            newIsSmallMobile = width < BREAKPOINTS.sm
        } else if (width >= BREAKPOINTS.md && width < BREAKPOINTS.lg) {
            newDeviceType = 'tablet'
        } else {
            newDeviceType = 'desktop'
            newIsSmallDesktop = width < BREAKPOINTS.xl
        }

        setDeviceType(newDeviceType)
        setIsSmallMobile(newIsSmallMobile)
        setIsSmallDesktop(newIsSmallDesktop)
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            handleWindowSizeChange() // Call it initially to set the correct state from the start
            window.addEventListener('resize', handleWindowSizeChange)
        }
        return () => window.removeEventListener('resize', handleWindowSizeChange)
    }, [])

    return {
        deviceType,
        isMobile: deviceType === 'mobile',
        isTablet: deviceType === 'tablet',
        isDesktop: deviceType === 'desktop',
        isSmallDesktop,
        isSmallMobile,
    }
}

export default useDeviceType
