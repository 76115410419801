import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { usePromotionsData, getDiscountAmount } from '@saatva-bits/pattern-library.modules.promotions'
import {
    findMatchingVariant,
    useProductData,
    useProductState,
    useUpdateProductAttributes
} from '@saatva-bits/pattern-library.modules.selection'
import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'
import { Dropdown } from '@saatva-bits/pattern-library.components.dropdown'
import { Modal } from '@saatva-bits/pattern-library.components.modal'

import useAddon from '@/hooks/addon'
import useDeviceType from '@/hooks/useDeviceType'

import StrikethroughPrice from '@/components/StrikethroughPrice'
import DiscountBadge from './partials/DiscountBadge'
import dropdownAddonConfig from './foundationDropdownAddonConfig'

function FoundationDropdownAddon({
    primaryProductCode,
    addonProductCode,
    addonLabel
}) {
    const productSpecificData = dropdownAddonConfig[primaryProductCode]
    const {
        addonTitle,
        modalTitle,
        modalContent,
        showDiscountBadge,
        defaultFoundationHeight,
        addByDefault,
        belowDropdownContent
    } = productSpecificData
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { isMobile, isSmallDesktop } = useDeviceType('mobile')
    const { isAddonSelected, selectAddon, unselectAddon } = useAddon(addonProductCode, addonLabel)
    const primaryProductState = useProductState(primaryProductCode)
    const primaryProductData = useProductData(primaryProductCode)
    const primaryProductSize = primaryProductState.size
    const addonProductData = useProductData(addonProductCode)
    const addonProductState = useProductState(addonProductCode)
    const updateProductAttributes = useUpdateProductAttributes()
    const [isFirstLoadComplete, setIsFirstLoadComplete] = useState(false)
    const [selectedFoundationHeight, setSelectedFoundationHeight] = useState(defaultFoundationHeight)
    const {
        isValueDiscountEligible,
        valueDiscountDefinitions
    } = usePromotionsData()

    const discountValue = isValueDiscountEligible && valueDiscountDefinitions?.foundationMattress?.discountValue

    // youth does not have all the possible sizes, so we need to narrow down the valid ones
    // to not show the user a higher discount than what they can actually get
    const filteredDiscountValue = {}
    discountValue && Object.keys(discountValue).forEach(key => {
        const found = primaryProductData.variants.some(variant => {
            return variant.sku.includes(key)
        })
        if (found) {
            filteredDiscountValue[key] = discountValue[key]
        }
    })

    const valueDiscountAmount = (isValueDiscountEligible && discountValue) && getDiscountAmount(filteredDiscountValue, addonProductState.sku)

    const titleClasses = classNames('foundationDropdownAddon__label u-marginTop--lg u-marginBottom--2xs')
    const modalClasses = classNames('t-link t-link--independent t-link--action foundationDropdownAddon__modalToggle')
    // remove extra styles and padding when frame checkbox addon is visible
    // may need to be updated if this is ever used without frame addon underneath
    const wrapperClasses = classNames({ 'u-marginBottom--lg': !isAddonSelected })

    const customDropdownItem = (label, productPrice, sku) => {
        const dataSelectorValue = `foundationDropdownAddonPrice-${sku}`
        let price = productPrice && <span data-selector={!valueDiscountAmount ? dataSelectorValue : ''}>{formatCurrency(productPrice)}</span>

        if (productPrice && valueDiscountAmount) {
            price = <StrikethroughPrice
                price={productPrice}
                discountAmount={valueDiscountAmount}
                fullPriceDataSelector={dataSelectorValue}
                reverseOrder={true}
            />
        }

        return (
            <div className="dropdown__text u-fullWidth u-flexDisplay u-flexJustify--spaceBetween">
                <span>{label}</span>
                {productPrice && price}
            </div>
        )
    }

    // effect hooks moved up to give option for graceful failure later without changing order of hooks
    // Update the addon product size when the primary product's size changes
    // Also update the selected value when the size selector is changed
    useEffect(() => {
        updateProductAttributes(addonProductCode, { size: primaryProductSize, foundationHeight: selectedFoundationHeight })
    }, [primaryProductSize, selectedFoundationHeight])

    // State updates behind the scenes for first load
    useEffect(() => {
        if (!isFirstLoadComplete) {
            // Foundation height sometimes defaults to 8.75 when it should be 4.75, this keeps the addon state consistent after first load
            updateProductAttributes(addonProductCode, { size: primaryProductSize, ...(defaultFoundationHeight !== 'none' && { foundationHeight: defaultFoundationHeight }) })

            // If the addon is selected by default (like the HD), update the addon state
            if (addByDefault) {
                selectAddon(addonProductCode)
            }
            setIsFirstLoadComplete(true)
        }
    }, [])

    const toggleModal = () => {
        setIsModalVisible((prevState) => !prevState)
    }

    const onDropdownChange = (e) => {
        // e.itemValue will either be a variant sku or 'none'
        if (e.value !== 'none') {
            selectAddon(addonProductCode)
            // ensures the foundation height is updated if changing size later
            const newVariantData = addonProductData.variants.find((variant) => variant.sku === e.value)
            if (newVariantData && newVariantData?.foundationHeight && newVariantData?.size) {
                updateProductAttributes(addonProductCode, {
                    foundationHeight: newVariantData.foundationHeight,
                    size: newVariantData.size
                })
            } else {
                console.error(`[FoundationDropdownAddon.onDropdownChange] Invalid variant data when selecting new option`)
            }
            setSelectedFoundationHeight(newVariantData.foundationHeight)
        } else {
            // removes from addon and cart state for subtotals
            unselectAddon(addonProductCode)
            // 'none' is not a valid value in selection context, so this throws logs in the console after the use effect runs
            setSelectedFoundationHeight('none')
        }
    }

    const noFoundationText = belowDropdownContent // true for HD
        ? `No Foundation${isMobile || isSmallDesktop
            ? ' - I own a solid foundation/base'
            : ' - I already own a solid foundation/platform base'
        }`
        : 'No Foundation' // true for Youth

    // addonProductData.attributeSets.foundationHeight could be replaced with:
    // useAttributeOptions(addonProductCode, 'foundationHeight').values.map((option) => option.code))
    // but this seems more performant and easier to read
    const foundationHeightOptions = addonProductData?.attributeSets?.foundationHeight
    if (!foundationHeightOptions) return null // short circuit and don't return the addon at all

    const dropdownOptions = foundationHeightOptions.map((option) => {
        const matchingVariant = findMatchingVariant(
            addonProductData.variants,
            { foundationHeight: option, size: primaryProductSize },
            addonProductData.configurableAttributes
        )
        if (!matchingVariant) return null // if there is no matching variant, don't show the option
        const dropdownItem = customDropdownItem(`${option}"`, matchingVariant.price, matchingVariant.sku)
        return {
            text: dropdownItem,
            itemValue: matchingVariant.sku,
            label: option,
            price: matchingVariant.price,
            sort: Number(option) * 100, // to ensure 4.75" comes before 8.75" when sorted
            sortOrder: Number(option) * 100, // backwards compatibility, not sure if needed
            value: matchingVariant.sku
        }
    })
    dropdownOptions.sort((a, b) => a.sort - b.sort) // display 4.75" first and 'none' last
    dropdownOptions.push({
        text: customDropdownItem(noFoundationText, null, 'none'),
        itemValue: 'none',
        sort: 999,
        sortOrder: 999,
        value: 'none'
    })

    let derivedValue = 'none'
    // if selection is 'none', we shortcircuit the derived value validation as you cant
    // really 'remove' the addon state in the selection context, without this the dropdown state goes out of sync
    if (selectedFoundationHeight !== 'none') {
        derivedValue = dropdownOptions.find(dropdownOption => dropdownOption.value === addonProductState.sku)?.value
    }

    const ModalComponent = modalContent

    return (
        <div className={wrapperClasses}>
            <div className={titleClasses}>
                {showDiscountBadge && valueDiscountAmount ? (
                    <span className="u-flexDisplay u-flexAlign--center">
                        {addonTitle}
                        <DiscountBadge
                            discount={valueDiscountAmount}
                            className="u-marginLeft--xs"
                        />
                    </span>
                ) : addonTitle}
                <button
                    className={modalClasses}
                    onClick={toggleModal}
                >
                    About foundation heights
                </button>
            </div>
            <Dropdown
                id="foundationDropdownAddonComponent"
                canScroll={false}
                selectedValue={derivedValue}
                className="foundationDropdownAddon__dropdown"
                wrapperClassName="foundationDropdownAddon__dropdownWrapper"
                itemsData={dropdownOptions}
                textClassName="foundationDropdownAddon__dropdown--text"
                onChange={onDropdownChange}
                data-selector="foundationDropdownAddon"
            />
            {belowDropdownContent && (
                <div className="foundationDropdownAddon__belowDropdownDescription">
                    {isAddonSelected || (addByDefault && !isFirstLoadComplete)
                        ? belowDropdownContent.selected
                        : belowDropdownContent.unselected}
                    {belowDropdownContent.static}
                </div>
            )}
            <Modal
                type='popup'
                isModalVisible={isModalVisible}
                onModalClose={() => setIsModalVisible(false)}
                modalTitle={modalTitle}
                modalLabel='foundation-dropdown-addon-modal'
            >
                <ModalComponent productCode={primaryProductCode} />
            </Modal>
        </div>
    )
}

FoundationDropdownAddon.propTypes = {
    addonProductCode: PropTypes.string.isRequired,
    primaryProductCode: PropTypes.string.isRequired,
    addonLabel: PropTypes.string.isRequired,
}

export default FoundationDropdownAddon
