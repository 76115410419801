import React from 'react'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import styles from './BBBReview.module.scss'

const BBBReview = () => {
    return (
        <section id='bbbReviews' className={`u-bgColor--white section ${styles.bbbReviews__container} container`}>
            <div className={styles.bbbReviews__wrapper}>
                <SvgSprite spriteID='icon-logo-bbb' className={styles.bbbReviews__logo} />
                <div className={styles.bbbReviews__textWrapper}>
                    <p className={styles.bbbReviews__text}>
                        {'Saatva has earned an '}
                        <a
                            className='t-underline t-color--accentBlue'
                            href='https://www.bbb.org/us/ct/westport/profile/mattress/saatva-inc-0111-87089118/'
                            target='_blank'
                            rel='noopener noreferrer'>
                                A+&nbsp;rating
                        </a>{' from the '}
                        <span className={styles.bbbReviews__text__semiBold}>Better Business Bureau</span>
                        {' and 96% of Saatva customers are satisfied with their purchase**.'}
                        <span className={`u-displayBlock ${styles.bbbReviews__text__secondLine}`}>
                            See for yourself why we have the highest customer review ratings in the mattress industry.
                        </span>
                    </p>
                </div>
            </div>
        </section>
    )
}

export default BBBReview
