import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useProductData, useProductState, useUpdateProductAttributes } from '@saatva-bits/pattern-library.modules.selection'
import { useGetDiscounts } from '@saatva-bits/pattern-library.modules.promotions'
import { generateAssetURLs, PDP_CAROUSEL_THUMBNAIL_WIDTHS } from '@saatva-bits/pattern-library.utils.product'
import { formatCurrency } from '@saatva-bits/pattern-library.utils.price-format'

import useAddon from '@/hooks/addon'
import Picture from '@/components/PictureImgix'
import SaleBadge from '@/components/SaleBadge'
import ProductSwatch from '@/components/ProductSwatch'
import PILLOW_SIZE_MAP from '@/temp-configs/pillow-size-map'
import contentData from './detailedCheckboxBuystackAddonConfig'
import { checkDetailedCheckboxBuystackAddonConditions } from '@/components/Addon/Addons/checkAddonConditions'
import styles from './DetailedCheckboxBuystackAddon.module.scss'

import { FormCheckbox } from '@saatva-bits/pattern-library.components.form-checkbox'

const IMGIX_URL = process.env.NEXT_PUBLIC_IMGIX_URL

const DetailedCheckboxBuystackAddon = ({
    primaryProductCode,
    addonProductCode,
    isChooseYourDealPromo = false,
    addonLabel
}) => {
    const productContentData = contentData[addonProductCode] && contentData[addonProductCode]
    const updateProductAttributes = useUpdateProductAttributes()
    const primaryProductState = useProductState(primaryProductCode)
    const addonProductData = useProductData(addonProductCode)
    const addonProductState = useProductState(addonProductCode)
    const { isAddonSelected, selectAddon, unselectAddon } = useAddon(addonProductCode, addonLabel)
    const { getDiscounts } = useGetDiscounts()
    const [colorSelectorOptions, setColorSelectorOptions] = useState({
        isVisible: false,
        optionsList: []
    })
    const [showSaleBadge, setShowSaleBadge] = useState(false)
    const [isAddonApplicable, setIsAddonApplicable] = useState(checkDetailedCheckboxBuystackAddonConditions(productContentData, primaryProductCode, addonProductState.subcategory, primaryProductState.size, addonProductState.inStock))

    // Create ardad urls for product thumbnail
    const addonImageData = useMemo(() => {
        const assetData = productContentData?.ardadConfig
        if (assetData?.generic) {
            const { imageFolder, imageName, imageAlt } = assetData.generic
            return [{
                filename: imageName,
                folder: imageFolder,
                alt: imageAlt
            }]
        } else {
            const addonProductData = addonProductCode === 'the-saatva-pillow' ? { ...addonProductState, pillowHeightOption: 'High Loft' } : addonProductState
            return generateAssetURLs(addonProductCode, assetData, addonProductData, '1-1')
        }
    }, [addonProductCode]) // eslint-disable-line react-hooks/exhaustive-deps

    // Logic for getting discounted prices
    const productInfoForDiscount = [{
        sku: addonProductState.sku,
        parentSku: addonProductCode,
        category: addonProductState.category,
        quantity: productContentData?.isBundle ? 2 : 1,
        price: addonProductState.price
    }]

    const {
        fullPrice,
        finalPrice,
        discountAmount
    } = getDiscounts(productInfoForDiscount)

    // Checks if the addon is in stock and if it meets specific configurable attribute criteria based on the PDP.
    useEffect(() => {
        const isApplicable = checkDetailedCheckboxBuystackAddonConditions(productContentData, primaryProductCode, addonProductState.subcategory, primaryProductState.size, addonProductState.inStock)
        if (!isApplicable) {
            unselectAddon()
        }
        setIsAddonApplicable(isApplicable)

    }, [primaryProductState.size, addonProductState.inStock]) // eslint-disable-line react-hooks/exhaustive-deps

    // Applies specific logic pertaining to different types of product addons
    // If additional sub categories are required, specific logic can be added below.
    useEffect(() => {
        // returns a filtered array of product options based on the product configurable attributes or a specified set of attributes and values that should be updated in Selection State.
        const filterConfigurableAttributes = (specificConfigurableAttributes = null, specificAttributeValues = null) => {
            const attributesToUpdate = specificConfigurableAttributes || addonProductData.configurableAttributes
            return attributesToUpdate.map((configurableAttribute) => {
                const attributeIsApplicableToProduct = addonProductData.options[configurableAttribute]

                if (!attributeIsApplicableToProduct) {
                    console.warn(`Attribute ${configurableAttribute} is not applicable to the product ${addonProductCode}`)
                    return
                }

                return {
                    code: configurableAttribute,
                    value: specificAttributeValues && specificAttributeValues[configurableAttribute]
                }
            })
        }

        // Updates specific attributes of the addon product to match the intended parent product selection state.
        const updateAddonProductState = (configurableAttributes = []) => {
            if (configurableAttributes.length === 0) return
            configurableAttributes.forEach(attribute => {
                if (attribute) {
                    const attributeValue = attribute.value || primaryProductState[attribute.code]
                    updateProductAttributes(addonProductCode, { [attribute.code]: attributeValue })
                }
            })
        }

        switch (addonProductData.subcategory) {
            case 'Pillows':
                let pillowQty = 1
                const pillowConfigurableAttributes = [...addonProductData.configurableAttributes]
                const pillowConfigurableAttributesValues = {}

                if (productContentData?.isBundle) {
                    // For bundled pillows, the PDP will add the singular pillow sku with a qty of 2 in the addon.
                    // Once added to cart the singular pillow with qty 2 will be transformed to the bundle sku for the respective pillow.
                    setShowSaleBadge(true)
                    pillowQty = 2
                    !pillowConfigurableAttributes.includes('quantity') && pillowConfigurableAttributes.push('quantity')
                }

                // normalizes pillow and primary product sizes.
                const { pillowSize } = PILLOW_SIZE_MAP[primaryProductState['size']]

                // Assigns specific values for the pillow addon so that the addon does not update directly with the primary product state.
                pillowConfigurableAttributes.forEach(attribute => {
                    switch (attribute) {
                        case 'size':
                            pillowConfigurableAttributesValues[attribute] = pillowSize === 'Queen' ? 'Standard/Queen' : pillowSize || 'Standard/Queen'
                            break
                        case 'quantity':
                            pillowConfigurableAttributesValues[attribute] = pillowQty
                            break
                        case 'pillowHeightOption':
                            pillowConfigurableAttributesValues[attribute] = 'High Loft'
                            break
                    }
                })

                const pillowAttributesToUpdate = filterConfigurableAttributes(pillowConfigurableAttributes, pillowConfigurableAttributesValues)
                updateAddonProductState(pillowAttributesToUpdate)
                break
            case 'Sheets':
                const sheetsConfigurableAttributes = [...addonProductData.configurableAttributes]
                const sheetsConfigurableAttributesValues = {}

                sheetsConfigurableAttributes.forEach(attribute => {
                    switch (attribute) {
                        case 'size':
                            sheetsConfigurableAttributesValues[attribute] = null
                            break
                        case 'color':
                            sheetsConfigurableAttributesValues[attribute] = 'White'
                            break
                    }
                })

                // only the white color option is available for sheet addons.
                // If additional colors are needed in the future, remove the if statement to allow all colors options to be present
                const optionElements = addonProductData.options.color && addonProductData.options.color.values.map((value) => {
                    if (value.code === 'White') {
                        return {
                            label: value.label,
                            value: value.code,
                            childProps: {
                                background: value.swatch,
                                hasBookmark: false,
                                useInverseCheckColor: false
                            }
                        }
                    }
                }).filter(option => option)

                const sheetsAttributesToUpdate = filterConfigurableAttributes(sheetsConfigurableAttributes, sheetsConfigurableAttributesValues)
                !colorSelectorOptions.isVisible && setColorSelectorOptions({ isVisible: true, optionsList: optionElements || [] })
                updateAddonProductState(sheetsAttributesToUpdate)
                break
            default:
                const configurableAttributesToUpdate = filterConfigurableAttributes()
                updateAddonProductState(configurableAttributesToUpdate)
                break
        }
    }, [primaryProductState.size]) // eslint-disable-line react-hooks/exhaustive-deps

    // If the addon does not meet the required conditions, do not return the addon tile
    if (!isAddonApplicable) return null

    // Adds or removes the addon product to the addons array
    const handleAddonSelect = (e) => {
        if (e.target.checked) {
            !isAddonSelected && selectAddon()
        } else {
            isAddonSelected && unselectAddon()
        }
    }

    // Creates the label displayed next to the checkbox.
    const createLabel = (label = '', size = '', price, isBundle = false, labelModifier) => {
        let priceDisplay = formatCurrency(price)
        if (isChooseYourDealPromo) {
            priceDisplay = <span className={styles['detailedCheckboxBuystackAddon__checkbox__label--valueTag']}>{formatCurrency(price)} Value</span>
        } else if (discountAmount) {
            priceDisplay = <>
                <span key={finalPrice} className={styles['detailedCheckboxBuystackAddon__checkbox__label--finalPrice']} data-selector='final-price'>{formatCurrency(finalPrice)}</span>
                <span className={styles['detailedCheckboxBuystackAddon__checkbox__label--strikethroughPrice']} data-selector='strikethrough-price'>{formatCurrency(price)}</span>
            </>
        }

        let formattedLabel = ''
        let plusSign = !isChooseYourDealPromo ? '+' : ''
        const bundleLabel = isBundle && (`Add 2 ${labelModifier} ${label}s${isChooseYourDealPromo ? ', ' : ''}`)
        const priceLabel = isBundle ? ` ${size} (Pair) ${plusSign}` : ` ${size} ${plusSign}`

        const labelPriceClasses = classNames(styles['detailedCheckboxBuystackAddon__checkbox__label--price'])

        if (!addonProductData.active) {
            // addon product doesn't have a PDP
            formattedLabel = (
                <span className={styles[`detailedCheckboxBuystackAddon__checkbox__label--text`]}>
                    <span data-selector='detailedCheckboxBuystackAddon__checkbox__label'>{bundleLabel || label}</span>
                    <span data-selector='detailedCheckboxBuystackAddon-price' className={labelPriceClasses}>{priceLabel}{priceDisplay}</span>
                </span>
            )
        } else {
            // addon product has PDP
            formattedLabel = (
                <span className={styles[`detailedCheckboxBuystackAddon__checkbox__label--text`]}>
                    <a href={addonProductData.url} target='_blank' rel='noopener noreferrer' className='t-link' data-selector='detailedCheckboxBuystackAddon__checkbox__label--link'>{bundleLabel || label}</a>
                    <span data-selector='detailedCheckboxBuystackAddon-price' className={labelPriceClasses}>{priceLabel}{priceDisplay}</span>
                </span>
            )
        }

        return (formattedLabel)
    }

    const labelModifier = addonProductState?.pillowHeightOption || ''
    const primaryLabel = productContentData?.checkboxLabel || addonProductData?.name
    const sizeLabel = addonProductState?.size || ''
    const label = createLabel(primaryLabel, sizeLabel, fullPrice, productContentData?.isBundle, labelModifier)
    const productIntroHeader = productContentData?.productIntro?.header
    const productIntroDescription = productContentData?.productIntro?.description
    const productMainDescription = productContentData?.description[primaryProductCode] || productContentData?.description?.default

    const containerClasses = classNames(styles.detailedCheckboxBuystackAddon)

    const productContentContainerClasses = classNames(styles.detailedCheckboxBuystackAddon__productContentContainer, {
        'active': isAddonSelected
    })

    const productContentContainerLeftClasses = classNames(styles.detailedCheckboxBuystackAddon__productContentContainer__left)

    const productContentContainerRightClasses = classNames(styles.detailedCheckboxBuystackAddon__productContentContainer__right)

    return (
        <div data-selector='detailedCheckboxBuystackAddon' className={containerClasses}>
            { productContentData?.productIntro &&
                <div className={styles.detailedCheckboxBuystackAddon__productIntro}>
                    {productIntroHeader && <div className={styles.detailedCheckboxBuystackAddon__productIntro__header} dangerouslySetInnerHTML={{ __html: productIntroHeader }} />}
                    {productIntroDescription && <div className={styles.detailedCheckboxBuystackAddon__productIntro__description} dangerouslySetInnerHTML={{ __html: productIntroDescription }} />}
                </div>
            }
            <div className={productContentContainerClasses}>
                <div className={productContentContainerLeftClasses}>
                    <Picture
                        key={`detailedCheckboxBuystackAddon-${addonProductCode}-${addonImageData[0]?.filename}`}
                        imageClassName={styles.detailedCheckboxBuystackAddon__productContentContainer__left__productImage}
                        folder={addonImageData[0]?.folder}
                        name={addonImageData[0]?.filename}
                        alt={addonProductData?.name}
                        widths={PDP_CAROUSEL_THUMBNAIL_WIDTHS}
                        prefixOverride={{
                            mobile: 'none',
                            tablet: 'none',
                            desktop: 'none'
                        }}
                        imgixDomain={IMGIX_URL}
                    />
                    { showSaleBadge &&
                        <SaleBadge
                            className={styles.detailedCheckboxBuystackAddon__productContentContainer__left__saleBadge}
                            bumper='none'
                            text={isChooseYourDealPromo ? `Free` : `Save $${discountAmount}`} />
                    }
                </div>
                <div className={productContentContainerRightClasses}>
                    <FormCheckbox
                        id={`detailedCheckboxBuystackAddon-${addonProductCode}`}
                        className={`${styles['detailedCheckboxBuystackAddon__productContentContainer__right__checkbox']}`}
                        checked={isAddonSelected}
                        label={label}
                        onChange={(e) => handleAddonSelect(e)}
                        value={addonProductCode}
                        autoComplete='off'
                        data-selector='detailedCheckboxBuystackAddon-checkbox'
                        containerAttributes={{ 'data-selector': `detailedCheckboxBuystackAddon-${addonProductCode}-container` }} />
                    <div className={styles.detailedCheckboxBuystackAddon__productContentContainer__right__description} dangerouslySetInnerHTML={{ __html: productMainDescription }} />
                    { colorSelectorOptions.isVisible && colorSelectorOptions.optionsList.length > 0 &&
                        <div className={styles.detailedCheckboxBuystackAddon__productContentContainer__right__productSwatch}>
                            <ProductSwatch
                                index='0'
                                inputIdModifier='detailedCheckboxBuystackAddon'
                                className={styles.detailedCheckboxBuystackAddon__productContentContainer__right__productSwatch__swatch}
                                optionsList={colorSelectorOptions.optionsList}
                                productCode={addonProductCode}
                                initialSelection={addonProductState.color || addonProductState.fabric}
                                size='small'
                            />
                            <div className={styles.detailedCheckboxBuystackAddon__productContentContainer__right__productSwatch__description}>{addonProductState.color || addonProductState.fabric}</div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

DetailedCheckboxBuystackAddon.propTypes = {
    primaryProductCode: PropTypes.string.isRequired,
    addonProductCode: PropTypes.string.isRequired,
    isChooseYourDealPromo: PropTypes.bool
}

export default DetailedCheckboxBuystackAddon
