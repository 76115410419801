import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

const Context = createContext()

// Tracks what addons are selected, and should be considered when calculating subtotal
// The addon product state, such as quantity and other configurable attributes, is managed via the selection context
const AddonProvider = ({ children }) => {
    const [selectedAddonProductCodes, setSelectedAddonProductCodes] = useState(new Set())

    const getProductCode = (selectedAddonProductCode) => {
        const [addon, code] = selectedAddonProductCode.split('--') // eslint-disable-line no-unused-vars
        return code
    }

    const encodeAddonProductCode = (addonLabel, productCode) => {
        const separator = '--'
        return `${addonLabel}${separator}${productCode}`
    }

    return (
        <Context.Provider value={{ selectedAddonProductCodes, setSelectedAddonProductCodes, getProductCode, encodeAddonProductCode }}>
            { children }
        </Context.Provider>
    )
}

AddonProvider.propTypes = {
    children: PropTypes.any
}

const useAddonContext = () => {
    const addonContext = useContext(Context)

    if (typeof addonContext === 'undefined') {
        throw new Error('useAddonContext must be used within AddonProvider')
    }

    return addonContext
}

export {
    AddonProvider,
    useAddonContext
}
