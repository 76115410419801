import { CLASSIC_STARTER_BUNDLE, FOUNDATION, ADJUSTABLE_FRAME, SAATVA_CLASSIC, ORGANIC_MATTRESS_PAD } from '@/constants/product-codes'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'
import styles from './BundlesProductSelectors.module.scss'

// Currently supported `displayData` overrides include:
//      name
//      description
//      url
//      imageName
//      imageFolder
//      attributeLabels

// `displayData` should have identical structure for merge products as it does
// for the individal products (the latter accessed via a product code key).

// `attributeLabels` is an object allowing you to override the displayName
// for product options using the following structure:
//      `{ <attributeCode>: <desiredLabelString> }`

const config = {
    [CLASSIC_STARTER_BUNDLE]: {
        mergeProducts: {
            productCodes: [FOUNDATION, ADJUSTABLE_FRAME],
            displayData: {
                name: 'Foundation + Universal Frame',
                description: 'Better than a box spring, our foundation adds optimal support and durability.',
                url: '/furniture/foundation',
                imageName: 'foundation-silo-with-frame-standard-4.75-1-1.jpg',
                imageFolder: 'products/foundation/silo-with-frame/standard/4.75'
            }
        },
        displayData: {
            [SAATVA_CLASSIC]: {
                name: <span>Saatva Classic Mattress <SvgSprite className={styles.awardSprite} spriteID='icon-award-winning' /></span>,
                description: 'America\'s best-selling online luxury innerspring with a 3” Euro pillow top and durable & responsive dual-coil design that supports all sleepers.',
                attributeLabels: {
                    'mattressHeight': 'Height'
                }
            },
            [ORGANIC_MATTRESS_PAD]: {
                description: 'Our plush mattress pad is made with GOTS®-certified organic cotton for breathable comfort and protection from light stains and general wear & tear.'
            }
        }
    }
}

export default config
