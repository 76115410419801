/*
    Centralized Addon Conditions

    If addons become required outside of PDPs, or business logic escalates, this could be migrated
    to the catalog service. Variants could be enriched to store applicable addons.
*/

export const checkDropdownBuystackAddonConditions = ({
    primaryQuiltOption,
    addonProductCode
}) => {
    // quilt products only have extra sham pair option if the quiltOption 'Quilt Only' option is selected
    if (primaryQuiltOption === 'Quilt only' && addonProductCode.includes('quilt-shams')) {
        return false
    } else {
        return true
    }
}

export const checkDetailedCheckboxBuystackAddonConditions = (productContentData, primaryProductCode, addonSubcategory, primaryProductSize, addonStockValue) => {
    if (!addonStockValue || !productContentData) return false

    if (addonSubcategory === 'Sheets') {
        if (primaryProductCode === 'solaire' || primaryProductCode === 'zenhaven') {
            if (!primaryProductSize.includes('Upper-Flex')) {
                return false
            }
        }
    }

    if (addonSubcategory === 'Mattress Pads') {
        if (primaryProductSize === 'Split Cal King') {
            return false
        }
    }

    return true
}

// primaryProductCode is the product code of the selected PDP, not the addon product code
// isOtherProductSelected is a boolean used in other cases where the addon component depends on the state of other addons
export const checkCheckboxAddonConditions = (primaryProductCode, isOtherProductSelected) => {
    // a list of all products that should display this addon component some but not all of the time
    const productsWithAddonConditionally = ['saatva-hd', 'saatva-youth']
    if (productsWithAddonConditionally.includes(primaryProductCode) && !isOtherProductSelected) {
        return false
    } else {
        return true
    }
}
