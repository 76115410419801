import React from 'react'
import { SAATVA_HD, SAATVA_YOUTH } from '@/constants/product-codes'
import FancyBanner from './partials/FancyBanner'
import FoundationModal from '@/components/FoundationModal'

const foundationDropdownAddonConfig = {
    [SAATVA_HD]: {
        addonTitle: 'Select HD Foundation Height',
        modalTitle: 'About HD foundation heights',
        modalContent: FoundationModal,
        showDiscountBadge: false,
        addByDefault: true,
        defaultFoundationHeight: '4.75',
        belowDropdownContent: {
            selected: (
                <p>
                    <ul className="foundationDropdownAddon__belowDropdownDescription--list t-bodySm">
                        <li>
                            Our HD foundation was designed to pair with the Saatva HD
                        </li>
                        <li>
                            Saatva HD is NOT compatible with regular foundations and box springs
                        </li>
                        <li>
                            Add the metal frame (sold separately) underneath the foundation to lift it 7.5" off the ground
                        </li>
                        <li>
                            Mattress cannot sit directly on the metal frame
                        </li>
                        <li>
                            King and Cal King foundations come split as two separate pieces for ease of delivery and setup
                        </li>
                    </ul>
                </p>
            ),
            unselected: (
                <p className="t-bodySm">
                    Only choose{' '}
                    <span className="t-base">No Foundation</span> if you
                    currently have a solid platform bed.
                </p>
            ),
            static: (
                <FancyBanner>
                    <span className="u-hidden--sm-down">
                        More than 80% of HD mattress shoppers choose a foundation
                    </span>
                    <span className="u-hidden--sm-up">
                        Over 80% of shoppers choose a foundation
                    </span>
                </FancyBanner>
            )
        }
    },
    [SAATVA_YOUTH]: {
        addonTitle: 'Select Foundation Height',
        modalTitle: 'About foundation heights',
        modalContent: FoundationModal,
        showDiscountBadge: true,
        defaultFoundationHeight: 'none',
        addByDefault: false
    }
}

export default foundationDropdownAddonConfig
