import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const ProductConfigLabel = ({
    isCheckbox = false,
    isCustomLabel,
    hideQuantity = true,
    hideSize,
    index,
    label,
    swatchLabel,
    isRecommendedProductModal = false,
    showSurplusSaleLabel = false,
}) => {
    const isSwatch = Boolean(swatchLabel)

    const selectLabelClasses = classNames('productPanel__label u-marginBottom--2xs', {
        'has-quantity': index === 0 && hideQuantity === false,
        'u-flexJustify--flexStart': isSwatch,
        'u-hidden': hideSize && label.toLowerCase().includes('size'),
        'isRecommendedProductModal': isRecommendedProductModal
    })

    let defaultLabel
    switch (true) {
        case label.toLowerCase().includes('size'):
            defaultLabel = 'Select Size'
            break
        case label.toLowerCase().includes('pillow'):
            defaultLabel = 'Select Pillow Height'
            break
        default:
            defaultLabel = `Select ${label}`
            break
    }

    const labelText = (isCustomLabel && label) || defaultLabel

    const displaySurplusSaleTitle = () => {
        return showSurplusSaleLabel && (
            <React.Fragment>
                <p className="t-heading5 productPanel__onSale u-textLeft">
                    Select styles on sale
                </p>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {displaySurplusSaleTitle()}
            <span className={selectLabelClasses}>
                { !isCheckbox && labelText }
                { swatchLabel && !isCheckbox &&
                    <span className="t-body u-marginLeft">{swatchLabel}</span>
                }
            </span>
            { index === 0 && hideQuantity === false &&
                <span className='productPanel__label productPanel__label--quantity u-marginBottom--2xs'>Qty</span>
            }
        </React.Fragment>
    )
}

ProductConfigLabel.propTypes = {
    isCustomLabel: PropTypes.bool,
    isCheckbox: PropTypes.bool,
    hideQuantity: PropTypes.bool,
    hideSize: PropTypes.bool,
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    swatchLabel: PropTypes.string,
    isRecommendedProductModal: PropTypes.bool,
    showSurplusSaleLabel: PropTypes.bool,
}

export default ProductConfigLabel
