const QUEEN_QTY_1 = {
    pillowSize: 'Queen',
    pillowQty: 1
}

const QUEEN_QTY_2 = {
    pillowSize: 'Queen',
    pillowQty: 2
}

const KING_QTY_2 = {
    pillowSize: 'King',
    pillowQty: 2
}

const PILLOW_SIZE_MAP = {
    'Crib': QUEEN_QTY_1,
    'One Size': QUEEN_QTY_2,
    'Twin': QUEEN_QTY_1,
    'Twin XL': QUEEN_QTY_1,
    'Twin/Twin XL': QUEEN_QTY_1,
    'Twin/Throw': QUEEN_QTY_1,
    'Full': QUEEN_QTY_2,
    'Full/Queen': QUEEN_QTY_2,
    'Standard/Queen': QUEEN_QTY_2,
    'Queen': QUEEN_QTY_2,
    'Queen Upper-Flex': QUEEN_QTY_2,
    'Upper-Flex Queen': QUEEN_QTY_2,
    'Split Queen': QUEEN_QTY_2,
    'King': KING_QTY_2,
    'King Upper-Flex': KING_QTY_2,
    'Upper-Flex King': KING_QTY_2,
    'King/Cal King': KING_QTY_2,
    'Split King': KING_QTY_2,
    'Cal King': KING_QTY_2,
    'Split Cal King': KING_QTY_2
}

export default PILLOW_SIZE_MAP
