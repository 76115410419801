import React, { useState, useRef, useEffect } from 'react'
import styles from './Modal.module.scss'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Icon } from '@saatva-bits/pattern-library.components.icon'

import useBodyOverflowControl from '@/hooks/useBodyOverflowControl'
import onFocusTrapper from '@/utils/onFocusTrapper'

const ELEMENTS_QUERY = 'button, [href]:not([hidden]), input, select, textarea, source, [tabindex]:not([tabindex="-1"])'

const Modal = ({
    children,
    className,
    closeButtonClass,
    closeButtonContent,
    contentWrapperClassName,
    focusableElementsQuery = ELEMENTS_QUERY,
    footerClassName,
    hasOverlay = true,
    isModalVisible,
    isVideo = false,
    modalFooter,
    modalLabel,
    modalTitle,
    overlayClass,
    titleClassName = 't-heading2',
    toggleModal,
    ...other
}) => {
    const [previouslyActiveElement, setPreviouslyActiveElement] = useState(null)
    const isRecommendedProducts = className.includes('recommendedProducts__modal')

    const modalElement = useRef(null)

    useEffect(() => {
        if (isModalVisible) {
            setPreviouslyActiveElement(window.document.activeElement)

            const elementsToFocus = modalElement.current.querySelectorAll(focusableElementsQuery)
            // IE doesn't support the forEach method on NodeLists so we need to convert it to an array below:
            const elementsToFocusArray = Array.prototype.slice.call(elementsToFocus)

            if (elementsToFocusArray.length > 0) {
                elementsToFocusArray[0].focus()
            }
        } else {
            previouslyActiveElement && previouslyActiveElement.focus && previouslyActiveElement.focus()
        }
    }, [isModalVisible])

    const handleKeyDown = (e) => onFocusTrapper(e, modalElement, toggleModal, focusableElementsQuery)

    useBodyOverflowControl(isModalVisible)

    const modalClasses = classNames(styles.modal, className)

    const overlayClasses = classNames(styles.modalOverlay, overlayClass, {
        [styles.modalOverlayIsVisible]: isModalVisible
    })

    const contentClasses = classNames(contentWrapperClassName, {
        [styles.modalContent]: !isVideo && !isRecommendedProducts,
        [styles.modalRecommendedContent]: isRecommendedProducts,
        [styles.modalContentHasTitle]: modalTitle !== undefined,
        [styles.modalContentHasFooter]: modalFooter !== undefined
    })

    // Use the default close button class "modal__dialogClose"
    // unless the user passes in their own class
    const closeButtonClasses = classNames(styles.modalDialogClose, closeButtonClass, {
        [styles.modalDialogCloseIsVisible]: isModalVisible
    })

    const titleClasses = classNames(styles.modalHeader, titleClassName)

    const footerClasses = classNames(styles.modalFooter, footerClassName)

    const overlayProps = {
        className: overlayClasses,
        onClick: toggleModal
    }

    const contentProps = {
        className: contentClasses
    }

    return (
        <React.Fragment>
            <div
                ref={modalElement}
                className={modalClasses}
                aria-hidden={!isModalVisible}
                aria-label={modalLabel}
                onKeyDown={handleKeyDown}
                role="presentation"
                {...other}>
                <button aria-label="Close" className={closeButtonClasses} onClick={toggleModal}>
                    {closeButtonContent || <Icon name="close" alt="Close Modal" description="Close Modal" titleId={`${modalTitle}-CloseIcon`}></Icon>}
                </button>
                { modalTitle &&
                    <h1 className={titleClasses}>{modalTitle}</h1>
                }
                <div {...contentProps}>
                    {children}
                </div>
                { modalFooter &&
                    <div className={footerClasses}>
                        {modalFooter}
                    </div>
                }
            </div>
            { hasOverlay &&
                <span {...overlayProps} />
            }
        </React.Fragment>
    )
}

Modal.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    closeButtonClass: PropTypes.string,
    closeButtonContent: PropTypes.node,
    contentWrapperClassName: PropTypes.string,
    focusableElementsQuery: PropTypes.string,
    footerClassName: PropTypes.string,
    hasOverlay: PropTypes.bool,
    isModalVisible: PropTypes.bool.isRequired,
    isVideo: PropTypes.bool.isRequired,
    modalFooter: PropTypes.node,
    modalLabel: PropTypes.string.isRequired,
    modalTitle: PropTypes.string,
    overlayClass: PropTypes.string,
    titleClassName: PropTypes.string,
    toggleModal: PropTypes.func.isRequired
}

export default Modal
