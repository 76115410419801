import { PRODUCT_CODES } from '@/constants'

const FABRICS = {
    NATURAL_LINEN: 'Natural Linen',
    ESPRESSO_ANTICA_LEATHER: 'Espresso Antica Leather',
    TAUPE_VINTAGE_VELVET: 'Taupe Vintage Velvet',
    GRAPHITE_VINTAGE_VELVET: 'Graphite Vintage Velvet',
    SLATE_VERONA_LEATHER: 'Slate Verona Leather',
    BLACK_VERONA_LEATHER: 'Black Verona Leather'
}

const COLORS = {
    BROWN_OAK: 'Brown Oak',
}

// Expand as necessary, only applies to wood bed frames currently
const COLORS_WITH_MODELS = [
    COLORS.BROWN_OAK,
]

// Attribute values that change the display text of the disclaimer.
// In the future, we can compare the configurable attribute values of the override SKU
// versus the original values of the SKU to determine what text should be displayed.
// Until then, we update the text based on size, fabric, or both.
// List of original fabrics that have 3D models.
const FABRICS_WITH_MODELS = [
    FABRICS.NATURAL_LINEN,
    FABRICS.ESPRESSO_ANTICA_LEATHER,
    FABRICS.TAUPE_VINTAGE_VELVET,
    FABRICS.GRAPHITE_VINTAGE_VELVET,
    FABRICS.SLATE_VERONA_LEATHER,
    FABRICS.BLACK_VERONA_LEATHER
]

// List of original sizes that have 3D models
const SIZES_WITH_MODELS = [
    'Queen',
    'King',
    'Cal King'
]

const SIZES = {
    QUEEN: 'Queen'
}

// skus object contains a key/value pair:
// - the key is the variant SKU currently selected on the PDP
// - the value is the fallback SKU that will have its model displayed by Epigraph
const epigraphSKUOverrides = {
    [`${PRODUCT_CODES.AMALFI}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        skus: {
            '508-CK900105': '508-CK900101',
            '508-KG900105': '508-KG900101',
            '508-QN900105': '508-QN900101'
        }
    },
    [`${PRODUCT_CODES.AMALFI_PLATFORM_BED}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        skus: {
            '802-CK900105': '802-CK900101',
            '802-KG900105': '802-KG900101',
            '802-QN900105': '802-QN900101'
        }
    },
    [`${PRODUCT_CODES.AMALFI_PLATFORM_BED}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        hasSingleFabricForModels: true,
        skus: {
            '1022-CK800105': '1022-CK800101',
            '1022-KG800105': '1022-KG800101',
            '1022-QN800105': '1022-QN800101',
            '1022-QN800203': '1022-QN800101',
            '1022-QN800202': '1022-QN800101',
            '1022-KG800203': '1022-KG800101',
            '1022-KG800202': '1022-KG800101',
            '1022-CK800203': '1022-CK800101',
            '1022-CK800202': '1022-CK800101'
        }
    },
    [`${PRODUCT_CODES.CALAIS}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        hasSingleFabricForModels: true,
        skus: {
            '622-CK900105': '622-CK900101',
            '622-KG900105': '622-KG900101',
            '622-QN900105': '622-QN900101',
            '622-QN900203': '622-QN900101',
            '622-QN900202': '622-QN900101',
            '622-KG900203': '622-KG900101',
            '622-KG900202': '622-KG900101',
            '622-CK900203': '622-CK900101',
            '622-CK900202': '622-CK900101'
        }
    },
    [`${PRODUCT_CODES.CASSIS}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        hasSingleFabricForModels: true,
        skus: {
            '612-CK900105': '612-CK900101',
            '612-KG900105': '612-KG900101',
            '612-QN900105': '612-QN900101',
            '612-QN900202': '612-QN900101',
            '612-QN900203': '612-QN900101',
            '612-KG900202': '612-KG900101',
            '612-KG900203': '612-KG900101',
            '612-CK900202': '612-CK900101',
            '612-CK900203': '612-CK900101',
            '612-CK900207': '612-CK900101',
            '612-KG900207': '612-KG900101',
            '612-QN900207': '612-QN900101'
        }
    },
    [`${PRODUCT_CODES.CASSIS_WITH_STORAGE}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        hasSingleFabricForModels: true,
        skus: {
            '1026-QN800105': '1026-QN800101',
            '1026-KG800105': '1026-KG800101',
            '1026-CK800105': '1026-CK800101',
            '1026-QN800202': '1026-QN800101',
            '1026-KG800202': '1026-KG800101',
            '1026-CK800202': '1026-CK800101',
            '1026-QN800203': '1026-QN800101',
            '1026-KG800203': '1026-KG800101',
            '1026-CK800203': '1026-CK800101',
            '1026-QN800207': '1026-QN800101',
            '1026-KG800207': '1026-KG800101',
            '1026-CK800207': '1026-CK800101'
        }
    },
    [`${PRODUCT_CODES.COPENHAGEN}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        skus: {
            '521-CK900105': '521-CK900101',
            '521-KG900105': '521-KG900101',
            '521-QN900105': '521-QN900101'
        }
    },
    [`${PRODUCT_CODES.HALLE}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        skus: {
            '558-CK900105': '558-CK900101',
            '558-KG900105': '558-KG900101',
            '558-QN900105': '558-QN900101'
        }
    },
    [`${PRODUCT_CODES.HALLE_STORAGE_PLATFORM_BED}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        skus: {
            '1020-QN800105': '1020-QN800101',
            '1020-KG800105': '1020-KG800101',
            '1020-CK800105': '1020-CK800101'
        }
    },
    [`${PRODUCT_CODES.HALLE_WITH_STORAGE}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        skus: {
            '593-CK800105': '593-CK800101',
            '593-KG800105': '593-KG800101',
            '593-QN800105': '593-QN800101'
        }
    },
    [`${PRODUCT_CODES.LUCERNE}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        skus: {
            '556-CK900105': '556-CK900101',
            '556-KG900105': '556-KG900101',
            '556-QN900105': '556-QN900101'
        }
    },
    [`${PRODUCT_CODES.LYON}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        hasSingleFabricForModels: true,
        skus: {
            '605-CK900105': '605-CK900101',
            '605-KG900105': '605-KG900101',
            '605-QN900105': '605-QN900101',
            '605-QN900203': '605-QN900101',
            '605-QN900202': '605-QN900101',
            '605-KG900203': '605-KG900101',
            '605-KG900202': '605-KG900101',
            '605-CK900203': '605-CK900101',
            '605-CK900202': '605-CK900101'
        }
    },
    [`${PRODUCT_CODES.MARBELLA}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        skus: {
            '366X-CK900105': '366X-CK900101',
            '366X-KG900105': '366X-KG900101',
            '366X-QN900105': '366X-QN900101',
            '366-CK900105': '366-CK900101',
            '366-KG900105': '366-KG900101',
            '366-QN900105': '366-QN900101'
        }
    },
    [`${PRODUCT_CODES.MARSEILLE}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        skus: {
            '171-CK900105': '171-CK900101',
            '171-KG900105': '171-KG900101',
            '171-QN900105': '171-QN900101'
        }
    },
    [`${PRODUCT_CODES.MERANO}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        hasSingleFabricForModels: true,
        skus: {
            '618-CK900105': '618-CK900101',
            '618-KG900105': '618-KG900101',
            '618-QN900105': '618-QN900101',
            '618-QN900203': '618-QN900101',
            '618-QN900202': '618-QN900101',
            '618-KG900203': '618-KG900101',
            '618-KG900202': '618-KG900101',
            '618-CK900203': '618-CK900101',
            '618-CK900202': '618-CK900101',
            '618-CK900507': '618-CK900101',
            '618-KG900507': '618-KG900101',
            '618-QN900507': '618-QN900101'
        }
    },
    [`${PRODUCT_CODES.MILAN}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        hasSingleFabricForModels: true,
        skus: {
            '609-CK900105': '609-CK900101',
            '609-KG900105': '609-KG900101',
            '609-QN900105': '609-QN900101',
            '609-QN900203': '609-QN900101',
            '609-QN900202': '609-QN900101',
            '609-KG900203': '609-KG900101',
            '609-KG900202': '609-KG900101',
            '609-CK900203': '609-CK900101',
            '609-CK900202': '609-CK900101'
        }
    },
    [`${PRODUCT_CODES.MINORI}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        skus: {
            '1015-CK900105': '1015-CK900101',
            '1015-KG900105': '1015-KG900101',
            '1015-QN900105': '1015-QN900101'
        }
    },
    [`${PRODUCT_CODES.MINORI_WITH_STORAGE}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        hasSingleFabricForModels: true,
        skus: {
            '1024-CK800105': '1024-CK800101',
            '1024-KG800105': '1024-KG800101',
            '1024-QN800105': '1024-QN800101',
            '1024-QN800203': '1024-QN800101',
            '1024-QN800202': '1024-QN800101',
            '1024-KG800203': '1024-KG800101',
            '1024-KG800202': '1024-KG800101',
            '1024-CK800203': '1024-CK800101',
            '1024-CK800202': '1024-CK800101'
        }
    },
    [`${PRODUCT_CODES.PORTO}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        skus: {
            '145-CK900105': '145-CK900101',
            '145-KG900105': '145-KG900101',
            '145-QN900105': '145-QN900101'
        }
    },
    [`${PRODUCT_CODES.RHODES}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        fallbackSize: SIZES.QUEEN,
        skus: {
            '589-CK900105': '589-CK900101',
            '589-KG900105': '589-KG900101',
            '589-QN900105': '589-QN900101',
            '589-CK900207': '589-CK900101',
            '589-KG900207': '589-KG900101',
            '589-QN900207': '589-QN900101',
            '589-TW900203': '589-QN900203',
            '589-TW900202': '589-QN900202',
            '589-TW900207': '589-QN900101',
            '589-TW900101': '589-QN900101',
            '589-TW900105': '589-QN900101',
            '589-FL900203': '589-QN900203',
            '589-FL900202': '589-QN900202',
            '589-FL900207': '589-QN900101',
            '589-FL900101': '589-QN900101',
            '589-FL900105': '589-QN900101'
        }
    },
    [`${PRODUCT_CODES.SANTORINI_PLATFORM_BED}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        fallbackSize: SIZES.QUEEN,
        skus: {
            '800-QN900105': '800-QN900101',
            '800-KG900105': '800-KG900101',
            '800-CK900105': '800-CK900101',
            '800-TW900203': '800-QN900203',
            '800-TW900202': '800-QN900202',
            '800-TW900101': '800-QN900101',
            '800-TW900105': '800-QN900101',
            '800-FL900203': '800-QN900203',
            '800-FL900202': '800-QN900202',
            '800-FL900101': '800-QN900101',
            '800-FL900105': '800-QN900101'
        }
    },
    [`${PRODUCT_CODES.SANTORINI}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        fallbackSize: SIZES.QUEEN,
        skus: {
            '394-CK900105': '394-CK900101',
            '394-KG900105': '394-KG900101',
            '394-QN900105': '394-QN900101',
            '394-TW900203': '394-QN900203',
            '394-TW900202': '394-QN900202',
            '394-TW900101': '394-QN900101',
            '394-TW900105': '394-QN900101',
            '394-FL900203': '394-QN900203',
            '394-FL900202': '394-QN900202',
            '394-FL900101': '394-QN900101',
            '394-FL900105': '394-QN900101'
        }
    },
    [`${PRODUCT_CODES.SYDNEY}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        skus: {
            '523-CK900105': '523-CK900101',
            '523-KG900105': '523-KG900101',
            '523-QN900105': '523-QN900101'
        }
    },
    [`${PRODUCT_CODES.VALENCIA}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        skus: {
            '522-CK900105': '522-CK900101',
            '522-KG900105': '522-KG900101',
            '522-QN900105': '522-QN900101'
        }
    },
    [`${PRODUCT_CODES.CRETE}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        hasSingleFabricForModels: true,
        skus: {
            '617-QN900507': '617-QN900101',
            '617-QN900202': '617-QN900101',
            '617-QN900203': '617-QN900101',
            '617-QN900105': '617-QN900101',
            '617-KG900203': '617-KG900101',
            '617-KG900202': '617-KG900101',
            '617-KG900507': '617-KG900101',
            '617-KG900105': '617-KG900101',
            '617-CK900507': '617-CK900101',
            '617-CK900202': '617-CK900101',
            '617-CK900203': '617-CK900101',
            '617-CK900105': '617-CK900101'
        }
    },
    [`${PRODUCT_CODES.ADRA_LEATHER}`]: {
        fallbackFabric: FABRICS.ESPRESSO_ANTICA_LEATHER,
        hasSingleFabricForModels: true,
        skus: {
            '619-QN900406': '619-QN900304',
            '619-QN900405': '619-QN900304',
            '619-QN900607': '619-QN900304',
            '619-KG900406': '619-KG900304',
            '619-KG900405': '619-KG900304',
            '619-KG900607': '619-KG900304',
            '619-CK900406': '619-CK900304',
            '619-CK900405': '619-CK900304',
            '619-CK900607': '619-CK900304'
        }
    },
    [`${PRODUCT_CODES.SIENA_LEATHER}`]: {
        fallbackFabric: FABRICS.ESPRESSO_ANTICA_LEATHER,
        skus: {
            '538-QN900607': '538-CK900304',
            '538-KG900607': '538-KG900304',
            '538-CK900607': '538-QN900304'
        }
    },
    [`${PRODUCT_CODES.LYON_WITH_STORAGE}`]: {
        fallbackFabric: FABRICS.NATURAL_LINEN,
        hasSingleFabricForModels: true,
        skus: {
            '1028-QN800203': '1028-QN800101',
            '1028-QN800202': '1028-QN800101',
            '1028-QN800207': '1028-QN800101',
            '1028-QN800105': '1028-QN800101',
            '1028-KG800203': '1028-KG800101',
            '1028-KG800202': '1028-KG800101',
            '1028-KG800207': '1028-KG800101',
            '1028-KG800105': '1028-KG800101',
            '1028-CK800203': '1028-CK800101',
            '1028-CK800202': '1028-CK800101',
            '1028-CK800207': '1028-CK800101',
            '1028-CK800105': '1028-CK800101'
        }
    },
    [`${PRODUCT_CODES.COLOGNE_WOOD}`]: {
        fallbackColor: COLORS.BROWN_OAK,
        hasSingleColorForModels: true,
        skus: {
            '685-CK900701': '685-CK900708',
            '685-CK900706': '685-CK900708',
            '685-CK900705': '685-CK900708',
            '685-KG900701': '685-KG900708',
            '685-KG900706': '685-KG900708',
            '685-KG900705': '685-KG900708',
            '685-QN900701': '685-QN900708',
            '685-QN900706': '685-QN900708',
            '685-QN900705': '685-QN900708',
        }
    },
    [`${PRODUCT_CODES.CORSICA_WOOD}`]: {
        fallbackColor: COLORS.BROWN_OAK,
        hasSingleColorForModels: true,
        skus: {
            '686-CK900701': '686-CK900708',
            '686-CK900706': '686-CK900708',
            '686-CK900705': '686-CK900708',
            '686-KG900701': '686-KG900708',
            '686-KG900706': '686-KG900708',
            '686-KG900705': '686-KG900708',
            '686-QN900701': '686-QN900708',
            '686-QN900706': '686-QN900708',
            '686-QN900705': '686-QN900708',
        }
    },
    [`${PRODUCT_CODES.MATERA_WOOD}`]: {
        fallbackColor: COLORS.BROWN_OAK,
        hasSingleColorForModels: true,
        skus: {
            '687-CK900701': '687-CK900708',
            '687-CK900706': '687-CK900708',
            '687-CK900705': '687-CK900708',
            '687-KG900701': '687-KG900708',
            '687-KG900706': '687-KG900708',
            '687-KG900705': '687-KG900708',
            '687-QN900701': '687-QN900708',
            '687-QN900706': '687-QN900708',
            '687-QN900705': '687-QN900708',
        }
    },
}

export {
    epigraphSKUOverrides,
    COLORS_WITH_MODELS,
    FABRICS_WITH_MODELS,
    SIZES_WITH_MODELS
}
