import React from 'react'
import * as productCodes from '@/constants/product-codes'
import ComfortLevelConfig from './ScaleComfortLevelConfig'

const accordionItems = {
    gotContent: 'accordion-content-gots',
    gotTitle: 'accordion-title-gots',
    ecoContent: 'accordion-content-ecoinstitut',
    ecoTitle: 'accordion-title-ecoinstitut',
    greenContent: 'accordion-content-greenguard',
    greenTitle: 'accordion-title-greenguard',
    golsContent: 'accordion-content-gols',
    golsTitle: 'accordion-title-gols'
}

const organicLatex = (hasCoils) => {
    return {
        heading: 'Pure comfort starts with pure&nbsp;materials',
        customMediaClassName: 'u-bgColor--accentGreenLight',
        tabs: [
            {
                tabName: 'Organic',
                heading: 'Healthier for the planet',
                hasScrollLink: true,
                dynamicContent (RedirectComponent) {
                    return (
                        <>
                            <p>We use <RedirectComponent copy='GOLS certified' accordionContent={accordionItems.golsContent} /> organic natural latex, our cover is made with highly breathable organic cotton, and our premium New Zealand wool is <RedirectComponent copy='GOTS® certified' accordionContent={accordionItems.gotContent} /> organic. </p>
                            <p>NO harmful pesticides, GMOs, toxic chemicals, heavy metals, fertilizers, and dyes. NO fiberglass or fiberglass sock flame retardants.</p>
                        </>
                    )
                },
                icons: ['icon-certification-gots', 'icon-certification-gols', 'icon-certification-eco-institut', 'icon-certification-greenguard']
            },
            {
                tabName: 'Natural',
                heading: 'Healthier sleep by nature',
                content: <p>We only use the highest quality sustainably-sourced materials nature has to offer: natural latex, cotton, {hasCoils ? 'wool, and recycled steel coils' : 'and wool'}. NO petroleum-based foams or fibers.</p>,
                icons: ['icon-certification-gots', 'icon-certification-gols', 'icon-certification-eco-institut', 'icon-certification-greenguard']
            },
            {
                tabName: 'Nontoxic',
                heading: 'Healthier indoor air',
                hasScrollLink: true,
                dynamicContent (RedirectComponent) {
                    return (
                        <p>Breathe easy. Our natural latex mattress is <RedirectComponent copy='eco-INSTITUT®' accordionContent={accordionItems.ecoContent} /> & <RedirectComponent copy='GREENGUARD®' accordionContent={accordionItems.greenContent} /> Gold certified to have low VOCs (volatile organic compounds) and total chemical emissions. Plus, we never use chemical flame retardants.</p>
                    )
                },
                icons: ['icon-certification-gots', 'icon-certification-gols', 'icon-certification-eco-institut', 'icon-certification-greenguard']
            }
        ]
    }
}

const solairePersonalizedComfort = () => {
    const BASE_LINK = '/bases/adjustable-base-plus?sku='
    return {
        heading: 'Get even more personalized comfort options with our Saatva Adjustable Base Plus',
        hasAlternateColor: true,
        tabContentWrapperClassName: 'u-marginTop--lg',
        tabs: [
            {
                tabName: 'Upper-Flex',
                heading: `Solaire Upper-Flex + Adjustable${'\u00A0'}Base Plus`,
                content: (
                    <>
                        <ul>
                            <li>Gives each sleeper the freedom to raise the head of the mattress independently</li>
                            <li>Great for alleviating mild snoring, relaxing with a good book, or watching TV at the perfect angle</li>
                        </ul>
                        <a className='tabbedContentDisplay__link' href={`${BASE_LINK}8105-5/0`}>Add a Saatva Adjustable Base Plus, save $100</a>
                    </>
                )
            },
            {
                tabName: 'Split King/Cal King',
                heading: `Solaire Split King/Cal King + Adjustable${'\u00A0'}Base Plus Split King/Cal King`,
                content: (
                    <>
                        <ul>
                            <li>Gives each sleeper the freedom to raise the head and the feet of the mattress independently</li>
                            <li>Your most customizable sleep experience ever</li>
                        </ul>
                        <a className='tabbedContentDisplay__link' href={`${BASE_LINK}8109-3XL-split-bundle`}>Add a Saatva Adjustable Base Plus Split King/Cal King, save $100</a>
                    </>
                )
            }
        ]
    }
}

const config = {
    [`${productCodes.SAATVA_LATEX_HYBRID}`]: {
        ...organicLatex(true)
    },
    [`${productCodes.ZENHAVEN}`]: {
        ...organicLatex(false)
    },
    [`${productCodes.SOLAIRE}--personalized-comfort`]: {
        ...solairePersonalizedComfort()
    },
    [`${productCodes.ZENHAVEN}--comfort-level`]: ComfortLevelConfig[productCodes.ZENHAVEN],
    [`${productCodes.SAATVA_CLASSIC}--comfort-level`]: ComfortLevelConfig[productCodes.SAATVA_CLASSIC],
    [`${productCodes.CLASSIC_STARTER_BUNDLE}--comfort-level`]: ComfortLevelConfig[productCodes.SAATVA_CLASSIC],
    [`${productCodes.LOOM_AND_LEAF}--comfort-level`]: ComfortLevelConfig[productCodes.LOOM_AND_LEAF],
    [`${productCodes.SAATVA_CONTOUR5}--comfort-level`]: ComfortLevelConfig[productCodes.SAATVA_CONTOUR5],
    [`${productCodes.SAATVA_HD}--comfort-level`]: ComfortLevelConfig[productCodes.SAATVA_HD],
    [`${productCodes.MEMORY_FOAM_HYBRID}--comfort-level`]: ComfortLevelConfig[productCodes.MEMORY_FOAM_HYBRID],
    [`${productCodes.SAATVA_LATEX_HYBRID}--comfort-level`]: ComfortLevelConfig[productCodes.SAATVA_LATEX_HYBRID],
    [`${productCodes.SAATVA_RX}--comfort-level`]: ComfortLevelConfig[productCodes.SAATVA_RX],
    [`${productCodes.SOLAIRE}--comfort-level`]: ComfortLevelConfig[productCodes.SOLAIRE],
}

export default config
